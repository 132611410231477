import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {HttpDataService} from '../../services/http/http-data.service';
import {ReferralRecordModel} from '../../model/referral-system/referral-record.model';
import {ReferralTransactionModel} from '../../model/referral-system/referral-transaction.model';
import {UtilService} from '../../services/util.service';
import {AuthService} from '../../services/auth.service';
import {NotifierService} from '../../services/notifier.service';
import {ReferralSystemTransactionModel} from '../../model/transaction/referral-system-transaction.model';
import {PermissionsEnum} from '../../permissions.enum';
import {DeviceDetectorService} from '../../services/device-detector.service';

@Component({
  selector: 'referral-system',
  templateUrl: './referral-system.component.html',
  styleUrls: ['./referral-system.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferralSystemComponent implements OnInit {
  public readonly origin = window.location.origin;
  private transactionsPageSize = 50;
  private transactionsPageIndex = 1;
  public transactionsHistoryRecords: ReferralTransactionModel[] = [];
  public referralsRecords: ReferralRecordModel[] = [];
  public referralLink: string | null;
  public copyHovered = false;
  public isMobileView: boolean;

  public historyForm = new FormGroup({
    from: new FormControl<Date | null>(new Date()),
    to: new FormControl<Date | null>(new Date()),
  });
  @Input() isAuthenticated: boolean;

  public constructor(
    private httpDataService: HttpDataService,
    private cdr: ChangeDetectorRef,
    private utilService: UtilService,
    private authService: AuthService,
    private notifierService: NotifierService,
    private detectorService: DeviceDetectorService
  ) {
    this.isMobileView = this.detectorService.isMobile();
  }

  public ngOnInit(): void {
    this.authService.userIsAuthorized$.subscribe(() => {
      this.cdr.detectChanges();
    });

    this.updateReferralLink();

    this.utilService.currentUserId$.subscribe(userId => {
      if (userId) {
        this.referralLink = `${origin}/#/r/${userId}`;
      }
      this.cdr.detectChanges();
    });
  }

  private updateReferralLink(userId?: string): void {
    if (userId) {
      this.referralLink = `${origin}/#/r/${userId}`;
    } else {
      const userIdFromToken = this.utilService.getUserIdFromDetails();
      if (userIdFromToken) {
        this.referralLink = `${origin}/#/r/${userIdFromToken}`;
      } else {
        this.referralLink = null;
      }
    }
    this.cdr.detectChanges();
  }

  public fetchTransactionHistory() {
    if (this.authService.hasPermission(PermissionsEnum.referral_transactions)) {
      this.historyForm.disable();
      this.httpDataService.getTransactionsReferralSystem(
        this.utilService.getSelectedMainCurrency(),
        (this.historyForm.value.from || new Date()).toISOString(),
        (this.historyForm.value.to || new Date()).toISOString(),
        this.transactionsPageIndex,
        this.transactionsPageSize,
      ).subscribe({
        next: res => {
          if (res.succeed) {
            this.transactionsHistoryRecords = res.value.items.map((v: ReferralSystemTransactionModel) => ({
                id: v.id,
                sender: v.referralTransaction.wallet.user.email,
                amount: v.referralTransaction.amount.value,
                created: new Date(v.created),
              } as ReferralTransactionModel)
            );
            this.historyForm.enable();
            this.cdr.detectChanges();
          } else {
            this.notifierService.handleErrors(res.errors);
          }
        },
        error: err => {
          this.notifierService.handleRequestError(err);
          this.historyForm.enable();
          this.cdr.detectChanges();
        }
      });
    }
  }

  public fetchReferrals() {
    if (this.authService.hasPermission(PermissionsEnum.referralSystem_read)) {
      this.httpDataService.getReferralSystem().subscribe(response => {
        if (response.succeed) {
          this.referralsRecords = response.value?.items?.map(v => ({
            id: v.id,
            firstName: v.firstName,
            lastName: v.lastName,
            country: v.country,
            referrals: v.referrals,
            created: new Date(v.created),
          }));
          this.cdr.detectChanges();
        }
      });
    }
  }
}
