import {ChangeDetectorRef, Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {UtilService} from './shared/services/util.service';
import {ThemeService} from './shared/services/theme.service';
import {LoginService} from './shared/services/login.service';
import {NavigationService} from './shared/services/navigation.service';
import {DeviceDetectorService} from './shared/services/device-detector.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  public showLoginPage = false;
  public timeZone: string;
  public visible = false;
  public isMobileView: boolean;
  public isGameOpenedInMobileMode = false;
  public isGameOpenedInDesktopMode = false;
  public isAuthenticated = false;
  public sidebarOpen = false;
  public showAdminSideBar: boolean;

  public constructor(public navigationService: NavigationService,
                     private router: Router,
                     private utilService: UtilService,
                     private themeService: ThemeService, public loginService: LoginService,
                     private cdr: ChangeDetectorRef,
                     private detectorService: DeviceDetectorService
  ) {
  }

  public ngOnInit() {
    this.utilService.timeZoneChange$.subscribe(timeZone => this.timeZone = timeZone);

    this.isMobileView = this.detectorService.isMobile();
    this.themeService.loadThemeFromLocalStorage();
    this.navigationService.openLeftBar$.subscribe(res => {
      this.visible = !!res.value;
    })
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showAdminSideBar = event.urlAfterRedirects.includes('/administration');
        window.scrollTo(0, 0);
        this.isGameOpenedInMobileMode = event.urlAfterRedirects.includes('/game') && this.isMobileView && !event.urlAfterRedirects.includes('/games');
        this.isGameOpenedInDesktopMode = event.urlAfterRedirects.includes('/game') && !this.isMobileView && !event.urlAfterRedirects.includes('/games');
      }
    });

    this.loginService.isLoginPageVisible$.subscribe((isVisible) => {
      this.showLoginPage = isVisible;
      this.cdr.detectChanges();
    })

  }

  public closeLeftBar(event: boolean) {
    this.visible = !event;
  }
}
