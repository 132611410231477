import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';

@Component({
  selector: 'casino-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent {
  @Input() itemTemplate: TemplateRef<any>;
  @Input() public options: any[];
  @Input() public objectValue: any;
  @Input() public placeholder: string;
  @Input() public optionValueIsObject = false;
  @Input() public optionValue?: string;
  @Input() public optionLabel?: string;
  @Input() public label: string;
  @Input() public id: string;
  @Input() width = '250px';
  @Input() value: string | object;

  @Output() public onValueChange = new EventEmitter<any>();

}
