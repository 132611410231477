import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UtilService} from '../../services/util.service';
import {AuthService} from '../../services/auth.service';
import {
  SETTINGS_ABOUT_PANEL_INDEX_IN,
  SETTINGS_ABOUT_PANEL_INDEX_OUT,
  SETTINGS_AGREEMENT_PANEL_INDEX_IN,
  SETTINGS_AGREEMENT_PANEL_INDEX_OUT,
  SETTINGS_GENERAL_PANEL_INDEX_IN,
  SETTINGS_TAB_INDEX_IN,
  SETTINGS_TAB_INDEX_OUT,
  WALLETS_TAB_INDEX_IN
} from '../../constants';
import {NavigationService} from '../../services/navigation.service';
import {DeviceDetectorService} from '../../services/device-detector.service';

@Component({
  selector: 'right-bar',
  templateUrl: './right-bar.component.html',
  styleUrls: ['./right-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightBarComponent implements OnInit {
  public visible = false;
  public settingsActivePanelIndex: number | null;
  public activeIndex: number | null = 0;
  public isAuthenticated = false;
  public isMobileView = false;
  public userMail: string | null;
  public userRole: string | null;

  constructor(
    private detectorService: DeviceDetectorService,
    private utilService: UtilService,
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
  ) {
    this.isMobileView = detectorService.isMobile();
  }

  public ngOnInit(): void {
    this.initUserData();
    this.authService.userIsAuthorized$.subscribe(userIsAuthorized => {
      this.isAuthenticated = userIsAuthorized;
      this.initUserData();
    });
    this.navigationService.openRightBar$.subscribe(res => {
      this.visible = !!res.value;
      if (res.section) {
        if (res.section === 'about') {
          this.activeIndex = res.isLoggedIn ? SETTINGS_TAB_INDEX_IN : SETTINGS_TAB_INDEX_OUT;
          this.settingsActivePanelIndex = res.isLoggedIn ? SETTINGS_ABOUT_PANEL_INDEX_IN : SETTINGS_ABOUT_PANEL_INDEX_OUT;
        } else if (res.section === 'privacy') {
          this.activeIndex = res.isLoggedIn ? SETTINGS_TAB_INDEX_IN : SETTINGS_TAB_INDEX_OUT;
          this.settingsActivePanelIndex = res.isLoggedIn ? SETTINGS_AGREEMENT_PANEL_INDEX_IN : SETTINGS_AGREEMENT_PANEL_INDEX_OUT;
        } else if (res.section === 'settings') {
          this.activeIndex = res.isLoggedIn ? SETTINGS_TAB_INDEX_IN : SETTINGS_TAB_INDEX_OUT;
          this.settingsActivePanelIndex = res.isLoggedIn ? SETTINGS_GENERAL_PANEL_INDEX_IN : null;
        } else if (res.section === 'wallets') {
          this.activeIndex = res.isLoggedIn ? WALLETS_TAB_INDEX_IN : 0;
          this.settingsActivePanelIndex = null;
        }
      } else {
        this.settingsActivePanelIndex = null;
        this.activeIndex = 0;
      }
      this.cdr.detectChanges();
    });
  }

  public onVisibleChange(visible: boolean) {
    if (!visible) {
      this.settingsActivePanelIndex = null;
      this.activeIndex = 0;
      this.cdr.detectChanges();
    }
  }

  public logout() {
    this.authService.logout()
    this.visible = false
  }

  private initUserData() {
    this.userMail = this.utilService.getUserMailFromDetails();
    this.userRole = this.utilService.getUserRoleFromDetails();
  }

}
