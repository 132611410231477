<ng-container *ngIf="fg && fcn; else wit">
  <form [formGroup]="fg">
    <div class="app-input">
      <span class="p-float-label">
        <input
          class="app-input__field app-input__field--color"
          pInputText
          id="{{id}}"
          type="{{type}}"
          placeholder="{{placeholder}}"
          formControlName="{{fcn}}"
          [ngClass]="{'ng-dirty': touched}"
          [style]="{width: width}"
          [disabled]="disabled"
          [readonly]="readonly"
        />
        <label class="app-input__label">{{ label }}</label>
      </span>
      <ng-container *ngIf="validationMessage">
        <small
          id="{{id}}-help"
          class="p-error"
          *ngIf="hasError">
          {{ validationMessage }}
        </small>
      </ng-container>
    </div>
  </form>
</ng-container>

<ng-template #wit>
  <div class="app-input">
    <span class="p-float-label">
      <input
        class="app-input__field app-input__field--color"
        pInputText
        id="{{id}}"
        type="{{type}}"
        placeholder="{{placeholder}}"
        [ngClass]="{'ng-dirty': touched}"
        [style]="{width: width}"
        [disabled]="disabled"
        [readonly]="readonly"
        [value]="value ?? ''"
        (change)="onValueChange.emit($event.target.value)"
      />
      <label class="app-input__label">{{ label }}</label>
    </span>
    <ng-container *ngIf="validationMessage">
      <small
        id="{{id}}-help"
        class="p-error"
        *ngIf="hasError">
        {{ validationMessage }}
      </small>
    </ng-container>
  </div>
</ng-template>
