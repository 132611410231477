import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SidebarModule} from 'primeng/sidebar';
import {ButtonModule} from 'primeng/button';
import {RightBarComponent} from './shared/components/right-bar/right-bar.component';
import {UtilService} from './shared/services/util.service';
import {TabViewModule} from 'primeng/tabview';
import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {HttpDataService} from './shared/services/http/http-data.service';
import {CalendarModule} from 'primeng/calendar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TableModule} from 'primeng/table';
import {WalletsComponent} from './shared/components/wallets/wallets.component';
import {SettingsComponent} from './shared/components/settings/settings.component';
import {ReferralSystemComponent} from './shared/components/referral-system/referral-system.component';
import {MailboxComponent} from './shared/components/mailbox/mailbox.component';
import {CasinoComponent} from './shared/components/casino/casino.component';
import {AccountSettingsComponent} from './shared/components/account-settings/account-settings.component';
import {ForgotPasswordModule} from './shared/components/forgot-password/forgot-password.module';
import {ThemeService} from './shared/services/theme.service';
import {AuthService} from './shared/services/auth.service';
import {JWT_OPTIONS, JwtHelperService} from '@auth0/angular-jwt';
import {DialogService} from 'primeng/dynamicdialog';
import {FooterComponent} from './shared/components/footer/footer.component';
import {AccordionModule} from 'primeng/accordion';
import {InputMaskModule} from 'primeng/inputmask';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {CurrencyChangeService} from './shared/services/currency-change.service';
import {CommonModule} from '@angular/common';
import {CarouselModule} from 'primeng/carousel';
import {WithdrawComponent} from './shared/components/wallets/withdraw/withdraw.component';
import {DepositComponent} from './shared/components/wallets/deposit/deposit.component';
import {TransactionComponent} from './shared/components/wallets/transaction/transaction.component';
import {InputNumberModule} from 'primeng/inputnumber';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {QRCodeModule} from 'angularx-qrcode';
import {NgxScannerQrcodeModule} from 'ngx-scanner-qrcode';
import {QrReaderComponent} from './shared/components/qr-reader/qr-reader.component';
import {HomeModule} from './modules/home/home.module';
import {ToastModule} from 'primeng/toast';
import {ToastService} from './shared/services/toast.service';
import {LocaleInterceptor} from './shared/interceptors/locale.interceptor';
import {SharedModule} from './shared/shared.module';
import {LoginPageComponent} from './shared/components/login-page/login-page.component';
import {RegisterComponent} from './shared/components/register/register.component';
import {CardModule} from 'primeng/card';
import {ReferralRedirectComponent} from './shared/classes/referral-redirect.component';
import {MessageService} from 'primeng/api';
import {MobileAccessPanelComponent} from './shared/components/mobile-access-panel/mobile-access-panel.component';
import {TokenInterceptor} from './shared/interceptors/token.interceptor';
import {SelectButtonModule} from 'primeng/selectbutton';
import {BadgeModule} from 'primeng/badge';
import {environment} from '../environments/environment';
import {API_URL, HttpIdentityService} from '../../libs/submodules/identity/src/lib/identity-client.service';
import {CanvasBackgroundComponent} from './canvas-background/canvas-background.component';

@NgModule({
  declarations: [
    AppComponent,
    RightBarComponent,
    WalletsComponent,
    SettingsComponent,
    ReferralSystemComponent,
    MailboxComponent,
    CasinoComponent,
    AccountSettingsComponent,
    FooterComponent,
    WithdrawComponent,
    DepositComponent,
    TransactionComponent,
    QrReaderComponent,
    LoginPageComponent,
    RegisterComponent,
    ReferralRedirectComponent,
    MobileAccessPanelComponent,
    CanvasBackgroundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SidebarModule,
    ButtonModule,
    TabViewModule,
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    CalendarModule,
    ReactiveFormsModule,
    FormsModule,
    TableModule,
    ForgotPasswordModule,
    AccordionModule,
    InputMaskModule,
    ClipboardModule,
    CommonModule,
    CarouselModule,
    InputNumberModule,
    FontAwesomeModule,
    QRCodeModule,
    NgxScannerQrcodeModule,
    HomeModule,
    ToastModule,
    SharedModule,
    CardModule,
    SelectButtonModule,
    BadgeModule,
  ],
  providers: [
    HttpIdentityService,
    {provide: API_URL, useValue: environment.apiUrl},
    {provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
    JwtHelperService,
    DialogService,
    UtilService,
    HttpDataService,
    ThemeService,
    AuthService,
    CurrencyChangeService,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true
    },
    ToastService,
  ],
  exports: [
    RegisterComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
