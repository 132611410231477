<div [@forgotPasswordSlideInAnimation]="'in'" class="forgotPass-container wrapper">
  <div class="scroll">
    <div class="card no-user-selection" *ngIf="!isForgotPasswordClosed">
      <div class="close-btn" (click)="closePage()">
        <span>
          <i class="pi pi-times"></i>
        </span>
      </div>

      <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" class="action forgot-pass-form">
        <div class="text-center">
          <h1 i18n="@@ForgotPasswordTitle">Forgot Password</h1>
        </div>

        <div class="input-box">
          <casino-input [id]="'email'"
                        [type]="'email'"
                        [width]="'100%'"
                        [hasError]="forgotPasswordForm.get('identifier')?.touched && forgotPasswordForm.get('identifier')?.hasError('required')"
                        [label]="'Email*'"
                        [placeholder]="'Enter your email'"
                        [validationMessage]="'Email is required'"
                        [touched]="forgotPasswordForm.get('identifier')?.touched"
                        [fcn]="'identifier'"
                        [fg]="forgotPasswordForm"
          ></casino-input>
          <small *ngIf="isEmailInvalid()" class="p-error block" i18n="@@InvalidEmailError">
            Invalid email format
          </small>
        </div>

        <div class="header-item text-btn">
          <button
            i18n="@@SendMailButton"
            class="inner-btn"
            type="submit"
          >Send mail
          </button>
        </div>

      </form>
    </div>
  </div>
</div>
