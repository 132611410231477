import {ChangeDetectorRef, Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {of, share, Subscription, timer, zip} from 'rxjs';
import {UtilService} from '../../services/util.service';
import {HttpDataService} from '../../services/http/http-data.service';
import {AuthService} from '../../services/auth.service';
import {Currency} from '../../model/account/currency.model';
import {CurrencyChangeService} from '../../services/currency-change.service';
import {LanguageModel} from '../../model/cultures/language.model';
import {WalletModel} from '../../model/wallet/user-wallet/wallet.model';
import {CurrencyConvertService} from '../../services/currency-convert.service';
import {map, switchMap} from 'rxjs/operators';
import {GAME_CURRENCY_ICONS_BG, LOCAL_STORAGE_TIME_ZONE} from '../../constants';
import {NavigationService} from '../../services/navigation.service';
import {PermissionsEnum} from '../../permissions.enum';
import {Response} from '../../model/response.model';
import * as moment from 'moment-timezone';
import {SearchService} from '../../services/search.service';
import {DeviceDetectorService} from '../../services/device-detector.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private domObserver: MutationObserver;
  public subscription: Subscription;
  public time = new Date();
  public fiatCurrencies: Currency[];
  public fiatCurrency: Currency;
  public mainCurrencies: Currency[];
  public mainCurrency: Currency;
  public languages: LanguageModel[];
  public selectedLanguage: LanguageModel;
  public currentWallet: WalletModel;
  public convertedBalance: number;
  public isMobileView: boolean;
  public isAuthenticated = false;
  public hasPermissionToReadAdministration: boolean;
  @Input() public timeZone: string;
  public readonly gameCurrencyIconsBG = GAME_CURRENCY_ICONS_BG;

  public constructor(
    private authService: AuthService,
    private httpDataService: HttpDataService,
    private utilService: UtilService,
    private searchService: SearchService,
    private cdr: ChangeDetectorRef,
    private currencyChangeService: CurrencyChangeService,
    private currencyConvertService: CurrencyConvertService,
    public navigationService: NavigationService,
    @Inject(LOCALE_ID) public locale: string,
    private detectorService: DeviceDetectorService,
  ) {
  }

  public ngOnInit(): void {
    this.initClock();
    this.hasPermissionToReadAdministration = this.authService.hasPermission(PermissionsEnum.organizationUser_read, [
      PermissionsEnum.organizationUser_edit,
      PermissionsEnum.organizationUser_delete,
    ]);
    this.isMobileView = this.detectorService.isMobile();
    this.initTimeZoneFromLocalStorage();
    this.fixDropDownPositions();
    this.subscribeToMainCurrencyChanges();
    this.subscribeToGameCurrencyChanges();
    this.initCurrenciesAndLanguages();
    this.authService.userIsAuthorized$.subscribe(userIsAuthorized => {
      this.isAuthenticated = userIsAuthorized && !!this.authService.permissions?.length;
      this.hasPermissionToReadAdministration = this.authService.hasPermission(PermissionsEnum.organizationUser_read, [
        PermissionsEnum.organizationUser_edit,
        PermissionsEnum.organizationUser_delete,
      ]);
      if (this.isAuthenticated && this.fiatCurrency && this.mainCurrency) {
        this.initCurrentWallet().pipe(
          switchMap(currentWallet => {
            this.currentWallet = currentWallet as WalletModel;
            if (this.fiatCurrency && this.mainCurrency) {
              return this.currencyConvertService.getCurrencyData(this.fiatCurrency.code, this.mainCurrency.code);
            } else return of(null);
          })
        ).subscribe({
            next: data => {
              if (data) {
                this.initConvertedBalance(data);
              }
            },
            error: error => console.error(error),
          });
      }
    });
  }

  public searchGame(searchGameName: string) {
    this.searchService.setSearchValue(searchGameName);
    if (searchGameName) {
      const providersEl = document.getElementById('casino-providers-slider-wrapper') as HTMLDivElement;
      if (providersEl) {
        providersEl.scrollIntoView();
      }
    }
  }

  private initClock() {
    this.subscription = timer(0, 1000)
      .pipe(
        map(() => new Date()),
        share()
      )
      .subscribe(time => {
        this.time = time;
        this.cdr.detectChanges();
      });
  }

  private initTimeZoneFromLocalStorage() {
    const savedTimeZone = localStorage.getItem(LOCAL_STORAGE_TIME_ZONE);
    if (savedTimeZone) {
      this.timeZone = savedTimeZone;
    } else {
      this.timeZone = moment.tz.guess();
      localStorage.setItem(LOCAL_STORAGE_TIME_ZONE, this.timeZone);
    }
  }

  private initCurrentWallet() {
    // if (this.authService.isAuthenticated()) {
      return this.utilService.getCurrentWallet();
    // }
  }

  public updateSelectedMainCurrency(currencyId: string) {
    this.mainCurrency = this.utilService.updateSelectedMainCurrency(currencyId, this.mainCurrencies);
  }

  private subscribeToMainCurrencyChanges() {
    this.currencyChangeService.isMainCurrencyChange$.pipe(
      switchMap((mainCurrency: Currency) => {
        this.mainCurrency = mainCurrency;
        return this.initCurrentWallet();
      }),
      switchMap(currentWallet => {
        this.currentWallet = currentWallet as WalletModel;
        if (this.fiatCurrency && this.mainCurrency) {
          return this.currencyConvertService.getCurrencyData(this.fiatCurrency.code, this.mainCurrency.code);
        } else return of(null);
      }),
    )
      .subscribe({
        next: data => {
          if (data) {
            this.initConvertedBalance(data);
          }
        },
        error: error => console.error(error),
      });
  }

  private subscribeToGameCurrencyChanges() {
    this.currencyChangeService.isFiatCurrencyChange$.pipe(
      switchMap((gameCurrency: Currency) => {
        this.fiatCurrency = gameCurrency;
        this.cdr.detectChanges();
        if (this.fiatCurrency && this.mainCurrency) {
          return this.currencyConvertService.getCurrencyData(this.fiatCurrency.code, this.mainCurrency.code);
        } else return of(null);
      })
    ).subscribe({
      next: data => {
        if (data) {
          this.initConvertedBalance(data);
        }
      },
      error: error => console.error(error),
    });
  }

  private initCurrenciesAndLanguages() {
    const storedFiatCurrencyId = this.utilService.getSelectedGameCurrency();
    const storedMainCurrencyId = this.utilService.getSelectedMainCurrency();
    zip(
      this.utilService.getGameCurrencies(),
      this.utilService.getMainCurrencies(),
      this.httpDataService.getCultures(),
    ).subscribe({
      next: ([fiatCurrencies, mainCurrencies, languages]) => {
        this.fiatCurrencies = fiatCurrencies as Currency[];
        this.fiatCurrency = this.utilService.updateSelectedGameCurrency(storedFiatCurrencyId || this.fiatCurrencies
          .find(fc => fc.code.toLowerCase() === 'usd')!.id, this.fiatCurrencies);
        this.mainCurrencies = (mainCurrencies as Currency[]).sort((a, b) => a.code
          .localeCompare(b.code));
        this.mainCurrency = this.utilService.updateSelectedMainCurrency(storedMainCurrencyId || this.mainCurrencies[0].id, this.mainCurrencies);
        this.initLanguages(languages as Response<LanguageModel[]>);
        this.cdr.detectChanges();
      }
    });
  }

  public goToAdministration() {
    if (this.isAuthenticated && this.hasPermissionToReadAdministration) {
      this.navigationService.navigateToUsers().then();
    }
  }

  private initLanguages(languages: Response<LanguageModel[]>) {
    if (languages.succeed) {
      this.languages = languages.value.sort((a: LanguageModel, b: LanguageModel) => a.threeLetterISOLanguageName
        .localeCompare(b.threeLetterISOLanguageName));
      const selectedLanguage = this.languages.find(l => l.twoLetterISOLanguageName === this.locale);
      if (selectedLanguage) {
        this.selectedLanguage = selectedLanguage;
        this.utilService.updateSelectedLanguage(selectedLanguage);
      }
    }
  }

  public onLanguageChange(language: LanguageModel) {
    if (language.twoLetterISOLanguageName === this.locale) {
      return;
    }
    this.selectedLanguage = language;
    this.utilService.updateSelectedLanguage(language);
  }

  public getCurrentTime() {
    if (!this.timeZone) {
      return new Date().toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit', second: '2-digit',})
    } else {
      const utcDate = new Date();
      const options = {
        timeZone: this.timeZone,
      };
      return utcDate.toLocaleTimeString(undefined, options);
    }
  }

  private fixDropDownPositions() {
    this.domObserver = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          const addedElement = mutation.addedNodes[0] as HTMLElement;
          const isCryptoDropDownOverlay = addedElement.classList.contains('p-overlay') && addedElement
            .querySelector('.header-dropdown-overlay-crypto');
          const isLanguageDropDownOverlay = addedElement.classList.contains('p-overlay') && addedElement
            .querySelector('.header-dropdown-overlay-lang');
          if (isCryptoDropDownOverlay || isLanguageDropDownOverlay) {
            this.addDropdownStyles(addedElement);
            setTimeout(() => {
              const dropdownRect = (document.querySelector(isCryptoDropDownOverlay ? '.drop-down-crypto'
                : '.drop-down-lang') as HTMLElement).getBoundingClientRect();
              addedElement.style.position = 'fixed';
              addedElement.style.top = dropdownRect.height + dropdownRect.top + 'px';
              addedElement.style.display = 'unset';
            }, 1);
          }
        }
      }
    });
    const bodyElem = document.querySelector('body')!;
    this.domObserver.observe(bodyElem, {
      childList: true,
    });
  }

  private addDropdownStyles(element: HTMLElement) {
    const panel = element.querySelector('.p-dropdown-panel') as HTMLElement;
    const itemsWrapper = element.querySelector('.p-dropdown-items-wrapper') as HTMLElement;
    panel.style.borderRadius = ' 30px 0 0 30px ';
    itemsWrapper.classList.add('custom-dropdown-panel');
    panel.classList.add('custom-dropdown-panel');
    const dropdownItems = element.querySelector('.p-dropdown-items') as HTMLElement;
    if (dropdownItems.children.length > 4) {
      panel.style.borderRadius = '20px';
      panel.style.paddingTop = '1.5px';
      panel.style.paddingLeft = '3.5px';
      panel.style.boxShadow = 'rgba(250, 250, 250, 0.25) 1.3px 1.5px 0px 0px inset, rgba(208, 208, 208, 0.5) 0.8px 1px 0px 1.5px inset'
    }
    Array.from(dropdownItems.children).forEach(
      (element: any) => {
        element.children[0].style.borderRadius = '100px 0   0 100px'
        element.children[0].style.padding = '10px'
        element.children[0].style.margin = '5px 1px'
        element.children[0].style.backgroundColor = ' rgba(33, 33, 33, 0.2)';
        element.children[0].style.boxShadow = 'rgba(0, 0, 0, 0.1) 3px 4px 4px 0px inset, rgba(0, 0, 0, 0.08) 1px 6px 9px 0px inset, rgba(255, 255, 255, 0.25) 2px 2px 4px 1px inset, rgba(255, 255, 255, 0.3) 2px 0px 0px 0px inset'
      }
    );
  }

  private initConvertedBalance(data: Response<any>) {
    const obj = data.value[`${this.mainCurrency.code.toUpperCase()}`];
    const num = obj ? (obj[this.fiatCurrency.code.toUpperCase()] as number) : null;
    if (num) {
      const rate = (data.value && num) ? +num.toFixed(6) : 0;
      this.convertedBalance = this.currentWallet?.balance ?
        parseFloat((this.currentWallet.balance * rate).toFixed(6)) : 0;
      this.cdr.detectChanges();
    }
  }

  public ngOnDestroy(): void {
    this.domObserver && this.domObserver.disconnect();
    this.subscription && this.subscription.unsubscribe();
  }

  public openWalletsBar() {
    const isAuthenticated = this.authService.isAuthenticated();
    if (isAuthenticated) {
      this.navigationService.openRightBar('wallets', isAuthenticated);
    }
  }
}
