<div class="providers-slide">
  <providers-slider [(isVisible)]="isProviderVisible" [isMobileView]="true"></providers-slider>
</div>

<div class="mobile-panel-wrapper">
  <div class="mobile-panel-top-border"></div>
  <div class="mobile-panel">
    <div class="panel-img">
      <img [src]="'assets/mobile-quick-access/provider.png'" (click)="openLeftBar()">
    </div>

    <div class="panel-img">
      <img [src]="'assets/mobile-quick-access/search.png'" (click)="openSearch()">
    </div>

    <div *ngIf="isSearchOpen" class="search-input">
      <div class="search-content">
        <input
          type="text"
          id="search-bar"
          placeholder="Search your game"
          i18n-placeholder="@@SearchGamePlaceholder"
          #searchInput
          (keyup.enter)="searchGame(searchInput.value)"
          autofocus
        >
        <label class="icon">
          <i class="fa fa-search"
             (click)="searchGame(searchInput.value)"
          ></i>
        </label>
      </div>
    </div>

    <div class="panel-img">
      <img [src]="'assets/mobile-quick-access/menu.png'" (click)="openProviderSlide()">
    </div>

    <div class="panel-img">
      <img [src]="'assets/mobile-quick-access/wheel.png'">
    </div>
  </div>
</div>
