import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private searchValueSubject = new Subject<string>();

  setSearchValue(searchValue: string): void {
    this.searchValueSubject.next(searchValue);
  }

  getSearchValue(): Observable<string> {
    return this.searchValueSubject.asObservable();
  }
}
