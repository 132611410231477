import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {NgxScannerQrcodeComponent, ScannerQRCodeConfig, ScannerQRCodeResult} from 'ngx-scanner-qrcode';

@Component({
  selector: 'app-qr-reader',
  templateUrl: './qr-reader.component.html',
  styleUrls: ['./qr-reader.component.scss']
})
export class QrReaderComponent {
  public selectedDevice: any;
  public config: ScannerQRCodeConfig = {
    constraints: {
      video: {
        width: window.innerWidth
      },
    },
  };

  @Output() qrReaderValue = new EventEmitter<string>;

  @ViewChild('action') action!: NgxScannerQrcodeComponent;

  public ngAfterViewInit(): void {
    this.action.isReady.subscribe(() => {
      this.handle(this.action, 'start');
    });
  }

  public onEvent(e: ScannerQRCodeResult[]): void {
    this.qrReaderValue.emit(e[0].value);
    this.action.stop();
  }

  public handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: any[]) => {
      const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    }

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe();
    } else {
      action[fn]().subscribe();
    }
  }
}
