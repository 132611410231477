<div class="app-input">
  <span class="p-float-label">
    <p-calendar
      class="app-input__field app-input__field--color"
      [style]="{width: width}"
      [showIcon]="showIcon"
      [showTime]="showTime"
      [showClear]="showClear"
      [ngModel]="value"
      [readonlyInput]="readonlyInput"
      (onSelect)="onValueChange.emit($event)"
      placeholder="{{placeholder}}"
    ></p-calendar>
    <label class="app-input__label">{{ label }}</label>
  </span>
</div>
