<div class="flex flex-column gap-4">
  <div class="dropdown">
    <p-dropdown [options]="action.devices.value"
                [style]="{borderRadius: '16px', height: '50px', borderBottom: '3px solid rgba(255, 255, 255, 0.3)',}"
                [(ngModel)]="selectedDevice"
                (onChange)="action.playDevice($event.value)"
                id="deviceDropdown"
                placeholder="Select device: "
                i18n-placeholder="@@SelectDevicePlaceholder"></p-dropdown>
  </div>
  <ngx-scanner-qrcode #action="scanner" [config]="config" (event)="onEvent($event)"></ngx-scanner-qrcode>
  <div class="buttons">
    <button
      type="button"
      pButton
      pRippl
      class="qr-btn"
      (click)="handle(action, 'start')"
      i18n="@@StartButton">Start
    </button>
    <button
      type="button"
      pButton
      pRippl
      class="qr-btn"
      (click)="handle(action, 'stop')"
      i18n="@@StopButton">Stop
    </button>
  </div>
</div>
