import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';

@Injectable()
export class NavigationService {
  public showRegisterPage: boolean;
  public showLoginPage: boolean;
  public showFPPage: boolean;
  public openLeftBar$ = new Subject<{ value: boolean, section?: string }>();
  public openProviderSlide$ = new Subject<{ value: boolean }>();
  public openRightBar$ = new Subject<{ value: boolean, section?: string, isLoggedIn?: boolean }>();

  public constructor(
    private router: Router,
  ) {
  }

  public navigateToHome(categoryName?: string): Promise<boolean> {
    const category = categoryName || 'home';
    return this.router.navigate([`/${category}`]);
  }

  public navigateToUsers(): Promise<boolean> {
    return this.router.navigate(['/administration/users']);
  }

  public navigateToUsersByCreator(): Promise<boolean> {
    return this.router.navigate(['/administration/usersByCreator']);
  }

  public navigateToAdministrationRoles(): Promise<boolean> {
    return this.router.navigate(['/administration/roles']);
  }

  public navigateToAdministrationMessages(): Promise<boolean> {
    return this.router.navigate(['/administration/messages']);
  }

  public navigateToHostedServices(): Promise<boolean> {
    return this.router.navigate(['/administration/hostedServices']);
  }

  public navigateToGames(): Promise<boolean> {
    return this.router.navigate(['/administration/games']);
  }

  public navigateToGameProviders(): Promise<boolean> {
    return this.router.navigate(['/administration/providers']);
  }

  public navigateToUserProfileByUserId(userId: string): Promise<boolean> {
    return this.router.navigate([`/administration/users/profile/${userId}`]);
  }

  public navigateToUserBonuses(userId: string): Promise<boolean> {
    return this.router.navigate([`/administration/users/bonuses/${userId}`]);
  }

  public navigateToUserSessionByUserId(userId: string): Promise<boolean> {
    return this.router.navigate([`/administration/users/sessions/${userId}`]);
  }

  public navigateToJobByServiceName(serviceName: string): Promise<boolean> {
    return this.router.navigate([`/administration/hostedServices/jobs/${serviceName}`]);
  }


  public openProviderSlide() {
    this.openProviderSlide$.next({value: true});
  }

  public closeProviderSlide() {
    this.openProviderSlide$.next({value: false});
  }

  public openRightBar(section?: string, isLoggedIn?: boolean) {
    if (section) {
      this.openRightBar$.next({value: true, section, isLoggedIn});
    } else {
      this.openRightBar$.next({value: true});
    }
  }

  public openLeftBar() {
    this.openLeftBar$.next({value: true});
  }

  public closeLeftBar() {
    this.openLeftBar$.next({value: false});
  }

  public closeAllPages() {
    this.closeRegisterPage();
    this.closeFPPage();
    this.closeLoginPage();
  }

  public closeRegisterPage() {
    this.showRegisterPage = false;
  }

  public closeLoginPage() {
    this.showLoginPage = false;
  }

  public closeFPPage() {
    this.showFPPage = false;
  }

  public showForgotPassPage() {
    this.showFPPage = true;
    this.closeLoginPage();
  }

  public showRegister() {
    this.showRegisterPage = true;
    this.closeLoginPage();
    this.closeFPPage();
  }

  public showLogin() {
    this.showLoginPage = true;
    this.closeRegisterPage()
    this.closeFPPage();
  }


}
