import {NgModule} from '@angular/core';
import {HomeComponent} from './home.component';
import {HomeRoutingModule} from './home-routing.module';
import {CommonModule} from '@angular/common';
import {GalleriaModule} from 'primeng/galleria';
import {GameCardComponent} from './components/game-card/game-card.component';
import {GameCarouselComponent} from './components/game-carousel/game-carousel.component';
import {CarouselModule} from 'primeng/carousel';
import {SharedModule} from '../../shared/shared.module';
import {ProvidersSliderComponent} from '../../shared/components/providers-slider/providers-slider.component';

@NgModule({
  declarations: [
    HomeComponent,
    GameCardComponent,
    GameCarouselComponent,
    ProvidersSliderComponent
  ],
  exports: [
    HomeComponent,
    ProvidersSliderComponent
  ],
  imports: [
    HomeRoutingModule,
    CommonModule,
    GalleriaModule,
    CarouselModule,
    SharedModule,
  ]
})
export class HomeModule {
}
