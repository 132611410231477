import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardModule} from 'primeng/card';
import {HttpDataService} from '../../services/http/http-data.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {ForgotPasswordComponent} from './forgot-password.component';
import {SharedModule} from '../../shared.module';




const routes: Routes = [{ path: '', component: ForgotPasswordComponent }];

@NgModule({
  declarations: [
    ForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    CardModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    SharedModule,
  ],
  exports: [
    ForgotPasswordComponent
  ],
  providers: [
    HttpDataService,
  ]
})
export class ForgotPasswordModule { }
