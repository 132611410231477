import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'casino-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputNumberComponent {
  @Input() public id: string;
  @Input() public placeholder: string;
  @Input() public label: string;
  @Input() public validationMessage: string;
  @Input() public type = 'text';
  @Input() public disabled = false;
  @Input() public touched = false;
  @Input() public hasError = false;
  @Input() public width = '250px';
  @Input() public fcn: string;
  @Input() public fg: FormGroup;
  @Input() public value: string | number = '';
  @Input() public min: number;
  @Input() public minFractionDigits: number;
  @Input() public maxFractionDigits: number;

  @Output() public onValueChange = new EventEmitter<string>();

}
