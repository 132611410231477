import {MultilingualStringModel} from '../app/shared/model/multilingual-string.model';

export const REQUEST_ERROR: MultilingualStringModel = {
  ru: 'Во время обработки Вашего запроса произошла ошибка.',
  en: 'An error occurred while processing your request.',
  es: 'Se produjo un error al procesar su solicitud.',
  pt: 'Um erro ocorreu durante o processamento do seu pedido.',
  fr: 'Une erreur s\'est produite lors du traitement de votre demande.',
  az: 'Sorğunuzu emal edərkən xəta baş verdi.',
  tr: 'İşleminiz gerçekleştirilirken bir hata oluştu.'
}

export const UNCATEGORIZED: MultilingualStringModel = {
  ru: 'Игры по категориям',
  en: 'Games by Categories',
  es: 'Juegos por categorías',
  pt: 'Jogos por categorias',
  fr: 'Jeux par catégories',
  az: 'Kateqoriyalar üzrə oyunlar',
  tr: 'Kategorilere Göre Oyunlar'
}

export const GAME_PROVIDER: MultilingualStringModel = {
  ru: 'Провайдер игр',
  en: 'Game Provider',
  es: 'Proveedor de juegos',
  pt: 'Provedor de jogos',
  fr: 'Fournisseur de jeux',
  az: 'Oyun Provayderi',
  tr: 'Oyun Sağlayıcı'
}

export const GAME_TYPE: MultilingualStringModel = {
  ru: 'Типы игр',
  en: 'Game Type',
  es: 'Tipo de juego',
  pt: 'Tipo de jogo',
  fr: 'Type de jeu',
  az: 'Oyun növü',
  tr: 'Oyun tipi'
}

export const ALL_GAMES: MultilingualStringModel = {
  ru: 'Все игры',
  en: 'All Games',
  es: 'Todos los juegos',
  pt: 'Todos os jogos',
  fr: 'Tous les jeux',
  az: 'Bütün Oyunlar',
  tr: 'Bütün Oyunlar'
}

export const FAVOURITE_GAMES: MultilingualStringModel = {
  en: 'Favourite Games',
  ru: 'Избранные',
  es: 'Favoritos',
  pt: 'Favoritos',
  fr: 'Favoris',
  az: 'Sevimlilər',
  tr: 'Favoriler'
}

export const LOGIN_SUCCESS: MultilingualStringModel = {
  en: 'Successful login!',
  ru: 'Вход в систему успешен!',
  es: 'Acceso exitoso!',
  pt: 'Registração falhou. Por favor insira os dados corretos.',
  fr: 'Échec de l\'enregistrement.Veuillez saisir des données correctes.',
  az: 'Qeydiyyat uğursuz oldu. Zəhmət olmasa düzgün məlumatları daxil edin.',
  tr: 'Başarılı Giriş!'
}

export const PROFILE_UPDATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Profile updated successfully.',
  ru: 'Профиль успешно обновлен.',
  es: 'Perfil actualizado con éxito.',
  pt: 'Perfil atualizado com sucesso.',
  fr: 'Mise à jour du profil réussie.',
  az: 'Profil uğurla yeniləndi.',
  tr: 'Profil başarıyla güncellendi.',
}

export const JOB_STATUS_RESET: MultilingualStringModel = {
  en: 'Job status reset.',
  ru: 'Статус задачи сброшен.',
  es: 'Restablecer el estado del trabajo.',
  pt: 'Redefinição do status do trabalho.',
  fr: 'Réinitialisation de l\'état du travail.',
  az: 'İş statusu sıfırlandı.',
  tr: 'İş durumu sıfırlandı.',
}

export const SERVICE_STOPPED: MultilingualStringModel = {
  en: 'Service stopped.',
  ru: 'Служба остановлена.',
  es: 'El servicio se detuvo.',
  pt: 'Serviço parado.',
  fr: 'Service arrêté.',
  az: 'Xidmət dayandı.',
  tr: 'Servis durduruldu.',
}

export const SERVICE_STARTED: MultilingualStringModel = {
  en: 'Service started.',
  ru: 'Служба запустилась.',
  es: 'Se inició el servicio.',
  pt: 'Serviço iniciado.',
  fr: 'Service démarré.',
  az: 'Xidmət başladı.',
  tr: 'Servis başladı.',
}

export const SERVICES_STARTED: MultilingualStringModel = {
  en: 'Services started.',
  ru: 'Службы начались.',
  es: 'Los servicios comenzaron.',
  pt: 'Os serviços foram iniciados.',
  fr: 'Les services ont commencé.',
  az: 'Xidmətlər başladı.',
  tr: 'Hizmetler başladı.',
}

export const ROLES_UPDATED: MultilingualStringModel = {
  en: 'Roles updated.',
  ru: 'Роли обновлены.',
  es: 'Roles actualizados.',
  pt: 'Funções atualizadas.',
  fr: 'Rôles mis à jour..',
  az: 'Rollar yeniləndi.',
  tr: 'Roller güncellendi.',
}

export const ROLE_CREATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'New role created.',
}

export const ROLE_UPDATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Role updated.',
}

export const ROLE_DELETED: MultilingualStringModel = {
  en: 'Role deleted.',
}

export const ORGANIZATION_CREATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'New organization created.',
}

export const ORGANIZATION_UPDATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Organization updated.',
}

export const ORGANIZATION_DELETED: MultilingualStringModel = {
  en: 'Organization deleted.',
}

export const USER_DELETED: MultilingualStringModel = {
  en: 'User deleted.',
  ru: 'User deleted.',
  es: 'User deleted.',
  pt: 'User deleted.',
  fr: 'User deleted.',
  az: 'User deleted.',
  tr: 'User deleted.',
}

export const OPERATION_SUCCESSFUL: MultilingualStringModel = {
  en: 'Operation successful!',
  ru: 'Операция успешна!',
  es: 'Operación exitosa!',
  pt: 'Operação bem sucedida!',
  fr: 'Opération réussie!',
  az: 'Əməliyyat uğurludur!',
  tr: 'Operasyon başarılı!',
}

export const PASSWORD_CHANGED: MultilingualStringModel = {
  en: 'Password changed.',
  ru: 'Пароль изменен.',
  es: 'Contraseña cambiada.',
  pt: 'Senha alterada.',
  fr: 'Mot de passe changé.',
  az: 'Parol dəyişdirildi.',
  tr: 'Şifre değişti.',
}

export const ORGANIZATION_BLOCKED: MultilingualStringModel = {
  en: 'Organization blocked successfully.',
  ru: 'Organization blocked successfully.',
  es: 'Organization blocked successfully.',
  pt: 'Organization blocked successfully.',
  fr: 'Organization blocked successfully.',
  az: 'Organization blocked successfully.',
  tr: 'Organization blocked successfully.',
}

export const GAME_PROVIDER_UPDATED: MultilingualStringModel = {
  en: 'Game provider is updated.',
  ru: 'Game provider is updated.',
  es: 'Game provider is updated.',
  pt: 'Game provider is updated.',
  fr: 'Game provider is updated.',
  az: 'Game provider is updated.',
  tr: 'Game provider is updated.',
}

export const REGISTRATION_FAILED: MultilingualStringModel = {
  en: 'Registration failed. Please enter correct data.',
  ru: 'Регистрация не удалась. Пожалуйста, введите правильные данные.',
  es: 'Registro fallido. Por favor ingrese los datos correctos.',
  pt: 'Registração falhou. Por favor insira os dados corretos.',
  fr: 'Échec de l\'enregistrement.Veuillez saisir des données correctes.',
  az: 'Qeydiyyat uğursuz oldu. Zəhmət olmasa düzgün məlumatları daxil edin.',
  tr: 'Kayıt başarısız. Lütfen doğru verileri girin.'
}

// export const REGISTRATION_DATA_ALREADY_EXIST: MultilingualStringModel = {
//   en: 'Registration failed. The data you entered already exists.',
//   ru: 'Регистрация не удалась. Введенные вами данные уже существуют.',
//   es: 'Registro fallido. Los datos que ingresaste ya existen.',
//   pt: 'Registração falhou. Os dados que você inseriu já existem.',
//   fr: 'Échec de l\'enregistrement.Les données que vous avez saisies existent déjà.',
//   az: 'Qeydiyyat uğursuz oldu. Daxil etdiyiniz data artıq mövcuddur.',
//   tr: 'Kayıt başarısız. Girdiğiniz veriler zaten mevcut.'
// }

export const REGISTRATION_SUCCESS: MultilingualStringModel = {
  en: 'Successful registration!',
  ru: 'Регистрация успешна!',
  es: 'Registro exitoso!',
  pt: 'Cadastro realizado com sucesso!',
  fr: 'Inscription réussie !',
  az: 'Uğurlu qeydiyyat!',
  tr: 'Başarılı kayıt!'
}

export const SESSION_DELETED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Session deleted successfully.',
  ru: 'Сессия удалена успешно.',
  es: 'Session supprimée avec succès.',
  pt: 'Sessão excluída com sucesso.',
  fr: 'Session supprimée avec succès.',
  az: 'Sessiya uğurla silindi.',
  tr: 'Oturum başarıyla silindi.'
}

// export const WALLET_GET_FAILURE: MultilingualStringModel = {
//   en: 'Error occurred when getting the wallet.',
//   ru: 'При создании кошелька произошла ошибка .',
//   es: 'Se produjo un error al obtener la billetera.',
//   pt: 'Ocorreu um erro ao obter a carteira.',
//   fr: 'Une erreur s\'est produite lors de l\'obtention du portefeuille.',
//   az: 'Pul kisəsini əldə edərkən xəta baş verdi.',
//   tr: 'Cüzdan alınırken hata oluştu.'
// }

export const USER_CREATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'New user created.',
  ru: 'Создан новый пользователь.',
  es: 'Nuevo usuario creado.',
  pt: 'Novo usuário criado.',
  fr: 'Nouvel utilisateur créé.',
  az: 'Yeni istifadəçi yaradıldı.',
  tr: 'Yeni kullanıcı oluşturuldu.'
}

export const PROVIDER_CREATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'New provider created.',
  ru: 'Создан новый провайдер.',
  es: 'New provider created.',
  pt: 'New provider created..',
  fr: 'New provider created.',
  az: 'New provider created.',
  tr: 'New provider created.'
}

export const GAME_CREATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'New game created.',
  ru: 'Создана новая игра.',
  es: 'New game created.',
  pt: 'New game created..',
  fr: 'New game created.',
  az: 'New game created.',
  tr: 'New game created.'
}

export const TRANSFERRED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Money transferred successfully.',
  ru: 'Деньги успешно переведены.',
  es: 'Dinero transferido exitosamente.',
  pt: 'Dinheiro transferido com sucesso.',
  fr: 'Argent transféré avec succès.',
  az: 'Pul uğurla köçürüldü.',
  tr: 'Para başarıyla aktarıldı.'
}

// export const TRANSFER_FAILURE: MultilingualStringModel = {
//   en: 'Error occurred when transferring money to the wallet.',
//   ru: 'При переводе денег на кошелек произошла ошибка.',
//   es: 'Se produjo un error al transferir dinero a la billetera.',
//   pt: 'Ocorreu um erro ao transferir dinheiro para a carteira.',
//   fr: 'Une erreur s\'est produite lors du transfert d\'argent vers le portefeuille.',
//   az: 'Pul kisəsinə pul köçürərkən xəta baş verdi.',
//   tr: 'Cüzdana para aktarılırken hata oluştu.'
// }

export const TRANSACTION_TYPE: { id: number, name: MultilingualStringModel }[] = [
  {
    id: 1,
    name: {
      en: 'Wallet',
      ru: 'Кошелек',
      es: 'Billetera',
      pt: 'Carteira',
      fr: 'Portefeuille',
      az: 'Pul kisəsi',
      tr: 'Cüzdan'
    }
  },
  {
    id: 2,
    name: {en: 'Crypto', ru: 'Крипто', es: 'Cripto', pt: 'Cripto', fr: 'Cryptomonnaie', az: 'Kripto', tr: 'Kripto'}
  },
  {
    id: 3,
    name: {
      en: 'Transfer',
      ru: 'Перевод',
      es: 'Transferir',
      pt: 'Transferir',
      fr: 'Transfert',
      az: 'Transfer',
      tr: 'Aktar'
    }
  },
  {id: 4, name: {en: 'Casino', ru: 'Казино', es: 'Casino', pt: 'Cassino', fr: 'Casino', az: 'Casino', tr: 'Kumarhane'}},
  {
    id: 5,
    name: {
      en: 'Referral System',
      ru: 'Система рефералов',
      es: 'Sistema de referencia',
      pt: 'Sistema de referencia',
      fr: 'Système de référence',
      az: 'İstinad sistemi',
      tr: 'Tavsiye sistemi'
    }
  },
];

export const GENDERS: { name: MultilingualStringModel; }[] = [
  {name: {en: 'Male', ru: 'Мужской', es: 'Masculino', pt: 'Macho', fr: 'Mâle', az: 'Kişi', tr: 'Erkek'}},
  {name: {en: 'Female', ru: 'Женский', es: 'Femenina', pt: 'Fêmea', fr: 'Femelle', az: 'Qadın', tr: 'Dişi'}},
  {name: {en: 'Other', ru: 'Другой', es: 'Otro', pt: 'Outro', fr: 'Autre', az: 'Digər', tr: 'Diğer'}}
];

export const SUCCESS_RESET_PASSWORD: MultilingualStringModel = {
  en: 'You requested to reset your password. We sent verification link to your email. Please, check it.',
  ru: 'Мы отправили вам ссылку для подтверждения на вашу электронную почту. Пожалуйста, проверьте её.',
  es: 'Solicitó restablecer su contraseña. Le enviamos un enlace de verificación a su correo electrónico. Compruébelo por favor.',
  pt: 'Você solicitou a redefinição de sua senha. Enviamos link de verificação para seu e-mail. Por favor verifique isto.',
  fr: 'Vous avez demandé la réinitialisation de votre mot de passe. Nous avons envoyé un lien de vérification à votre adresse e-mail. S\'il te plaît vérifie le.',
  az: 'Siz parolunuzu sıfırlamağı tələb etdiniz. Doğrulama linkini e-poçtunuza göndərdik. Zəhmət olmasa yoxlayın.',
  tr: 'Şifrenizi sıfırlama talebinde bulundunuz. Doğrulama bağlantısını e-postanıza gönderdik. Lütfen kontrol et.'
}

// export const INVALID_EMAIL: MultilingualStringModel = {
//   en: 'Invalid email',
//   ru: 'Неверный адрес электронной почты',
//   es: 'Email inválido',
//   pt: 'E-mail inválido',
//   fr: 'Email invalide',
//   az: 'Səhv e-mail',
//   tr: 'Geçersiz e-posta'
// }

export const CHANGE_PASSWORD_SUCCESS: MultilingualStringModel = {
  en: 'Success! Your password has been changed.',
  ru: 'Отлично! Ваш пароль успешно изменен.',
  es: '¡Éxito! Tu contraseña ha sido cambiada.',
  pt: 'Sucesso! Sua senha foi mudada.',
  fr: 'Succès! Votre mot de passe a été changé.',
  az: 'Uğurlar! Parolunuz dəyişdirilib.',
  tr: 'Başarı! Şifreniz değiştirildi.'
}

export const PROVIDER_DELETED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Provider deleted successfully.',
  ru: 'Провайдер удален успешно.',
  es: 'Provider deleted successfully.',
  pt: 'Provider deleted successfully.',
  fr: 'Provider deleted successfully.',
  az: 'Provider deleted successfully.',
  tr: 'Provider deleted successfully.'
}

export const ORGANIZATION_ROLE_DELETED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Organization role deleted successfully.',
  ru: 'Organization role deleted successfully.',
  es: 'Organization role deleted successfully.',
  pt: 'Organization role deleted successfully.',
  fr: 'Organization role deleted successfully.',
  az: 'Organization role deleted successfully.',
  tr: 'Organization role deleted successfully.'
}

export const ORGANIZATION_ROLE_ADDED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Organization role added successfully.',
  ru: 'Organization role added successfully.',
  es: 'Organization role added successfully.',
  pt: 'Organization role added successfully.',
  fr: 'Organization role added successfully.',
  az: 'Organization role added successfully.',
  tr: 'Organization role added successfully.'
}

export const ORGANIZATION_CURRENCY_DELETED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Organization currency deleted successfully.',
  ru: 'Organization currency deleted successfully.',
  es: 'Organization currency deleted successfully.',
  pt: 'Organization currency deleted successfully.',
  fr: 'Organization currency deleted successfully.',
  az: 'Organization currency deleted successfully.',
  tr: 'Organization currency deleted successfully.'
}

export const ORGANIZATION_CURRENCY_ADDED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Organization currency added successfully.',
  ru: 'Organization currency added successfully.',
  es: 'Organization currency added successfully.',
  pt: 'Organization currency added successfully.',
  fr: 'Organization currency added successfully.',
  az: 'Organization currency added successfully.',
  tr: 'Organization currency added successfully.'
}

export const GAME_DELETED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Game deleted successfully.',
  ru: 'Игра удалена успешно.',
  es: 'Game deleted successfully.',
  pt: 'Game deleted successfully.',
  fr: 'Game deleted successfully.',
  az: 'Game deleted successfully.',
  tr: 'Game deleted successfully.'
}

export const MESSAGE_CREATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'New message created.',
  ru: 'New message created.',
  es: 'New message created.',
  pt: 'New message created.',
  fr: 'New message created.',
  az: 'New message created.',
  tr: 'New message created.',
}

export const MESSAGE_SENT_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Message sent.',
  ru: 'Message sent.',
  es: 'Message sent.',
  pt: 'Message sent.',
  fr: 'Message sent.',
  az: 'Message sent.',
  tr: 'Message sent.',
}

// export const CHANGE_PASSWORD_FAILED: MultilingualStringModel = {
//   en: 'Sorry, the password change was unsuccessful. Please make sure you entered the current password correctly and try again. If the issue persists, contact customer support.',
//   ru: 'Извините, не удалось изменить пароль. Пожалуйста, убедитесь, что вы ввели текущий пароль правильно и попробуйте еще раз. Если проблема сохраняется, обратитесь в службу поддержки.',
//   es: 'Lo sentimos, el cambio de contraseña no fue exitoso. Asegúrese de haber ingresado la contraseña actual correctamente e inténtelo nuevamente. Si el problema persiste, comuníquese con atención al cliente.',
//   pt: 'Desculpe, a alteração da senha não foi bem-sucedida. Certifique-se de inserir a senha atual corretamente e tente novamente. Se o problema persistir, entre em contato com o suporte ao cliente.',
//   fr: 'Désolé, la modification du mot de passe n\'a pas abouti.Veuillez vous assurer que vous avez saisi correctement le mot de passe actuel et réessayer.Si le problème persiste, contactez le support client.',
//   az: 'Üzr istəyirik, parol dəyişikliyi uğursuz oldu. Cari parolu düzgün daxil etdiyinizə əmin olun və yenidən cəhd edin. Problem davam edərsə, müştəri dəstəyi ilə əlaqə saxlayın.',
//   tr: 'Üzgünüz, şifre değişikliği başarısız oldu. Lütfen mevcut şifrenizi doğru girdiğinizden emin olun ve tekrar deneyin. Sorun devam ederse müşteri desteğiyle iletişime geçin.'
// }

export const THEMES: { theme: string, name: MultilingualStringModel }[] = [
  {
    theme: 'theme1',
    name: {
      en: 'Midnight Shadows',
      ru: 'Полуночные Тени',
      es: 'Sombras de medianoche',
      pt: 'Sombras da meia-noite',
      fr: 'Ombres de minuit',
      az: 'Gecə yarısı kölgələri',
      tr: 'Gece Yarısı Gölgeleri'
    }
  },
  {
    theme: 'theme2',
    name: {
      en: 'Harmony Oasis',
      ru: 'Гармония Оазиса',
      es: 'Oasis de armonía',
      pt: 'Oásis de Harmonia',
      fr: 'Oasis d\'Harmonie',
      az: 'Harmoniya Oasis',
      tr: 'Uyum Vahası'
    }
  },
  {
    theme: 'theme3',
    name: {
      en: 'Mysterious Nightfall',
      ru: 'Таинственный Сумрак',
      es: 'Mysterious Nightfall',
      pt: 'Mysterious Nightfall',
      fr: 'Mysterious Nightfall',
      az: 'Mysterious Nightfall',
      tr: 'Gizemli Akşam Vakti'
    }
  },
  {
    theme: 'theme4',
    name: {
      en: 'Fiery Ember',
      ru: 'Пылающая Уголь',
      es: 'Fiery Ember',
      pt: 'Fiery Ember',
      fr: 'Fiery Ember',
      az: 'Fiery Ember',
      tr: 'Ateşli Kor'
    }
  },
  {
    theme: 'theme5',
    name: {
      en: 'Light',
      ru: 'Light',
      es: 'Light',
      pt: 'Light',
      fr: 'Light',
      az: 'Light',
      tr: 'Light'
    }
  },
  {
    theme: 'theme6',
    name: {
      en: 'Light2',
      ru: 'Light2',
      es: 'Light2',
      pt: 'Light2',
      fr: 'Light2',
      az: 'Light2',
      tr: 'Light2'
    }
  },
];
