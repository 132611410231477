import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {Observable, of, Subject, zip} from 'rxjs';
import {Router} from '@angular/router';
import {
  DEFAULT_LANGUAGE,
  LOCAL_STORAGE_ACCOUNT_DETAILS,
  LOCAL_STORAGE_AUTH_TOKEN,
  LOCAL_STORAGE_GAME_CURRENCY,
  LOCAL_STORAGE_LANGUAGE,
  LOCAL_STORAGE_MAIN_CURRENCY,
  LOCAL_STORAGE_TIME_ZONE
} from '../constants';
import {Currency} from '../model/account/currency.model';
import {CurrencyChangeService} from './currency-change.service';
import {map, switchMap} from 'rxjs/operators';
import {HttpDataService} from './http/http-data.service';
import {Response} from '../model/response.model';
import {WalletModel} from '../model/wallet/user-wallet/wallet.model';
import {LanguageModel} from '../model/cultures/language.model';
import {GameModel} from '../model/game/game.model';
import {GameCategoryModel} from '../model/game/game-category.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GENDERS, THEMES, TRANSACTION_TYPE} from '../../../locale/multilingual-strings-constants';
import {CategoryTypeEnum} from '../model/category-type.enum';
import {OrganizationModel} from '../model/user/organization.model';
import {TreeNode} from 'primeng/api';
import {NewResponse} from '../model/response';
import {TransactionRecordModel} from '../model/transaction/transaction-record.model';
import {OrganizationHierarchicalIdsModel} from '../model/user/organization-hierarchical-ids.model';
import {ProfileModel} from '../../../../libs/submodules/identity/src/lib/models/profile.model';

@Injectable()
export class UtilService {
  private currencyCache: Currency[];
  public timeZoneChange$: Subject<string> = new Subject<string>();
  public currentUserId$ = new Subject<string | null>();
  public allCategories$ = new Subject<GameCategoryModel[]>();
  public gameProviders: GameCategoryModel[] = [];
  public allGames: Map<string, GameModel[]> = new Map;
  public allHiddenGames: Map<string, GameModel[]> = new Map;
  public allCategories: GameCategoryModel[];

  public constructor(
    private router: Router,
    private currencyChangeService: CurrencyChangeService,
    private httpDataService: HttpDataService,
    private fb: FormBuilder,
    @Inject(LOCALE_ID) public locale: string,
  ) {
  }

  public changeTimeZone(timeZone: string): void {
    localStorage.setItem(LOCAL_STORAGE_TIME_ZONE, timeZone);
    this.timeZoneChange$.next(timeZone);
  }

  private handleAllGamesResponse(response: Response<GameModel[]>): boolean {
    if (response.succeed) {
      response.value.forEach(game => {
        const providerId = game.categories.find(category => this.gameProviders.find(gameProvider => category === gameProvider.id))!;
        game.gameProviderId = providerId;
        const providerGames = this.allGames.get(providerId) || [];
        this.allGames.set(providerId, [...providerGames, game]);
      });
      return true;
    } else {
      return false;
    }
  }

  public initCategories(): Observable<boolean> {
    if (this.allGames.size) {
      return of(true);
    }
    return this.httpDataService.getAllCategories().pipe(
      switchMap(allCategories => {
        this.allCategories = allCategories.value;
        this.allCategories$.next(allCategories.value);
        this.gameProviders = allCategories.value.filter(provider => provider.categoryType === CategoryTypeEnum.GameProvider);
        return this.httpDataService.getAllGames().pipe(
          map((gamesResponse: Response<GameModel[]>) =>
            this.handleAllGamesResponse(gamesResponse)
          )
        );
      })
    );
  }

  public openGame(game: GameModel, isDemo: boolean): void {
    const providerName = this.getProviderNameById(game.gameProviderId);
    this.router.navigate([`/game/${providerName}/${game.name}/${isDemo}`]).then();
  }

  public getSelectedLanguage() {
    return localStorage.getItem(LOCAL_STORAGE_LANGUAGE) || DEFAULT_LANGUAGE;
  }

  public getSelectedMainCurrency(): string {
    return localStorage.getItem(LOCAL_STORAGE_MAIN_CURRENCY)!;
  }

  public getSelectedGameCurrency(): string {
    return localStorage.getItem(LOCAL_STORAGE_GAME_CURRENCY)!;
  }

  public getUserIdFromDetails(): string | null {
    const decodedToken = UtilService.getDecodedToken()
    return decodedToken ? decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'] : null;
  }

  public getUserNameFromDetails(): string | null {
    const decodedToken = UtilService.getDecodedToken();
    return decodedToken ? decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'] : null;
  }

  public getUserRoleFromDetails(): string | null {
    const decodedToken = UtilService.getDecodedToken();
    return decodedToken ? decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] : null;
  }

  public getUserMailFromDetails(): string | null {
    const decodedToken = UtilService.getDecodedToken();
    return decodedToken ? decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'] : null;
  }

  public static getDecodedToken() {
    try {
      const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN) as string;
      if (token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(atob(base64));
      }
    } catch (error) {
      console.error('Error decoding token:', error);
    }
    return null;
  }

  public updateSelectedGameCurrency(currencyId: string, gameCurrencies: Currency[]): Currency {
    const selectedCurrency = gameCurrencies.find(c => c.id === currencyId) || gameCurrencies[0];
    localStorage.setItem(LOCAL_STORAGE_GAME_CURRENCY, selectedCurrency.id);
    this.currencyChangeService.notifyFiat(selectedCurrency);
    return selectedCurrency;
  }

  public updateSelectedMainCurrency(currencyId: string, mainCurrencies: Currency[]): Currency {
    const selectedCurrency = mainCurrencies.find(c => c.id === currencyId) || mainCurrencies[0];
    localStorage.setItem(LOCAL_STORAGE_MAIN_CURRENCY, selectedCurrency.id);
    this.currencyChangeService.notifyMain2(selectedCurrency);
    return selectedCurrency;
  }

  public updateSelectedLanguage(selectedLanguage: LanguageModel): void {
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE, selectedLanguage.id);
    window.location.href = window.location.href.replace(/\/\w+\/#/, `/${selectedLanguage.twoLetterISOLanguageName}/#`);
  }

  public getCurrencies(): Observable<Currency[]> {
    if (this.currencyCache) {
      return of(this.currencyCache);
    } else {
      return this.httpDataService.getCurrencies().pipe(
        map((response) => {
          this.currencyCache = response.value;
          return response.value;
        }),
      );
    }
  }

  public getMainCurrencies(): Observable<Currency[]> {
    return this.getCurrencies().pipe(
      map(currencies => currencies.filter(currency => currency.chargeableCurrency || currency.cryptoCurrency))
    );
  }

  public getGameCurrencies(): Observable<Currency[]> {
    return this.getCurrencies().pipe(
      map(currencies => currencies.filter(currency => currency.gameUICurrency))
    );
  }

  public getCurrentWalletPeriodically(): Observable<WalletModel> {
    return this.httpDataService.getWalletsPeriodically().pipe(
      map((wallets: NewResponse<WalletModel[]>) => {
        return wallets.value.items.find(wallet => wallet.currencyId === this.getSelectedMainCurrency())!;
      })
    )
  }

  public getCurrentWallet(): Observable<WalletModel> {
    return zip(
      this.httpDataService.getWallets(),
      this.httpDataService.getCurrencies(),
    ).pipe(
      map(([wallets, currencies]) => {
        const wallet = wallets.value.items.find(wallet => wallet.currencyId === this.getSelectedMainCurrency())!;
        const currency = currencies.value.find(c => c.id === wallet?.currencyId);
        if (wallet && currency) {
          wallet.currency = currency;
        }
        return wallet;
      })
    )
  }

  public filterGamesByCategory(categoryName: string): GameModel[] {
    const filteredGames: GameModel[] = [];
    const categoryId = this.getCategoryIdByCategoryName(categoryName);

    if (categoryId) {
      this.allGames.forEach((games, providerId) => {
        const gamesInCategory = games.filter(game => game.categories.some(category => category === categoryId));
        filteredGames.push(...gamesInCategory.map(game => ({...game, gameProviderId: providerId})));
      });
    }

    return filteredGames;
  }

  public getCategoryIdByCategoryName(name: string): string | undefined {
    return this.allCategories.find(category => category.name === name)?.id;
  }

  public filterLikedGames(): GameModel[] {
    const filteredGames: GameModel[] = [];
    const likedGames = this.getLikedGames();
    this.allGames.forEach((games, providerId) => {
      const likedGameIdsByProvider = likedGames.filter(g => g.providerId === providerId).map(g => g.gameId);
      filteredGames.push(...games.filter(g => likedGameIdsByProvider.indexOf(g.id) !== -1).map(game => ({
        ...game,
        gameProviderId: providerId
      })));
    });
    return filteredGames;
  }

  public getGameIdByGameName(gameProviderId: string, gameName: string): string {
    const gamesForProvider = this.allGames.get(gameProviderId)!;
    const game = gamesForProvider.find(g => g.name === gameName)!;
    return game.id;
  }

  public getGameProviderIdByName(gameProviderName: string): string {
    const gameProvider = this.gameProviders.find(gameProvider => gameProvider.name === gameProviderName)!;
    return gameProvider.id;
  }

  public getProviderNameById(gameProviderId: string) {
    const gameProvider = this.gameProviders.find(provider => provider.id === gameProviderId)!;
    return gameProvider.name;
  }

  public getNonHiddenGameProviders() {
    // return this.gameProviders.filter(gameProvider => !gameProvider.hidden);
    return this.gameProviders;
  }

  public getBonusGames(): GameModel[] {
    const bonusGameProvider = this.gameProviders.find(gameProvider => gameProvider.name === 'Bonus')!;
    return this.allHiddenGames.get(bonusGameProvider.id)!;
  }

  public searchGamesByName(name: string): GameModel[] {
    const filteredGames: GameModel[] = [];
    this.allGames.forEach((games, providerId) => {
      const gamesInQuery = games.filter(game => game.displayName.toLowerCase().includes(name.toLowerCase()));
      filteredGames.push(...gamesInQuery.map(game => ({...game, gameProviderId: providerId})));
    });
    return filteredGames;
  }

  public initProfileFormGroup(profileData: ProfileModel): FormGroup {
    const birthDay = profileData.dateOfBirth as string;
    const adjustedBirthDate = this.getAdjustedBirthDate(birthDay);

    return this.fb.group({
      firstName: [profileData.firstName, [Validators.maxLength(50)]],
      lastName: [profileData.lastName, [Validators.maxLength(50)]],
      gender: [profileData.gender],
      address: [profileData.address, [Validators.maxLength(100)]],
      country: [profileData.country],
      city: [profileData.city, [Validators.maxLength(50)]],
      zipCode: [profileData.zipCode, [Validators.maxLength(10)]],
      dateOfBirth: [adjustedBirthDate],
      passportId: [profileData.passportId, [Validators.maxLength(20)]],
    });
  }

  public getGenders(): { name: string }[] {
    return GENDERS.map(gender => ({name: gender.name[this.locale]}));
  }

  public getThemes(): { theme: string, name: string }[] {
    return THEMES.map(theme => (
      {
        theme: theme.theme,
        name: theme.name[this.locale]
      }
    ))
  }

  public getTransactionTypes(): { id: number, name: string }[] {
    return TRANSACTION_TYPE.map(type => (
      {
        id: type.id,
        name: type.name[this.locale]
      }
    ))
  }

  public getCurrentOrganizationId(): string | null {
    const accountDetails = UtilService.getAccountDetails();
    return accountDetails.find(c => c.type === 'OrganizationId')?.value as string || null;
  }

  private getAdjustedBirthDate(birthDay: string): Date | null {
    if (birthDay && birthDay !== '0001-01-01T00:00:00') {
      const birthDate = new Date(birthDay);
      birthDate.setDate(birthDate.getDate() + 1);
      return birthDate;
    }
    return null;
  }

  private getLikedGames(): { providerId: string, gameId: string }[] {
    const likedGames = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)!;
      if (key.startsWith('Favorite/')) {
        const isLiked = localStorage.getItem(key) === 'true';
        if (isLiked) {
          const [, providerId, gameId] = key.split('/');
          likedGames.push({providerId, gameId});
        }
      }
    }
    return likedGames;
  }

  public static getAccountPermissions(): string[] {
    const decodedToken = UtilService.getDecodedToken();
    return decodedToken ? decodedToken['permissions'] : [];
  }

  public static getAccountDetails(): any[] {
    const detailsStr = localStorage.getItem(LOCAL_STORAGE_ACCOUNT_DETAILS) as string;
    if (detailsStr) {
      return JSON.parse(detailsStr);
    }
    return [];
  }

  public showCopied(copy: HTMLElement, copied: HTMLElement, display = 'flex') {
    copy.style.display = 'none';
    copied.style.display = display;
    setTimeout(() => {
      copy.style.display = display;
      copied.style.display = 'none';
    }, 1000);
  }

  public buildNode(value: (OrganizationModel & {balance?: number, organizationWalletId?: string,})[]): TreeNode[] {
    return value.map(o => {
      if (o.organizations?.length) {
        return {
          label: o.name,
          data: o.id,
          selectable: true,
          leaf: false,
          parentId: o.parentId,
          blocked: o.blocked,
          balance: o.balance,
          organizationWalletId: o.organizationWalletId,
          children: this.buildNode(o.organizations as (OrganizationModel & {balance: number, organizationWalletId: string,})[]),
        } as TreeNode;
      } else {
        return {
          label: o.name,
          data: o.id,
          selectable: true,
          leaf: true,
          parentId: o.parentId,
          blocked: o.blocked,
          balance: o.balance,
          organizationWalletId: o.organizationWalletId,
        } as TreeNode;
      }
    });
  }

  public findOrganizationNode(orgId: string, organizationNodes: TreeNode[]): TreeNode | null {
    if (!organizationNodes.length) {
      return null;
    }
    const result = organizationNodes.find(node => node.data === orgId);
    if (result) {
      return result;
    }
    const children: TreeNode[] = [];
    for (const node of organizationNodes) {
      if (node.children) {
        children.push(...node.children);
      }
    }
    return this.findOrganizationNode(orgId, children);
  }

  public collapseChildren(node: TreeNode) {
    if (node.children) {
      node.expanded = false;
      for (const cn of node.children) {
        this.collapseChildren(cn);
      }
    }
  }

  public getRecords(transactionTypeId: number, response: NewResponse<any[]>): TransactionRecordModel[] {
    switch (transactionTypeId) {
      case 1:
        return (response.value.items.map(transaction => ({
          transactionId: transaction.id,
          transactionType: transaction.transactionType === 0 ? 'Outgoing' : 'Incoming',
          balance: transaction.balance,
          amount: transaction.amount,
          dateCreated: new Date(transaction.created),
        })));
      case 2:
        return (response.value.items.map(transaction => ({
          transactionId: transaction.id,
          transactionType: transaction.transactionType === 0 ? 'Outgoing' : 'Incoming',
          balance: transaction.baseTransaction.balance,
          amount: transaction.baseTransaction.amount,
          dateCreated: new Date(transaction.created),
          transaction: transaction.baseTransaction.id,
        })));
      case 3:
        return (response.value.items.map(transaction => ({
          transactionId: transaction.id,
          fromUserName: transaction.fromTransaction.wallet.user.firstName + ' ' + transaction.fromTransaction.wallet.user.lastName,
          toUserName: transaction.toTransaction.wallet.user.firstName + ' ' + transaction.toTransaction.wallet.user.lastName,
          amount: transaction.fromTransaction.amount,
          dateCreated: new Date(transaction.created),
        })));
      case 4:
        return (response.value.items.map(transaction => ({
          transactionId: transaction.id,
          gameName: transaction.gameSessionId.gameId,
          balance: transaction.baseTransaction.balance,
          amount: transaction.baseTransaction.amount,
          dateCreated: new Date(transaction.created),
        })));
      case 5:
        return (response.value.items.map(transaction => ({
          transactionId: transaction.id,
          transactionType: transaction.transactionType === 0 ? 'Outgoing' : 'Incoming',
          userName: transaction.referralTransaction.wallet.user.userName,
          balance: transaction.baseTransaction.balance,
          amount: transaction.baseTransaction.amount,
          dateCreated: new Date(transaction.created),
        })));
      default:
        throw (new Error('Transaction type is required.'));
    }
  }

  public buildOrganizationTree(hierarchicalIds: OrganizationHierarchicalIdsModel[], parentId: string, node?: any,):
    Observable<{childOrgs?: OrganizationModel[], organizationNodes?: TreeNode[]}> {
    if (node && hierarchicalIds.length) {
      node.leaf = false;
    }
    const observers = hierarchicalIds.map(org => zip(
      this.httpDataService.getOrganization(org.childId),
      this.httpDataService.getOrganizationWalletByCurrency(org.childId, localStorage.getItem(LOCAL_STORAGE_GAME_CURRENCY)!),
    ));
    observers.push(zip(
      this.httpDataService.getOrganization(parentId),
      this.httpDataService.getOrganizationWalletByCurrency(parentId, localStorage.getItem(LOCAL_STORAGE_GAME_CURRENCY)!),
    ));
    return zip(observers).pipe( map((organizationsResponses) => {
      let parentOrg!: OrganizationModel;
      const childOrgs: OrganizationModel[] = [];
      organizationsResponses.forEach(([orgResponse, walletResponse]) => {
        (orgResponse.value as any).balance = (walletResponse.value as any).balance;
        (orgResponse.value as any).organizationWalletId = (walletResponse.value as any).userWalletId;
        if (orgResponse.value.id === parentId) {
          parentOrg = orgResponse.value;
        } else {
          childOrgs.push(orgResponse.value);
        }
      })
      parentOrg.organizations = childOrgs;

      if (!node) {
        return {
          organizationNodes: this.buildNode([parentOrg]),
        };
      } else {
        return {
          childOrgs,
        };
      }
    }));
  }

  public deepClone(obj: any, cloned = new WeakMap()): any {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (cloned.has(obj)) {
      return cloned.get(obj);
    }

    const clonedObj = Array.isArray(obj) ? [] : Object.create(Object.getPrototypeOf(obj));

    cloned.set(obj, clonedObj);

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        clonedObj[key] = this.deepClone(obj[key], cloned);
      }
    }

    return clonedObj;
  }

}
