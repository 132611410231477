<main class="game-card-main" [ngClass]="{'mobile-view' : isMobilView, 'desktop-view' : !isMobilView}">
  <div class="card" (click)="showButtonsOnTap()">
    <img [src]="src" [alt]="game?.name">
    <div class="card-content">
      <a class="buttons" style="position: relative; top: 0; left: 0;" *ngIf="show">
        <div class="like-button" [ngClass]="{ 'liked': isLiked, }" (click)="updateLikedGames()" i18n="@@LikeButton">
          <i style="font-size: 20px" class="pi" [ngClass]="{ 'pi-star-fill': isLiked, 'pi-star': !isLiked }"></i>
        </div>
        <div class="play__btns">

          <button
            class="play-for-real-btn"
            (click)="openGame(false)"
            i18n="@@PlayForReal">Play for real
          </button>

          <button
            class="play-for-free-btn"
            (click)="openGame(true)"
            i18n="@@PlayForFree">Play for free
          </button>
        </div>
      </a>
    </div>
  </div>
</main>
