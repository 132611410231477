import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {LoginService} from '../../services/login.service';
import {SUCCESS_RESET_PASSWORD} from '../../../../locale/multilingual-strings-constants';
import {NavigationService} from '../../services/navigation.service';
import {NotifierService} from '../../services/notifier.service';
import {HttpIdentityService} from '../../../../../libs/submodules/identity/src/lib/identity-client.service';
import {ForgotPasswordModel} from '../../../../../libs/submodules/identity/src/lib/models/forgot-password.model';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../../../../auth-component-styles.scss'],
  animations: [
    trigger('forgotPasswordSlideInAnimation', [
      state('in', style({transform: 'translateX(0)'})),
      transition(':enter', [style({transform: 'translateX(100%)'}), animate('500ms ease')]),
      transition(':leave', animate('500ms ease', style({transform: 'translateX(100%)'}))),
    ]),
  ],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public forgotPasswordForm: FormGroup;
  public isForgotPasswordClosed = false

  constructor(
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private httpIdentityService: HttpIdentityService,
    private notifierService: NotifierService,
    private navigationService: NavigationService,
    private elementRef: ElementRef,
  ) {
  }

  public ngOnInit() {
    this.createForgotPasswordForm();
    this.loginService.showLogin()
    setTimeout(() => document.addEventListener('click', this.clickOutsideComponent));
  }

  public ngOnDestroy(): void {
    document.removeEventListener('click', this.clickOutsideComponent);
  }

  public isEmailInvalid() {
    const emailControl = this.forgotPasswordForm.get('identifier');
    return emailControl?.invalid && emailControl?.touched;
  }

  public onSubmit() {
    this.httpIdentityService.forgotPassword(<ForgotPasswordModel>this.forgotPasswordForm.value).subscribe({
      next: response => {
        if (response.succeed) {
          this.notifierService.handleSuccessRequest(SUCCESS_RESET_PASSWORD);
          this.loginService.hideLogin()
          this.navigationService.closeRegisterPage();
        } else {
          this.notifierService.handleErrors(response.errors);
        }
      },
      error: error => {
        this.forgotPasswordForm.reset();
        this.notifierService.handleRequestError(error);
      }
    });
  }

  public closePage() {
    this.loginService.hideLogin()
    this.isForgotPasswordClosed = true;
    this.navigationService.closeAllPages();
  }

  private clickOutsideComponent = (event: MouseEvent) => {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closePage();
    }
  };

  private createForgotPasswordForm() {
    this.forgotPasswordForm = this.formBuilder.group({
      identifier: ['', [Validators.required, Validators.email]],
    });
  }

}
