import {Injectable} from '@angular/core';
import {HttpClient,} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrencyConvertService {

  // private apiUrl = '/gh/fawazahmed0/currency-api@1/latest/currencies';
  private apiUrl = 'https://min-api.cryptocompare.com/data';

  constructor(private http: HttpClient) {
  }

  public getCurrencyData(fiatCode: string, currencyCode: string): Observable<any> {
    const url = `${this.apiUrl}/pricemulti?fsyms=${currencyCode.toUpperCase()}&tsyms=${fiatCode.toUpperCase()}`;
    return this.http.get(url).pipe(
      map(res => {
        if (((res as any).value as any)['Response'] === 'Error') {
          (res as any).value = {
            [currencyCode.toUpperCase()]: {
              [fiatCode.toUpperCase()]: 0.404,
            }
          };
        }
        return res;
      }),
    );
  }

  public convertFromCrypto(amount: number, rate: number): number {
    return (amount || 0) * rate;
  }

  public convertFromFiat(amount: number, rate: number): number {
    return (amount || 0) / rate;
  }
}
