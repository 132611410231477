import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {Currency} from '../model/account/currency.model';

@Injectable()
export class CurrencyChangeService {
  public isFiatCurrencyChange$ = new ReplaySubject<Currency>(1);
  public isMainCurrencyChange$ = new ReplaySubject<Currency>(1);

  public notifyFiat(fiatCurrency: Currency): void {
    this.isFiatCurrencyChange$.next(fiatCurrency);
  }

  public notifyMain2(mainCurrency: Currency): void {
    this.isMainCurrencyChange$.next(mainCurrency);
  }
}
