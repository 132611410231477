import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {interval, Observable} from 'rxjs';
import {first, map, switchMap} from 'rxjs/operators';
import {Response} from '../../model/response.model';
import {GameProviderModel} from '../../model/game/game-provider.model';
import {GameModel} from '../../model/game/game.model';
import {DemoGamePostModel} from '../../model/game/demo-game-post.model';
import {RealGamePostModel} from '../../model/game/real-game-post.model';
import {ReferralRecordModel} from '../../model/referral-system/referral-record.model';
import {Currency} from '../../model/account/currency.model';
import {BaseTransactionModel} from '../../model/transaction/base-transaction.model';
import {CasinoTransactionModel} from '../../model/transaction/casino-transaction.model';
import {TransferTransactionModel} from '../../model/transaction/transfer-transaction.model';
import {CryptoTransactionModel} from '../../model/transaction/crypto-transaction.model';
import {ReferralSystemTransactionModel} from '../../model/transaction/referral-system-transaction.model';
import {WalletModel} from '../../model/wallet/user-wallet/wallet.model';
import {LanguageModel} from '../../model/cultures/language.model';
import {DepositModel} from '../../model/wallet/user-wallet/deposit.model';
import {GameCategoryModel} from '../../model/game/game-category.model';
import {HostedServiceModel} from '../../model/hosted-services/hosted-service.model';
import {JobModel} from '../../model/hosted-services/job.model';
import {API_URL, FETCH_INTERVAL, MAX_PAGING_LIMIT, SUPPORTED_LANGUAGES} from '../../constants';
import {CreateGameModel} from '../../model/game/create-game.model';
import {OrganizationModel} from '../../model/user/organization.model';
import {WalletWithdrawModel} from '../../model/wallet/user-wallet/wallet-withdraw.model';
import {OrganizationSettingsModel} from '../../model/user/organization-settings.model';
import {NewResponse} from '../../model/response';
import {OrganizationHierarchicalIdsModel} from '../../model/user/organization-hierarchical-ids.model';
import {OrganizationSettingsListModel} from '../../model/user/organization-settings-list.model';
import {BonusModel} from '../../model/bonuses/bonus.model';
import {MessageModel} from '../../model/mailbox/message.model';
import {OrganizationRoleModel} from '../../model/user/organization-role.model';
import {OrganizationCurrencyModel} from '../../model/user/organization-currency.model';
import {UserMailboxesModel} from '../../model/mailbox/user-mailboxes.model';
import {MailboxModel} from '../../model/mailbox/mailbox.model';
import {RefundModel} from '../../model/wallet/user-wallet/refund.model';
import {OrganizationTransactionModel} from '../../model/wallet/organization-wallet/organization-transaction.model';
import {
  OrganizationTransactionTransferModel
} from '../../model/wallet/organization-wallet/organization-transaction-transfer.model';
import {OrganizationWalletModel} from '../../model/wallet/organization-wallet/organization-wallet.model';
import {
  WalletRefundUserToOrgModel,
  WalletTransferModel,
  WalletTransferOrgToOrgModel,
  WalletTransferOrgToUserModel,
  WalletTransferUserToOrgModel
} from '../../model/wallet/wallet-transfer.model';
import {UtilService} from '../util.service';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {throwError} from 'rxjs';

@Injectable()
export class HttpDataService {
  private static readonly urlCasino = `${API_URL}/casino/`;
  private static readonly urlHostedServices = `${API_URL}/hostedServices/`;
  private static readonly urlMailboxes = `${API_URL}/messagingSystem/mailboxes`;
  private static readonly urlMessageRecipients = `${API_URL}/messagingSystem/messageRecipients`;
  private static readonly urlMessages = `${API_URL}/messagingSystem/messages`;
  private static readonly urlUserWallets = `${API_URL}/userWallets`;
  private static readonly urlUserTransactions = `${API_URL}/userWalletTransactions`;
  private static readonly urlOrganizationWallets = `${API_URL}/organizationWallets`;
  private static readonly urlOrganizationWalletTransactions = `${API_URL}/OrganizationWalletTransactions/`;
  private static readonly urlOrganizations = `${API_URL}/organization/organizations`;
  private static readonly urlOrganizationRoles = `${API_URL}/organization/organizationRoles`;
  private static readonly urlOrganizationCurrencies = `${API_URL}/organizationCurrencies`;
  private static readonly urlOrganizationSettings = `${API_URL}/organization/settings`;
  private static readonly urlOrganizationSettings2 = `${API_URL}/organizationSettings`;
  private static readonly urlCasinoGameProvidersManage = `${API_URL}/Casino/GameProviders/Manage/`;
  private static readonly urlCasinoGamesManage = `${API_URL}/Casino/Games/Manage/`;
  private static readonly urlOrganizationUserWallets = `${API_URL}/OrganizationUserWallets/`;
  private static readonly urlOrganizationUserWalletTransactions = `${API_URL}/OrganizationUserWalletTransactions/`;
  private static readonly urlOrganizationUsers = `${API_URL}/organization/organizationUsers`;
  private static readonly urlWagerBonuses = `${API_URL}/casino/bonuses/wager`;

  private readonly headersSkippingInterceptor: HttpHeaders;
  private readonly headers: HttpHeaders;
  private readonly optionsSkippingInterceptor: object;
  private readonly options: object;

  public constructor(private http: HttpClient) {
    this.headersSkippingInterceptor = new HttpHeaders({
      'Content-Type': 'application/json',
      'skipInterceptor': 'true',
    });
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.optionsSkippingInterceptor = {
      headers: this.headersSkippingInterceptor,
      withCredentials: true
    };
    this.options = {
      headers: this.headers,
      withCredentials: true
    };
  }

  // Organization users
  public getCurrentOrganizationUsers(
    page: number,
    size: number,
    filter?: {
      [s: string]: FilterMetadata | FilterMetadata[] | undefined;
    },
  ): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${HttpDataService.urlOrganizationUsers}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getAllUsersByOrganizationId(
    organizationId: string,
    page: number,
    size: number,
    filter?: {
      [s: string]: FilterMetadata | FilterMetadata[] | undefined;
    },
  ): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${HttpDataService.urlOrganizationUsers}/${organizationId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteUserFromOrganization(userId: string, organizationId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${HttpDataService.urlOrganizationUsers}/${organizationId}/${userId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public createOrganizationUser(userId: string, organizationId: string): Observable<Response<null>> {
    return this.http.post<Response<null>>(
      `${HttpDataService.urlOrganizationUsers}/${organizationId}`,
      {
        userId,
      },
      this.optionsSkippingInterceptor
    ).pipe(first());
  }

  public updateOrganizationUser(userId: string, organizationId: string, isDeleted: boolean): Observable<Response<null>> { // todo removed in swagger
    return this.http.put<Response<null>>(`${HttpDataService.urlOrganizationUsers}/${organizationId}/${userId}`, {isDeleted}, this.optionsSkippingInterceptor).pipe(first());
  }

  // Organizations
  public getOrganization(organizationId: string): Observable<Response<OrganizationModel>> {
    return this.http.get<Response<OrganizationModel>>(`${HttpDataService.urlOrganizations}/${organizationId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addOrganization(organizationId: string, body: OrganizationModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${HttpDataService.urlOrganizations}/${organizationId}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateOrganization(organizationId: string, body: OrganizationModel): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${HttpDataService.urlOrganizations}/${organizationId}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteOrganization(organizationId: string): Observable<Response<null>> { // todo
    return this.http.delete<Response<null>>(`${HttpDataService.urlOrganizations}/${organizationId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getOrganizationsByParent(organizationId: string, page: number, size: number,): Observable<NewResponse<string[]>> { // todo
    return this.http.get<NewResponse<string[]>>(`${HttpDataService.urlOrganizations}/${organizationId}/getChildren?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // OrganizationRoles
  public getCurrentOrganizationRoles(page: number, size: number,): Observable<NewResponse<{ roleId: string } []>> {
    return this.http.get<NewResponse<{ roleId: string }[]>>(`${HttpDataService.urlOrganizationRoles}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getOrganizationRoles(organizationId: string, page: number, size: number,): Observable<NewResponse<{ roleId: string } []>> {
    return this.http.get<NewResponse<{ roleId: string }[]>>(`${HttpDataService.urlOrganizationRoles}/${organizationId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addOrganizationRole(organizationId: string, body: OrganizationRoleModel): Observable<NewResponse<null>> {
    return this.http.post<NewResponse<null>>(`${HttpDataService.urlOrganizationRoles}/${organizationId}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteOrganizationRole(organizationId: string, roleId: string): Observable<NewResponse<null>> {
    return this.http.delete<NewResponse<null>>(`${HttpDataService.urlOrganizationRoles}/${organizationId}/${roleId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // OrganizationCurrencies
  public getOrganizationCurrencies(organizationId: string): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${HttpDataService.urlOrganizationCurrencies}/${organizationId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public addOrganizationCurrency(body: OrganizationCurrencyModel): Observable<NewResponse<null>> {
    return this.http.post<NewResponse<null>>(`${HttpDataService.urlOrganizationCurrencies}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteOrganizationCurrency(organizationId: string, currencyId: string): Observable<NewResponse<null>> {
    return this.http.delete<NewResponse<null>>(`${HttpDataService.urlOrganizationCurrencies}/${organizationId}?currencyId=${currencyId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Organization Hierarchies
  public getOrganizationHierarchies(parentId?: string, pageIndex = 1, pageSize = MAX_PAGING_LIMIT): Observable<NewResponse<OrganizationHierarchicalIdsModel[]>> { // todo delete
    return this.http.get<NewResponse<string[]>>(`${HttpDataService.urlOrganizations}?pageIndex=${pageIndex}&pageSize=${pageSize}${parentId ? '&parentId=' + parentId : ''}`, this.optionsSkippingInterceptor).pipe(
      first(),
      map((res: NewResponse<string[]>) => {
        return {
          ...res,
          value: {
            ...res.value,
            items: res.value.items.map(childId => ({
              childId,
              parentId,
            })),
          }
        } as unknown as NewResponse<OrganizationHierarchicalIdsModel[]>;
      }),
    );
  }

  public deleteOrganizationHierarchy(organizationId: string): Observable<Response<null>> { // todo delete
    return this.http.delete<Response<null>>(`${HttpDataService.urlOrganizations}/${organizationId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Organization Settings
  public createOrganizationSettings(body: OrganizationSettingsModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${HttpDataService.urlOrganizationSettings}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateOrganizationSetting(settingsId: string, body: OrganizationSettingsModel): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${HttpDataService.urlOrganizationSettings}/${settingsId}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public getSettingsBySettingsId(settingId: string): Observable<Response<OrganizationSettingsModel>> {
    return this.http.get<Response<OrganizationSettingsModel>>(`${HttpDataService.urlOrganizationSettings}/${settingId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getSettingsByOrganizationId(organizationId?: string): Observable<NewResponse<OrganizationSettingsModel[]>> {
    if (organizationId) {
      return this.http.get<NewResponse<OrganizationSettingsModel[]>>(`${HttpDataService.urlOrganizationSettings}?organizationId=${organizationId}`, this.optionsSkippingInterceptor).pipe(first());
    }
    return this.http.get<NewResponse<OrganizationSettingsModel[]>>(`${HttpDataService.urlOrganizationSettings}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // OrganizationSettings
  public getOrganizationSavedSetting(organizationId: string): Observable<Response<OrganizationSettingsListModel>> {
    return this.http.get<Response<OrganizationSettingsListModel>>(`${HttpDataService.urlOrganizationSettings2}/${organizationId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public saveOrganizationSetting(body: { organizationId: string, settingId: string }): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${HttpDataService.urlOrganizationSettings2}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateSelectedSetting(body: { organizationId: string, settingId: string }): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${HttpDataService.urlOrganizationSettings2}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteOrganizationSetting(organizationId: string, settingsId: string): Observable<Response<string>> {
    return this.http.delete<Response<string>>(`${HttpDataService.urlOrganizationSettings2}/${organizationId}/${settingsId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // UserWallets
  public getWallets(userId?: string, organizationId?: string,): Observable<NewResponse<WalletModel[]>> {
    if (!userId || !organizationId) {
      const accountDetails = UtilService.getAccountDetails();
      const decodedToken = UtilService.getDecodedToken()
      if (!userId) {
        userId = decodedToken ? decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'] : null;
      }
      if (!organizationId) {
        organizationId = accountDetails.find(c => c.type === 'OrganizationId')?.value as string || undefined;
      }
    }
    if (!organizationId) {
      return throwError('Organization ID is missing.'); // Or return an Observable that completes immediately
    }
    return this.http.get<NewResponse<WalletModel[]>>(`${HttpDataService.urlUserWallets}?userId=${userId}${organizationId ? '&organizationId=' + organizationId : ''}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getWalletsPeriodically(userId?: string, organizationId?: string,): Observable<NewResponse<WalletModel[]>> {
    if (!userId || !organizationId) {
      const accountDetails = UtilService.getAccountDetails();
      const decodedToken = UtilService.getDecodedToken()
      if (!userId) {
        userId = decodedToken ? decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'] : null;
      }
      if (!organizationId) {
        organizationId = accountDetails.find(c => c.type === 'OrganizationId')?.value as string || undefined;
      }
    }
    return interval(FETCH_INTERVAL).pipe(
      switchMap(() => this.http.get<NewResponse<WalletModel[]>>(`${HttpDataService.urlUserWallets}?userId=${userId}${organizationId ? '&organizationId=' + organizationId : ''}`, this.optionsSkippingInterceptor)),
    );
  }

  public postWalletRefund(body: RefundModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${HttpDataService.urlUserWallets}/refund`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public postWalletWithdraw(body: WalletWithdrawModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${HttpDataService.urlUserWallets}/withdraw`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public postWalletTransfer(body: WalletTransferModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${HttpDataService.urlUserWallets}/transfer`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public getWalletByUser(userId: string, currencyId: string): Observable<Response<WalletModel>> {
    return this.http.get<Response<WalletModel>>(`${HttpDataService.urlUserWallets}/${userId}/${currencyId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getWalletByUserAndOrganization(userId: string, organizationId: string, currencyId: string): Observable<Response<WalletModel>> {
    return this.http.get<Response<WalletModel>>(`${HttpDataService.urlUserWallets}/${userId}/${currencyId}${organizationId ? '/' + organizationId : ''}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getWalletDeposit(walletId: string): Observable<Response<DepositModel>> {
    return this.http.get<Response<DepositModel>>(`${HttpDataService.urlUserWallets}/deposit/${walletId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getWalletPendingBalance(walletId: string): Observable<Response<number>> {
    return this.http.get<Response<number>>(`${HttpDataService.urlUserWallets}/pendingBalance/${walletId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // UserTransactions
  public getUserTransactionsBetween(
    currencyId: string,
    fromDateTime: string,
    toDateTime: string,
    pageIndex: number,
    pageSize: number,
  ): Observable<NewResponse<BaseTransactionModel[]>> {
    return this.http.get<NewResponse<BaseTransactionModel[]>>(
      `${HttpDataService.urlUserTransactions}?currencyId=${currencyId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
      this.optionsSkippingInterceptor
    ).pipe(first());
  }

  public getUserTransactionByTransactionId(
    userWalletTransactionId : string,
  ): Observable<NewResponse<BaseTransactionModel[]>> {
    return this.http.get<NewResponse<BaseTransactionModel[]>>(
      `${HttpDataService.urlUserTransactions}${userWalletTransactionId}`,
      this.optionsSkippingInterceptor
    ).pipe(first());
  }

  public getTransactionsCasinoBetween(
    currencyId: string,
    fromDateTime: string,
    toDateTime: string,
    pageIndex: number,
    pageSize: number,
  ): Observable<NewResponse<CasinoTransactionModel[]>> {
    return this.http.get<NewResponse<CasinoTransactionModel[]>>(
      `${HttpDataService.urlUserTransactions}/casino?currencyId=${currencyId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
      this.optionsSkippingInterceptor
    ).pipe(first());
  }

  public getTransactionsTransfer(
    currencyId: string,
    fromDateTime: string,
    toDateTime: string,
    pageIndex: number,
    pageSize: number,
  ): Observable<NewResponse<TransferTransactionModel[]>> {
    return this.http.get<NewResponse<TransferTransactionModel[]>>(
      `${HttpDataService.urlUserTransactions}/transfer?currencyId=${currencyId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
      this.optionsSkippingInterceptor
    ).pipe(first());
  }

  public getTransactionsCrypto(
    currencyId: string,
    fromDateTime: string,
    toDateTime: string,
    pageIndex: number,
    pageSize: number,
  ): Observable<NewResponse<CryptoTransactionModel[]>> {
    return this.http.get<NewResponse<CryptoTransactionModel[]>>(
      `${HttpDataService.urlUserTransactions}/crypto?currencyId=${currencyId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
      this.optionsSkippingInterceptor
    ).pipe(first());
  }

  public getTransactionsReferralSystem(
    currencyId: string,
    fromDateTime: string,
    toDateTime: string,
    pageIndex: number,
    pageSize: number,
  ): Observable<NewResponse<ReferralSystemTransactionModel[]>> {
    return this.http.get<NewResponse<ReferralSystemTransactionModel[]>>(
      `${HttpDataService.urlUserTransactions}/referralSystem?currencyId=${currencyId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
      this.optionsSkippingInterceptor
    ).pipe(first());
  }

  // OrganizationUsersWallets
  public transferFromOrganizationWalletToUserWallet(body: WalletTransferOrgToUserModel): Observable<Response<any>> {
    return this.http.post<Response<any>>(`${HttpDataService.urlOrganizationUserWallets}/transferFromOrganizationWalletToUserWallet`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public transferFromUserWalletToOrganizationWallet(body: WalletTransferUserToOrgModel): Observable<Response<any>> {
    return this.http.post<Response<any>>(`${HttpDataService.urlOrganizationUserWallets}depositToOrganizationWallet`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public refundFromUserWalletToOrganizationWallet(body: WalletRefundUserToOrgModel): Observable<Response<any>> {
    return this.http.post<Response<any>>(`${HttpDataService.urlOrganizationUserWallets}/refundFromUserWalletToOrganizationWallet`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  // OrganizationUsersWalletTransactions
  public getOrganizationUserWalletTransactionsByOrganization(
    organizationId: string,
    currencyId: string,
    fromDateTime?: string,
    toDateTime?: string,
    pageIndex?: number,
    pageSize?: number,
  ): Observable<NewResponse<OrganizationTransactionModel[]>> {
    let url = `${HttpDataService.urlOrganizationUserWalletTransactions}by-organization/${organizationId}/${currencyId}?`;
    if (fromDateTime !== undefined) {
      url += `fromDateTime=${fromDateTime}&`;
    }
    if (toDateTime !== undefined) {
      url += `toDateTime=${toDateTime}&`;
    }
    if (pageIndex !== undefined) {
      url += `pageIndex=${pageIndex}&`;
    }
    if (pageSize !== undefined) {
      url += `pageSize=${pageSize}&`;
    }
    url = url.replace(/&$/, '');

    return this.http.get<NewResponse<OrganizationTransactionModel[]>>(url, this.optionsSkippingInterceptor)
      .pipe(
        first()
      );
  }

  public getOrganizationUserWalletTransactionsByUser(
    userId: string,
    currencyId: string,
    fromDateTime?: string,
    toDateTime?: string,
    pageIndex?: number,
    pageSize?: number,
  ): Observable<NewResponse<OrganizationTransactionModel[]>> {
    let url = `${HttpDataService.urlOrganizationUserWalletTransactions}by-user/${userId}/${currencyId}?`;
    if (fromDateTime !== undefined) {
      url += `fromDateTime=${fromDateTime}&`;
    }
    if (toDateTime !== undefined) {
      url += `toDateTime=${toDateTime}&`;
    }
    if (pageIndex !== undefined) {
      url += `pageIndex=${pageIndex}&`;
    }
    if (pageSize !== undefined) {
      url += `pageSize=${pageSize}&`;
    }
    url = url.replace(/&$/, '');

    return this.http.get<NewResponse<OrganizationTransactionModel[]>>(url, this.optionsSkippingInterceptor)
      .pipe(
        first()
      );
  }

  // OrganizationWallets
  public getOrganizationWalletByCurrency(organizationId: string, currencyId: string): Observable<Response<OrganizationWalletModel>> {
    return this.http.get<Response<OrganizationWalletModel>>(`${HttpDataService.urlOrganizationWallets}/${organizationId}/${currencyId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getOrganizationWallets(organizationId: string): Observable<Response<OrganizationWalletModel[]>> {
    return this.http.get<Response<OrganizationWalletModel[]>>(`${HttpDataService.urlOrganizationWallets}/${organizationId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public postOrganizationWalletTransfer(body: WalletTransferOrgToOrgModel): Observable<Response<null>> {
    return this.http.post<Response<null>>(`${HttpDataService.urlOrganizationWallets}/transfer`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  // OrganizationTransactions
  public getOrganizationTransactions(
    organizationId: string,
    currencyId: string,
    pageIndex: number,
    pageSize: number,
    fromDateTime?: string,
    toDateTime?: string,
  ): Observable<NewResponse<OrganizationTransactionModel[]>> {
    let url = `${HttpDataService.urlOrganizationWalletTransactions}${organizationId}/${currencyId}?`;

    if (fromDateTime !== undefined && fromDateTime !== null && fromDateTime.trim() !== '') {
      url += `fromDateTime=${fromDateTime}&`;
    }

    if (toDateTime !== undefined && toDateTime !== null && toDateTime.trim() !== '') {
      url += `toDateTime=${toDateTime}&`;
    }

    url += `pageIndex=${pageIndex}&pageSize=${pageSize}`;

    return this.http.get<NewResponse<OrganizationTransactionModel[]>>(
      url,
      this.optionsSkippingInterceptor
    ).pipe(first());
  }

  public getOrganizationTransactionByTransactionId(
    transactionId: string,
  ): Observable<NewResponse<OrganizationTransactionModel[]>> {
    return this.http.get<NewResponse<OrganizationTransactionModel[]>>(
      `${HttpDataService.urlOrganizationWalletTransactions}${transactionId}`,
      this.optionsSkippingInterceptor
    ).pipe(first());
  }

  public getOrganizationTransferTransactions(
    currencyId: string,
    organizationId: string,
    fromDateTime: string,
    toDateTime: string,
    pageIndex: number,
    pageSize: number,
  ): Observable<NewResponse<OrganizationTransactionTransferModel[]>> {
    return this.http.get<NewResponse<OrganizationTransactionTransferModel[]>>(
      `${HttpDataService.urlOrganizationWalletTransactions}transfer/${organizationId}/${currencyId}?fromDateTime=${fromDateTime}&toDateTime=${toDateTime}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
      this.optionsSkippingInterceptor
    ).pipe(first());
  }

  // Cultures
  public getCultures(): Observable<Response<LanguageModel[]>> {
    const supportedLanguages: string[] = SUPPORTED_LANGUAGES;
    return this.http.get<Response<LanguageModel[]>>(`${API_URL}/Cultures`, this.optionsSkippingInterceptor).pipe(
      map(response => {
        if (response.succeed) {
          response.value = response.value.filter(culture => supportedLanguages.includes(culture.name));
        }
        return response;
      }),
      first()
    );
  }

  // Currencies
  public getCurrencies(): Observable<Response<Currency[]>> {
    return this.http.get<Response<Currency[]>>(`${API_URL}/Currencies`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getCurrencyById(currencyId: string): Observable<Response<Currency>> {
    return this.http.get<Response<Currency>>(`${API_URL}/Currencies/${currencyId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Casino
  public getAllGames(): Observable<Response<GameModel[]>> {
    return this.http.get<Response<GameModel[]>>(`${HttpDataService.urlCasino}games`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getCasinoTransactions(currencyId: string, count: number): Observable<Response<any>> { // todo
    return this.http.get<Response<any>>(`${HttpDataService.urlCasino}Transactions/win/${currencyId}/${count}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getCasinoPlayedGame(id: string, gameName: string, fromDate: string, toDate: string): Observable<Response<any>> { // todo
    return this.http.get<Response<any>>(`${HttpDataService.urlCasino}playedGame/${id}/${gameName}/${fromDate}/${toDate}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getAllCategories(): Observable<Response<GameCategoryModel[]>> {
    return this.http.get<Response<GameCategoryModel[]>>(`${HttpDataService.urlCasino}Categories`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getGameProviders(): Observable<Response<GameProviderModel[]>> { // todo
    return this.http.get<Response<GameProviderModel[]>>(`${HttpDataService.urlCasino}GameProviders`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getAllGamesByGameProvider(gameProviderId: string): Observable<Response<GameModel[]>> { // todo
    return this.http.get<Response<GameModel[]>>(`${HttpDataService.urlCasino}games/${gameProviderId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getCasinoLiveFeed(currencyId: string, count: number): Observable<Response<any>> {
    return this.http.get<Response<any>>(`${HttpDataService.urlCasino}feed/live/${currencyId}/${count}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getCasinoDailyFeed(currencyId: string, count: number): Observable<Response<any>> {
    return this.http.get<Response<any>>(`${HttpDataService.urlCasino}feed/daily/${currencyId}/${count}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getCasinoWeeklyFeed(currencyId: string, count: number): Observable<Response<any>> {
    return this.http.get<Response<any>>(`${HttpDataService.urlCasino}feed/weekly/${currencyId}/${count}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getCasinoFreeSpins(currencyId: string): Observable<Response<any>> {
    return this.http.get<Response<any>>(`${HttpDataService.urlCasino}/freeSpins/${currencyId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getCasinoGameSessionBalance(gameSessionId: string): Observable<Response<any>> {
    return this.http.get<Response<any>>(`${HttpDataService.urlCasino}gameSessionCallback/balance/${gameSessionId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getCasinoGameSessionBalance2(gameSessionId: string, decimalString: string): Observable<Response<any>> {
    return this.http.get<Response<any>>(`${HttpDataService.urlCasino}gameSessionCallback/balance/${gameSessionId}/${decimalString}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getCasinoGameSessionWallet(walletId: string, count: number): Observable<Response<any>> {
    return this.http.get<Response<any>>(`${HttpDataService.urlCasino}gameSession/${walletId}/${count}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public gameSessionReal(body: RealGamePostModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${HttpDataService.urlCasino}gameSession/real`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public gameSessionDemo(body: DemoGamePostModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${HttpDataService.urlCasino}gameSession/demo`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  // Bonuses
  public getUserWagerBonuses(userId: string, pageIndex: number, pageSize: number): Observable<NewResponse<BonusModel[]>> {
    return this.http.get<NewResponse<BonusModel[]>>(`${HttpDataService.urlWagerBonuses}/${userId}?pageIndex=${pageIndex}&pageSize=${pageSize}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getWagerBonuses(pageIndex: number, pageSize: number): Observable<NewResponse<BonusModel[]>> {
    return this.http.get<NewResponse<BonusModel[]>>(`${HttpDataService.urlWagerBonuses}?pageIndex=${pageIndex}&pageSize=${pageSize}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public createBonus(body: BonusModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${HttpDataService.urlWagerBonuses}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteBonus(bonusId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${HttpDataService.urlWagerBonuses}/${bonusId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // HostedServices
  public getHostedServices(): Observable<Response<HostedServiceModel[]>> {
    return this.http.get<Response<HostedServiceModel[]>>(`${HttpDataService.urlHostedServices}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getHostedService(serviceName: string): Observable<Response<HostedServiceModel>> {
    return this.http.get<Response<HostedServiceModel>>(`${HttpDataService.urlHostedServices}${serviceName}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public resetHostedServiceJobStatus(serviceName: string, id: string): Observable<Response<HostedServiceModel>> {
    return this.http.post<Response<HostedServiceModel>>(`${HttpDataService.urlHostedServices}${serviceName}/${id}`, {}, this.optionsSkippingInterceptor).pipe(first());
  }

  public getHostedServiceJobs(serviceName: string): Observable<Response<JobModel[]>> {
    return this.http.get<Response<JobModel[]>>(`${HttpDataService.urlHostedServices}${serviceName}/jobs`, this.optionsSkippingInterceptor).pipe(first());
  }

  public startHostedService(serviceName: string): Observable<Response<null>> {
    return this.http.get<Response<null>>(`${HttpDataService.urlHostedServices}${serviceName}/start`, this.optionsSkippingInterceptor).pipe(first());
  }

  public stopHostedService(serviceName: string): Observable<Response<null>> {
    return this.http.get<Response<null>>(`${HttpDataService.urlHostedServices}${serviceName}/stop`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Mailbox
  public getMailboxes(): Observable<Response<UserMailboxesModel[]>> {
    return this.http.get<Response<UserMailboxesModel[]>>(`${HttpDataService.urlMailboxes}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getMailboxesByUserId(userId: string): Observable<NewResponse<UserMailboxesModel[]>> {
    return this.http.get<NewResponse<UserMailboxesModel[]>>(`${HttpDataService.urlMailboxes}/${userId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Message Recipients
  public getMailboxByMailboxId(mailboxId: string, pageIndex: number, pageSize: number): Observable<NewResponse<MailboxModel[]>> {
    return this.http.get<NewResponse<MailboxModel[]>>(`${HttpDataService.urlMessageRecipients}?mailboxId=${mailboxId}&pageIndex=${pageIndex}&pageSize=${pageSize}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public createMessageRecipient(body: { mailboxId: string, messageId: string }): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${HttpDataService.urlMessageRecipients}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public markMessageRecipientAsRead(body: { mailboxId: string, messageId: string }): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${HttpDataService.urlMessageRecipients}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteMessageRecipient(mailboxId: string, messageId: string, userId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${HttpDataService.urlMessageRecipients}?mailboxId=${mailboxId}&messageId=${messageId}&userId=${userId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Messages
  public getMessageByMessageId(messageId: string): Observable<Response<MessageModel>> {
    return this.http.get<Response<MessageModel>>(`${HttpDataService.urlMessages}/${messageId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getAllMessages(pageIndex: number, pageSize: number): Observable<NewResponse<MessageModel[]>> {
    return this.http.get<NewResponse<MessageModel[]>>(`${HttpDataService.urlMessages}?pageIndex=${pageIndex}&pageSize=${pageSize}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public createMessage(body: MessageModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${HttpDataService.urlMessages}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  // ReferralSystem
  public getReferralSystem(): Observable<NewResponse<ReferralRecordModel[]>> {
    return this.http.get<NewResponse<ReferralRecordModel[]>>(`${API_URL}/ReferralSystem`, this.optionsSkippingInterceptor).pipe(first());
  }

  // GameProviders
  public getAllGameProviders(): Observable<Response<GameProviderModel[]>> {
    return this.http.get<Response<GameProviderModel[]>>(`${HttpDataService.urlCasinoGameProvidersManage}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateGameProvider(body: GameProviderModel): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${HttpDataService.urlCasinoGameProvidersManage}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public createNewGameProvider(body: GameProviderModel): Observable<Response<string>> {
    // const { id, ...postBody } = body; // we don't need id
    return this.http.post<Response<string>>(`${HttpDataService.urlCasinoGameProvidersManage}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public getGameProviderByGameProviderId(gameProviderId: string): Observable<Response<GameProviderModel>> {
    return this.http.get<Response<GameProviderModel>>(`${HttpDataService.urlCasinoGameProvidersManage}${gameProviderId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteGameProviderById(gameProviderId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${HttpDataService.urlCasinoGameProvidersManage}${gameProviderId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  //Games Manage
  public getAllGamesByProviderId(providerId: string): Observable<Response<GameModel[]>> {
    return this.http.get<Response<GameModel[]>>(`${HttpDataService.urlCasinoGamesManage}${providerId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getGameById(gameId: string): Observable<Response<GameModel>> {
    return this.http.get<Response<GameModel>>(`${HttpDataService.urlCasinoGamesManage}${gameId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteGameById(gameId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${HttpDataService.urlCasinoGamesManage}${gameId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateGame(body: GameModel): Observable<Response<null>> {
    return this.http.put<Response<null>>(`${HttpDataService.urlCasinoGamesManage}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public createNewGame(body: CreateGameModel): Observable<Response<null>> {
    return this.http.post<Response<null>>(`${HttpDataService.urlCasinoGamesManage}`, body, this.optionsSkippingInterceptor).pipe(first());
  }
}
