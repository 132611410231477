import {ActivatedRoute, Router} from '@angular/router';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UtilService} from '../../shared/services/util.service';
import {GameModel} from '../../shared/model/game/game.model';
import {SearchService} from '../../shared/services/search.service';
import {FAVOURITE_GAMES} from '../../../locale/multilingual-strings-constants';
import {GameCategoryModel} from '../../shared/model/game/game-category.model';
import {Carousel} from 'primeng/carousel';
import {DeviceDetectorService} from '../../shared/services/device-detector.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
  private originalGames: Map<string, GameModel[]> = new Map<string, GameModel[]>();
  private originalFilteredGames: GameModel[] = [];
  public gameProviders: GameCategoryModel[] = [];
  public games: Map<string, GameModel[]> = new Map<string, GameModel[]>;
  public filteredGames: GameModel[] = [];
  public isFiltered = false;
  public selectedCategory = '';
  public isLoading = true;
  public isMobileView: boolean;
  public favouriteGames = FAVOURITE_GAMES;
  public images: string[] = [
    'assets/images/banner1.jpg',
    'assets/images/banner2.jpg',
    'assets/images/banner3.jpg',
    'assets/images/banner4.jpg',
  ];

  @Input() isLoginPageOpen: boolean;

  public constructor(
    private utilService: UtilService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private searchService: SearchService,
    private detectorService: DeviceDetectorService,
  ) {
    Carousel.prototype.onTouchMove = () => undefined;
  }

  public ngOnInit() {
    this.isMobileView = this.detectorService.isMobile();
    this.route.params.subscribe(params => {
      this.utilService.initCategories().subscribe(() => {
        this.selectedCategory = params['category'] as string;
        this.isFiltered = this.selectedCategory !== 'home';
        if (!this.isFiltered || this.isLoginPageOpen) {
          this.gameProviders = Array.from(this.utilService.getNonHiddenGameProviders())
          this.sortProvidersByGamesLength(this.gameProviders);
          this.games = this.utilService.allGames;
          this.isLoading = false;
          this.filteredGames = [];
          this.originalGames = new Map(this.games);
        } else if (this.selectedCategory) {
          this.gameProviders = [];
          this.games = new Map<string, GameModel[]>;
          this.filteredGames = this.selectedCategory === 'liked' ? this.utilService.filterLikedGames()
            : this.utilService.filterGamesByCategory(this.selectedCategory);
          this.originalFilteredGames = this.filteredGames;
          this.isLoading = false;
          if (!this.filteredGames.length) {
            this.router.navigate(['']).then();
          }
        }
        this.searchService.getSearchValue().subscribe(searchValue => {
          this.filterGamesBySearch(searchValue);
        });
        this.cdr.detectChanges();
      })
    })
  }

  private filterGamesBySearch(searchValue: string | null | undefined): void {
    if (searchValue !== null && searchValue !== undefined) {
      if (searchValue.trim() === '') {
        this.games = new Map(this.originalGames);
        this.filteredGames = this.originalFilteredGames;
      } else {
        const filteredGamesMap = new Map<string, GameModel[]>();
        for (const [category, gamesOfCategory] of this.originalGames) {
          const filteredGamesOfCategory = gamesOfCategory.filter(game =>
            game.name.toLowerCase().includes(searchValue.toLowerCase())
          );
          if (filteredGamesOfCategory.length > 0) {
            filteredGamesMap.set(category, filteredGamesOfCategory);
          }
        }
        this.games = filteredGamesMap;
        this.filteredGames = this.filteredGames.filter((obj: GameModel) =>
          obj.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        this.isLoading = false;
      }
      this.cdr.detectChanges();
    }
  }

  public updateLikedGames() {
    this.filteredGames = this.utilService.filterLikedGames();
    if (!this.filteredGames.length) {
      this.router.navigate(['']).then();
    }
    this.cdr.detectChanges();
  }

  public trackByImage(index: number, item: string) {
    return item;
  }

  public trackByProviderId(index: number, item: GameCategoryModel) {
    return item.id;
  }

  public trackByFilteredGameId(index: number, item: GameModel) {
    return item.id;
  }

  public sortProvidersByGamesLength(providers: GameCategoryModel[]) {
    if (providers.length) {
      return providers.sort((a, b) => {
        if (a && b && a.games && b.games) {
          return b.games?.length - a.games?.length;
        }
        return 0;
      });
    }
    return providers;
  }
}
