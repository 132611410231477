import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {HttpDataService} from '../../services/http/http-data.service';
import {UtilService} from '../../services/util.service';
import {CasinoTransactionModel} from '../../model/transaction/casino-transaction.model';
import {NewResponse} from '../../model/response';
import {BonusModel} from '../../model/bonuses/bonus.model';
import {NotifierService} from '../../services/notifier.service';
import {TableFieldModel} from '../../model/table-field.model';
import {FieldTypesEnum} from '../../field-types.enum';
import {FieldSortTypesEnum} from '../../field-sort-types.enum';
import {PermissionsEnum} from '../../permissions.enum';
import {AuthService} from '../../services/auth.service';
import {DeviceDetectorService} from '../../services/device-detector.service';

@Component({
  selector: 'casino',
  templateUrl: './casino.component.html',
  styleUrls: ['./casino.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CasinoComponent implements OnInit {
  public casinoHistoryRecords = [];
  public casinoGameHistoryRecords: CasinoTransactionModel[] = [];
  public transactionsPageSize = 50;
  public transactionsPageIndex = 1;
  public bonuses: BonusModel[] = [];
  public totalBonusesRecords: number;
  public bonusesLoading = true;
  public showBonusesTable = false;
  public isMobileView: boolean;
  public hasPermissionToReadWagerBonuses = false;

  public bonusesFields: TableFieldModel[] = [
    {
      name: 'id',
      displayName: '#',
      isCopy: true,
    },
    {
      name: 'bonus',
      displayName: 'Bonus',
      isCopy: false,
      sortable: true,
    },
    {
      name: 'wageringRequirement',
      displayName: 'Wagering Requirement',
      isCopy: false,
      sortable: true,
    },
    {
      name: 'createdAt',
      displayName: 'Created At',
      isCopy: false,
      sortable: true,
      type: FieldTypesEnum.date,
      sortType: FieldSortTypesEnum.date
    },
    {
      name: 'activeUntil',
      displayName: 'Active Until',
      isCopy: false,
      sortable: true,
      type: FieldTypesEnum.date,
      sortType: FieldSortTypesEnum.date
    },
  ];

  public constructor(
    private httpDataService: HttpDataService,
    private utilService: UtilService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private notifierService: NotifierService,
    private detectorService: DeviceDetectorService,
  ) {
    this.isMobileView = this.detectorService.isMobile();
  }

  public formGroup = new FormGroup({
    from: new FormControl(new Date()),
    to: new FormControl(new Date()),
  });

  public casinoGameHistoryForm = new FormGroup({
    from: new FormControl<Date | null>(new Date()),
    to: new FormControl<Date | null>(new Date()),
  });

  public ngOnInit(): void {
    this.initializePermissions();
    this.fetchBonuses();
  }

  public fetchBonuses() {
    if (this.hasPermissionToReadWagerBonuses) {
      this.showBonusesTable = true;
      this.httpDataService.getWagerBonuses(1, 100).subscribe({
        next: res => {
          this.handleBonusesResponse(res);
        },
        error: err => console.error(err),
      });
    }
  }

  private initializePermissions() {
    this.hasPermissionToReadWagerBonuses = this.authService.hasPermission(PermissionsEnum.casinoWagerBonus_read);
  }

  private handleBonusesResponse(res: NewResponse<BonusModel[]>) {
    if (res.succeed) {
      this.totalBonusesRecords = res.value.totalPages * 20;
      this.bonuses = res.value.items;
      this.bonusesLoading = false;
      this.cdr.detectChanges();
    } else this.notifierService.handleErrors(res.errors);
  }

  public fetchCasinoHistory() {
    console.log(this.formGroup.value);
  }

  public fetchCasinoGameHistory() {
    if (this.authService.hasPermission(PermissionsEnum.casino_transactions)) {
      this.casinoGameHistoryForm.disable();
      this.httpDataService.getTransactionsCasinoBetween(
        this.utilService.getSelectedMainCurrency(),
        (this.casinoGameHistoryForm.value.from || new Date()).toISOString(),
        (this.casinoGameHistoryForm.value.to || new Date()).toISOString(),
        this.transactionsPageIndex,
        this.transactionsPageSize,
      ).subscribe(response => {
        if (response.succeed) {
          this.casinoGameHistoryRecords = response.value.items.map(v => ({
              id: v.id,
              created: new Date(v.created),
              baseTransaction: v.baseTransaction,
              gameSession: v.gameSession,
              gameSessionId: v.gameSessionId,
              baseTransactionId: v.baseTransactionId,
            })
          );
          this.casinoGameHistoryForm.enable();
          this.cdr.detectChanges();
        }
      }, error => {
        console.error(error);
        this.casinoGameHistoryForm.enable();
        this.cdr.detectChanges();
      });
    }
  }

}
