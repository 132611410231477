import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {GameModel} from '../../../../shared/model/game/game.model';
import {DeviceDetectorService} from '../../../../shared/services/device-detector.service';
import {CarouselResponsiveOptions} from 'primeng/carousel/carousel.interface';

@Component({
  selector: 'app-game-carousel',
  templateUrl: './game-carousel.component.html',
  styleUrls: ['./game-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GameCarouselComponent implements OnInit, AfterViewInit, AfterViewChecked {
  private currentNumVisible: number;
  @Input() public title: string;
  @Input() public games: GameModel[] = [];
  @ViewChild('pCarousel') taskNoteRef: any;

  public ngAfterViewInit(): void {
    this.currentNumVisible = this.taskNoteRef['_numVisible'];
    this.setEmptyGamesForVisibility();
  }

  public ngAfterViewChecked(): void {
    this.updateCarouselVisibility();
  }

  public numVisible = 8;
  public numScroll = 5;
  public cardContainerWidth: number;
  public isMobileView: boolean;

  constructor(private el: ElementRef,
              private cdr: ChangeDetectorRef,
              private detectorService: DeviceDetectorService) {
  }

  public ngOnInit() {
    this.isMobileView = this.detectorService.isMobile();
  }

  public responsiveOptions: CarouselResponsiveOptions[] = [
    {
      breakpoint: '2000px',
      numVisible: 7,
      numScroll: 3
    },
    {
      breakpoint: '1930px',
      numVisible: 6,
      numScroll: 3
    },
    {
      breakpoint: '1750px',
      numVisible: 5,
      numScroll: 3
    },
    {
      breakpoint: '1530px',
      numVisible: 4,
      numScroll: 3
    },
    {
      breakpoint: '1300px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '1080px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '850px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  public responsiveOptionsForMobile: CarouselResponsiveOptions[] = [
    {
      breakpoint: '1400px',
      numVisible: 8,
      numScroll: 3
    },

    {
      breakpoint: '1180px',
      numVisible: 7,
      numScroll: 3
    },

    {
      breakpoint: '1015px',
      numVisible: 6,
      numScroll: 3
    },

    {
      breakpoint: '910px',
      numVisible: 5,
      numScroll: 3
    },

    {
      breakpoint: '760px',
      numVisible: 4,
      numScroll: 3
    },

    {
      breakpoint: '640px',
      numVisible: 3,
      numScroll: 2
    },

    {
      breakpoint: '500px',
      numVisible: 2,
      numScroll: 2
    },

    {
      breakpoint: '370px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  private updateCarouselVisibility() {
    if (this.currentNumVisible !== this.taskNoteRef['_numVisible']) {
      this.currentNumVisible = this.taskNoteRef['_numVisible'];
      this.setEmptyGamesForVisibility();
      if (this.el.nativeElement.querySelector('.p-carousel')) {
        this.cdr.detectChanges()
      }
    }
  }

  private setEmptyGamesForVisibility() {
    const visibleGames = this.games.filter(game => game && game?.id);
    const emptySlots = Math.max(0, this.currentNumVisible - visibleGames.length);
    this.games = visibleGames.concat(new Array(emptySlots).fill(null));
    this.cdr.detectChanges();
  }
}
