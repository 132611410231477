<div *ngIf="(!isMobileView || isVisible) && (providers$ | async) as providers">
  <ng-container *ngIf="providers.length > 0; else noProviders">
    <div class="carousel-container-providers">
      <ng-container *ngIf="responsiveOptions">
        <p-carousel
          [numVisible]="9"
          [numScroll]="9"
          [value]="providers$ | async"
          [showIndicators]="false"
          [responsiveOptions]="isMobileView? responsiveOptionsForMobile : responsiveOptions">
          <ng-template let-provider pTemplate="item">
            <div class="provider-item" [title]="provider.displayName | translateIfMultilingual"
                 (click)="filterGamesByCategory(provider.name)"
                 [ngClass]="{'selected': selectedProvider === provider.name}">
              <img class="provider-img no-user-selection" [src]="provider.url">
            </div>
          </ng-template>
        </p-carousel>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #noProviders>
    <p>No providers found.</p>
  </ng-template>
</div>
