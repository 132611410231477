<div class="referral-wrapper">
  <div *ngIf="isAuthenticated">
    <p i18n="@@referralLinkHeader">Referral link</p>
    <div class="referral-link">
      <p>{{ referralLink }}</p>
      <img class="referral-copy no-user-selection"
           [src]="copyHovered ? 'assets/wallet/copy_active.svg' : 'assets/wallet/copy.svg'"
           alt="message icon"
           [cdkCopyToClipboard]="referralLink"
           (mouseenter)="copyHovered=true"
           (mouseleave)="copyHovered=false">
    </div>
  </div>

  <app-panel
    [header]="'How to invite players?'"
    [text]="'Tell them about the system and send them a link pointed above for registration. Throughout the time when the players play in our resource, you will receive 1 % of the amount they withdraw from the system. If the players you invite bring in new players, you will also profit from their earnings. This chain is endless. With the right approach, you can create a system that provides you with a stable income in a short time. The system is created by the players – for the players. The payout percentage of the system is the highest. The involved players will thank you for it.'"
  ></app-panel>

  <p-accordion>
    <p-accordionTab *ngIf="isAuthenticated">
      <ng-template pTemplate="header">
        <div class="flex align-items-center">
          <img width="22"
               class="no-user-selection"
               [src]="'assets/identity/referral.svg'" style="margin-right: 12px">
          <span class="vertical-align-middle" i18n="@@referrals">Referrals</span>
        </div>
      </ng-template>

      <ng-template pTemplate="content">
        <div class="history-wrapper">
          <div class="history">
            <div (click)="fetchReferrals()" class="text-btn">
              <button class="inner-btn inner-btn-wide">Fetch</button>
            </div>
          </div>

          <div>
            <p-table [value]="referralsRecords" [tableStyle]="{ 'min-width': '30rem' }">
              <ng-template pTemplate="header">
                <tr>
                  <th i18n="@@identifierHeader">Identifier</th>
                  <th i18n="@@firstNameHeader">First name</th>
                  <th i18n="@@lastNameHeader">Last name</th>
                  <th i18n="@@countryHeader">Country</th>
                  <th i18n="@@referralsHeader">Referrals</th>
                  <th i18n="@@createdHeader">Created</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-record>
                <tr>
                  <td>{{ record.id.value }}</td>
                  <td>{{ record.firstName }}</td>
                  <td>{{ record.lastName }}</td>
                  <td>{{ record.country }}</td>
                  <td>{{ record.referrals }}</td>
                  <td>{{ record.created | date:'medium' }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </ng-template>
    </p-accordionTab>

    <p-accordionTab *ngIf="isAuthenticated">
      <ng-template pTemplate="header">
        <div class="flex align-items-center">
          <img width="22"
               class="no-user-selection"
               [src]="'assets/casino/history.svg'" style="margin-right: 12px">
          <span class="vertical-align-middle" i18n="@@history">History</span>
        </div>
      </ng-template>

      <ng-template pTemplate="content">
        <h4 i18n="@@transactionsHistoryHeader">Transactions history</h4>

        <div class="history-wrapper">
          <form [formGroup]="historyForm" (ngSubmit)="fetchTransactionHistory()">
            <div [ngClass]="{ 'account-content' : !isMobileView, 'account-content__mobile': isMobileView }">
              <casino-date-picker
                [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
                [width]="isMobileView ? '100%' : '250px'"
                [placeholder]="'From'"
                [label]="'From'"
                [value]="historyForm['from']"
                (onValueChange)="historyForm.controls['from'].setValue($event)">
              </casino-date-picker>

              <casino-date-picker
                [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
                [width]="isMobileView ? '100%' : '250px'"
                [placeholder]="'To'"
                [label]="'To'"
                [value]="historyForm['to']"
                (onValueChange)="historyForm.controls['to'].setValue($event)">
              </casino-date-picker>
              <div class="text-btn">
                <button class="inner-btn inner-btn-wide">Fetch</button>
              </div>
            </div>
          </form>
          <div>
            <p-table [value]="transactionsHistoryRecords" [tableStyle]="{ 'min-width': '30rem' }">
              <ng-template pTemplate="header">
                <tr>
                  <th>#</th>
                  <th i18n="@@senderHeader">Sender</th>
                  <th i18n="@@amountHeader">Amount</th>
                  <th i18n="@@createdHeader">Created</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-record>
                <tr>
                  <td>{{ record.id }}</td>
                  <td>{{ record.sender }}</td>
                  <td>{{ record.amount }}</td>
                  <td>{{ record.created | date:'medium' }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </ng-template>
    </p-accordionTab>
  </p-accordion>
</div>
