export enum PermissionsEnum {
  organization_read = 'Organization.Read',
  organization_create = 'Organization.Create',
  organization_edit = 'Organization.Edit',
  organization_delete = 'Organization.Delete',
  roles_Read = 'Roles.Read',
  roles_create = 'Roles.Create',
  roles_edit = 'Roles.Edit',
  roles_delete = 'Roles.Delete',
  userRoles_Read = 'UserRoles.Read',
  userRoles_create = 'UserRoles.Create',
  userRoles_edit = 'UserRoles.Edit',
  userRoles_delete = 'UserRoles.Delete',
  rolePermissions_read = 'RolePermissions.Read',
  rolePermissions_create = 'RolePermissions.Create',
  rolePermissions_edit = 'RolePermissions.Edit',
  rolePermissions_delete = 'RolePermissions.Delete',
  organizationRole_read = 'OrganizationRole.Read',
  organizationRole_create = 'OrganizationRole.Create',
  organizationRole_delete = 'OrganizationRole.Delete',
  organizationUser_read = 'OrganizationUser.Read',
  organizationUser_edit = 'OrganizationUser.Edit',
  organizationUser_delete = 'OrganizationUser.Delete',
  users_read = 'Users.Read',
  users_create = 'Users.Create',
  users_edit = 'Users.Edit',
  users_delete = 'Users.Delete',
  profile_read = 'Profile.Read',
  profile_edit = 'Profile.Edit',
  profiles_read = 'Profiles.Read',
  profiles_edit = 'Profiles.Edit',
  session_read = 'Session.Read', // todo
  session_delete = 'Session.Delete',
  sessions_read = 'Sessions.Read',
  account_manage = 'Account.Manage',
  gameSession_view = 'GameSession.View',
  gameSession_create = 'GameSession.Create',
  gameProvider_read = 'GameProvider.Read',
  gameProvider_create = 'GameProvider.Create',
  gameProvider_edit = 'GameProvider.Edit',
  gameProvider_delete = 'GameProvider.Delete',
  game_read = 'Game.Read',
  game_create = 'Game.Create',
  game_edit = 'Game.Edit',
  game_delete = 'Game.Delete',
  hostedServices_manage = 'HostedServices.Manage',
  wallet_withdraw = 'Wallet.Withdraw',
  wallet_deposit = 'Wallet.Deposit',
  wallet_transfer = 'Wallet.Transfer',
  wallet_manage = 'Wallet.Manage',
  wallets_manage = 'Wallets.Manage',
  wallet_transactions = 'Wallet.Transactions',
  casino_transactions = 'Casino.Transactions',
  transfer_transactions = 'Transfer.Transactions',
  crypto_transactions = 'Crypto.Transactions',
  referral_transactions = 'Referral.Transactions',
  referralSystem_read = 'ReferralSystem.Read',
  referralSystems_read = 'ReferralSystems.Read', // todo
  mailbox_read = 'Mailbox.Read',
  message_read = 'Message.Read',
  message_create = 'Message.Create',
  message_edit = 'Message.Edit',
  message_delete = 'Message.Delete',
  messageRecipient_read = 'MessageRecipient.Read',
  messageRecipient_create = 'MessageRecipient.Create',
  messageRecipient_edit = 'MessageRecipient.Edit',
  messageRecipient_delete = 'MessageRecipient.Delete',
  cultures_read = 'Cultures.Read',
  permissions_manage = 'Permissions.Manage',
  currencies_read = 'Currencies.Read',
  organizationCurrencies_read = 'OrganizationCurrencies.Read',
  organizationCurrencies_create = 'OrganizationCurrencies.Create',
  organizationCurrencies_delete = 'OrganizationCurrencies.Delete',
  organizationHierarchy_read = 'OrganizationHierarchy.Read',
  organizationHierarchy_edit = 'OrganizationHierarchy.Edit',
  organizationSetting_read = 'OrganizationSetting.Read',
  organizationSetting_create = 'OrganizationSetting.Create',
  organizationSetting_edit = 'OrganizationSetting.Edit',
  organizationSetting_delete = 'OrganizationSetting.Delete',
  setting_read = 'Setting.Read',
  setting_create = 'Setting.Create',
  setting_edit = 'Setting.Edit',
  setting_delete = 'Setting.Delete', // todo
  casinoWagerBonus_read = 'CasinoWagerBonus.Read',
  casinoWagerBonuses_read = 'CasinoWagerBonuses.Read',
  casinoWagerBonuses_create = 'CasinoWagerBonuses.Create',
  casinoWagerBonuses_delete = 'CasinoWagerBonuses.Delete',
}
