import {NgModule} from '@angular/core';
import {HttpDataService} from './services/http/http-data.service';
import {HttpClientModule} from '@angular/common/http';
import {TranslateIfMultilingualPipe} from './pipes/translate-if-multilingual.pipe';
import {CommonModule, DatePipe} from '@angular/common';
import {SidebarModule} from 'primeng/sidebar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {TableModule} from 'primeng/table';
import {AccordionModule} from 'primeng/accordion';
import {CalendarModule} from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KeyFilterModule} from 'primeng/keyfilter';
import {SplitButtonModule} from 'primeng/splitbutton';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {ImageModule} from 'primeng/image';
import {CardModule} from 'primeng/card';
import {TabViewModule} from 'primeng/tabview';
import {DividerModule} from 'primeng/divider';
import {CheckboxModule} from 'primeng/checkbox';
import {MultiSelectModule} from 'primeng/multiselect';
import {TableComponent} from './components/table/table.component';
import {MenuModule} from 'primeng/menu';
import {TreeSelectModule} from 'primeng/treeselect';
import {FieldsetModule} from 'primeng/fieldset';
import {SkeletonModule} from 'primeng/skeleton';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {TooltipModule} from 'primeng/tooltip';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ProgressBarModule} from 'primeng/progressbar';
import {DeletePopup} from './components/delete-popup/delete-popup.component';
import {NavigationService} from './services/navigation.service';
import {NotifierService} from './services/notifier.service';
import {LeftBarComponent} from './components/left-bar/left-bar.component';
import {HeaderComponent} from './components/header/header.component';
import {SearchComponent} from './components/search/search.component';
import {PanelComponent} from './components/panel/panel.component';
import {PanelModule} from 'primeng/panel';
import {SubmitButtonComponent} from './components/submit-button/submit-button.component';
import {DateService} from './services/date.service';
import {InputComponent} from './components/input/input.component';
import {DropdownComponent} from './components/dropdown/dropdown.component';
import {DatePickerComponent} from './components/date-picker/date-picker.component';
import {InputNumberComponent} from './components/input-number/input-number.component';
import {InputNumberModule} from 'primeng/inputnumber';
import {TextareaComponent} from './components/textarea/textarea.component';
import {PasswordComponent} from './components/password/password.component';
import {PasswordModule} from 'primeng/password';

@NgModule({
  declarations: [
    TranslateIfMultilingualPipe,
    TableComponent,
    DeletePopup,
    LeftBarComponent,
    HeaderComponent,
    SearchComponent,
    PanelComponent,
    SubmitButtonComponent,
    InputComponent,
    DropdownComponent,
    DatePickerComponent,
    InputNumberComponent,
    TextareaComponent,
    PasswordComponent,
  ],
  imports: [
    HttpClientModule,
    ClipboardModule,
    CommonModule,
    SidebarModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    TableModule,
    AccordionModule,
    CalendarModule,
    DropdownModule,
    InputTextareaModule,
    KeyFilterModule,
    SplitButtonModule,
    ImageModule,
    CardModule,
    TabViewModule,
    DividerModule,
    CheckboxModule,
    MultiSelectModule,
    MenuModule,
    TreeSelectModule,
    FieldsetModule,
    SkeletonModule,
    TieredMenuModule,
    TooltipModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    PanelModule,
    InputNumberModule,
    PasswordModule,
  ],
  exports: [
    TranslateIfMultilingualPipe,
    TableComponent,
    LeftBarComponent,
    HeaderComponent,
    PanelComponent,
    SubmitButtonComponent,
    InputComponent,
    DropdownComponent,
    DatePickerComponent,
    InputNumberComponent,
    SearchComponent,
    TextareaComponent,
    PasswordComponent,
  ],
  providers: [
    HttpDataService,
    NavigationService,
    NotifierService,
    DateService,
    DatePipe,
  ]
})
export class SharedModule {
}
