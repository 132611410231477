<p-accordion [ngClass]="{'mobile-view': isMobileView}">
  <p-accordionTab *ngIf="hasProfileViewPermission">
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <img width="22" class="no-user-selection" [src]="'assets/identity/person.svg'">
        <span class="vertical-align-middle" i18n="@@profileHeader">Profile</span>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div>
        <form [formGroup]="profileForm" (ngSubmit)="saveProfile()">
          <div [ngClass]="{ 'account-inputs' : !isMobileView, 'account-inputs__mobile': isMobileView }">
            <div [ngClass]="{ 'account-content' : !isMobileView, 'account-content__mobile': isMobileView }">
              <casino-input [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
                            [width]="isMobileView ? '100%' : '250px'"
                            [label]="'First Name'"
                            [placeholder]="'First Name'"
                            [value]="profileForm['firstName']"
                            (onValueChange)="profileForm.controls['firstName'].setValue($event)"
              ></casino-input>

              <casino-input [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
                            [width]="isMobileView ? '100%' : '250px'"
                            [label]="'Last Name'"
                            [placeholder]="'Last Name'"
                            [value]="profileForm['lastName']"
                            (onValueChange)="profileForm.controls['lastName'].setValue($event)"
              ></casino-input>
            </div>

            <div [ngClass]="{ 'account-content' : !isMobileView, 'account-content__mobile': isMobileView }">
              <casino-dropdown [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
                               [id]="'genderDropdown'"
                               [width]="isMobileView ? '100%' : '250px'"
                               [label]="'Gender'"
                               [options]="genders"
                               [placeholder]="'Gender'"
                               [optionLabel]="'name'"
                               [optionValue]="'name'"
                               (onValueChange)="profileForm.controls['gender'].setValue($event)"
              ></casino-dropdown>

              <casino-date-picker [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
                                  [width]="isMobileView ? '100%' : '250px'"
                                  [placeholder]="'Date of Birthday'"
                                  [label]="'Date of Birthday'"
                                  [value]="profileForm['dateOfBirth']"
                                  (onValueChange)="profileForm.controls['dateOfBirth'].setValue($event)"
              ></casino-date-picker>
            </div>

            <div [ngClass]="{ 'account-content' : !isMobileView, 'account-content__mobile': isMobileView }">
              <casino-dropdown [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
                               [width]="isMobileView ? '100%' : '250px'"
                               [id]="'countryDropdown'"
                               [label]="'Country'"
                               [options]="countries"
                               [placeholder]="'Country'"
                               [optionLabel]="'name'"
                               [optionValue]="'name'"
                               (onValueChange)="profileForm.controls['country'].setValue($event)"
              ></casino-dropdown>

              <casino-input [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
                            [width]="isMobileView ? '100%' : '250px'"
                            [label]="'City'"
                            [placeholder]="'City'"
                            [value]="profileForm['city']"
                            (onValueChange)="profileForm.controls['city'].setValue($event)"
              ></casino-input>
            </div>

            <div [ngClass]="{ 'account-content' : !isMobileView, 'account-content__mobile': isMobileView }">
              <casino-input [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
                            [width]="isMobileView ? '100%' : '250px'"
                            [label]="'Address'"
                            [placeholder]="'Address'"
                            [value]="profileForm['address']"
                            (onValueChange)="profileForm.controls['address'].setValue($event)"
              ></casino-input>

              <casino-input [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
                            [width]="isMobileView ? '100%' : '250px'"
                            [label]="'ZIP Code'"
                            [placeholder]="'ZIP Code'"
                            [value]="profileForm['zipCode']"
                            (onValueChange)="profileForm.controls['zipCode'].setValue($event)"
              ></casino-input>
            </div>

            <div [ngClass]="{ 'account-content' : !isMobileView, 'account-content__mobile': isMobileView }">
              <casino-input [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
                            [width]="isMobileView ? '100%' : '250px'"
                            [label]="'Passport ID'"
                            [placeholder]="'Passport ID'"
                            [value]="profileForm['passportId']"
                            (onValueChange)="profileForm.controls['passportId'].setValue($event)"
              ></casino-input>
            </div>

            <div [ngClass]="{ 'account-content' : !isMobileView, 'account-content__mobile': isMobileView }" *ngIf="profileErrorMessages?.length">
              <small class="error-message">{{ profileErrorMessages }}</small>
            </div>

            <div class="text-btn">
              <button class="inner-btn inner-btn-wide">Save Changes</button>
            </div>
          </div>
        </form>
      </div>
    </ng-template>
  </p-accordionTab>

  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <img width="22"
             class="no-user-selection"
             [src]="'assets/identity/unverified.svg'">
        <span class="vertical-align-middle" i18n="@@contactsLabel">Contacts</span>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div>
        <form [formGroup]="contactsForm" (ngSubmit)="saveContacts()">
          <div [ngClass]="{ 'account-content' : !isMobileView, 'account-content__mobile': isMobileView }">
            <casino-input [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
                          [width]="isMobileView ? '100%' : '250px'"
                          [label]="'Email'"
                          [placeholder]="'Email'"
                          [value]="profileForm['email']"
                          (onValueChange)="profileForm.controls['email'].setValue($event)"
            ></casino-input>

            <div class="profile__country__phone account-content__item" [style]="{width: isMobileView ? '100%' : '250px'}">
              <div class="country__dropdown">
                <casino-dropdown [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
                                 [width]="'100px'"
                                 [id]="'countryDropdown'"
                                 [label]="'Country'"
                                 [options]="countries"
                                 [placeholder]="'Country'"
                                 [optionLabel]="'code'"
                                 [optionValue]="'code'"
                                 (onValueChange)="onCountryChange($event)"
                ></casino-dropdown>
              </div>

              <div class="profile__phone">
                <p-inputMask
                  size="30"
                  id="phone"
                  [style]="{height: '48px', width: isMobileView ? '100%' : '250px', borderRadius: '22px', border: 'none',}"
                  mask="{{countryCode}} 99-999-999"
                  placeholder="{{countryCode}} 99-999-999"
                  formControlName="phoneNumber"
                  class="align-right-input app-input__field">
                </p-inputMask>
              </div>
            </div>
            <span class="mail-text" i18n="@@confirmationEmailMessage">If you did not receive your confirmation make sure to check the spam folder in your email client.</span>
            <div class="text-btn">
              <button class="inner-btn inner-btn-wide">Send confirmation email</button>
            </div>
          </div>
        </form>
      </div>
    </ng-template>
  </p-accordionTab>

  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <img width="22"
             class="no-user-selection"
             [src]="'assets/identity/username.svg'">
        <span class="vertical-align-middle" i18n="@@userLabel">User</span>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div [ngClass]="{ 'account-content' : !isMobileView, 'account-content__mobile': isMobileView }">
        <casino-input [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
                      [width]="isMobileView ? '100%' : '250px'"
                      [label]="'User name'"
                      [placeholder]="'User name'"
                      [disabled]="true"
                      [value]="">  <!-- todo set value -->
        </casino-input>
      </div>
    </ng-template>
  </p-accordionTab>

  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <img width="22"
             class="no-user-selection"
             [src]="'assets/identity/change_password.svg'">
        <span class="vertical-align-middle" i18n="@@passwordLabel">Password</span>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div>
        <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
          <div [ngClass]="{ 'account-inputs' : !isMobileView, 'account-inputs__mobile': isMobileView }">
            <div [ngClass]="{ 'account-content' : !isMobileView, 'account-content__mobile': isMobileView }">
              <div [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }">
                <casino-password
                  [width]="isMobileView ? '100%' : '250px'"
                  [id]="'currentPassword'"
                  [label]="'Current Password'"
                  [placeholder]="'Current Password'"
                  [fcn]="'currentPassword'"
                  [fg]="profileForm">
                </casino-password>
              </div>

              <div [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }">
                <casino-password
                  [width]="isMobileView ? '100%' : '250px'"
                  [id]="'newPassword'"
                  [label]="'New Password'"
                  [placeholder]="'New Password'"
                  [fcn]="'newPassword'"
                  [fg]="profileForm">
                </casino-password>
              </div>
            </div>

            <div [ngClass]="{ 'account-content' : !isMobileView, 'account-content__mobile': isMobileView }">
              <div [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }">
                <casino-password
                  [width]="isMobileView ? '100%' : '250px'"
                  [id]="'confirmPassword'"
                  [label]="'Confirm Password'"
                  [placeholder]="'Confirm Password'"
                  [fcn]="'confirmPassword'"
                  [fg]="profileForm">
                </casino-password>
              </div>
            </div>

            <div [ngClass]="{ 'account-content' : !isMobileView, 'account-content__mobile': isMobileView }" *ngIf="changePasswordErrorMessages?.length">
              <small class="error-message">{{ changePasswordErrorMessages }}</small>
            </div>

            <div class="text-btn">
              <button
                [disabled]="changePasswordForm.invalid"
                class="inner-btn inner-btn-wide"
              >
                Change Password
              </button>
            </div>
          </div>
        </form>
      </div>
    </ng-template>
  </p-accordionTab>
</p-accordion>
