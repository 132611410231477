<footer class="site-footer">
  <div [ngClass]="{'footer-content': !isMobileDevice, 'footer-content-mobile': isMobileDevice}" class="footer-content">
    <div class="supported-currencies-container">
      <p i18n="@@SupportedCryptoCurrencies">Supported Crypto Currencies</p>
      <div class="icons-container">
        <div (click)="openExternalLink('https://bitcoin.org/en/')">
          <img src="assets/cryptocurrencies/btc.svg">
        </div>
        <div (click)="openExternalLink('https://litecoin.com/en/')">
          <img src="assets/cryptocurrencies/ltc.svg">
        </div>
        <div (click)="openExternalLink('https://www.dash.org/ru/')">
          <img src="assets/cryptocurrencies/dash.svg">
        </div>
        <div (click)="openExternalLink('https://dogecoin.com/')">
          <img src="assets/cryptocurrencies/doge.svg">
        </div>
        <div (click)="openExternalLink('https://bitcoincash.org/')">
          <img src="assets/cryptocurrencies/bch.svg">
        </div>
      </div>
    </div>
    <div class="payment-methods-container">
      <p i18n="@@PaymentMethods">Payment methods</p>
      <div class="icons-container">
        <img src="assets/images/onramper_icon.svg" (click)="openExternalLink('https://onramper.com/')">
        <img src="assets/images/binance_icon.svg"
             (click)="openExternalLink('https://accounts.binance.com/en/register?ref=207299185&gclid=CjwKCAjwkaSaBhA4EiwALBgQaBp7rCCO7gSZKDxwg2rK2FKOC2c3SfGd74iIvjTY74Rta2rG3Mm-HRoCOE4QAvD_BwE')">
        <img src="assets/images/kraken_icon.svg" (click)="openExternalLink('https://www.kraken.com/sign-in')">
        <img src="assets/images/blockfinex_icon.svg"
             (click)="openExternalLink('https://www.blockfinex.com/account/signin')">
        <img src="assets/images/bestchange.jpeg" (click)="openExternalLink('https://www.bestchange.com/')">
      </div>
      <p class="underneath_text" i18n="@@PaymentAvailability">
        Availability of payment providers and their payment selection may vary per country
      </p>
    </div>
    <div class="information">
      <nav>
        <a class="navigation-link" (click)="openRightBar('about')" i18n="@@About">About</a>
        <a class="navigation-link" (click)="openRightBar('privacy')" i18n="@@Privacy">Privacy</a>
      </nav>
    </div>
  </div>
</footer>
