import {Component, ElementRef, HostBinding, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-canvas-background',
  templateUrl: './canvas-background.component.html',
  styleUrls: ['./canvas-background.component.scss']
})
export class CanvasBackgroundComponent implements OnInit {
  @HostBinding('$anim-layer1-color') layer1Color = 'rgba(133, 12, 5, 0.2)';
  @HostBinding('$anim-layer2-color') layer2Color = 'rgba(45, 19, 18, 0.3)';
  @HostBinding('$anim-layer3-color') layer3Color = 'rgba(106, 18, 12, 0.5)';
  @HostBinding('$anim-layer4-color') layer4Color = 'rgba(35, 5, 5, 0.7)';

  @ViewChild('animationCanvas', {static: true}) public canvasRef!: ElementRef<HTMLCanvasElement>;
  public ctx!: CanvasRenderingContext2D | null;

  public rotationAngle1 = 50;
  public rotationAngle2 = 30;
  public translationY = 0;
  public translationX = 0;

  public ngOnInit(): void {
    const canvas: HTMLCanvasElement = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const draw = (): void => {
      if (!this.ctx) return;

      this.ctx.clearRect(0, 0, canvas.width, canvas.height);

      this.rotationAngle1 += 0.0008;
      this.rotationAngle2 -= 0.0011;
      this.translationX = Math.sin(this.rotationAngle1) * 1.01;
      this.translationY = Math.cos(this.rotationAngle2) * 1.01;

      this.drawLayer4();
      this.drawLayer3();
      this.drawLayer2();
      this.drawLayer1();

      requestAnimationFrame(draw);
    };

    draw();
  }

  public drawLayer4(): void {
    if (!this.ctx) return;

    this.ctx.save();
    this.ctx.translate(
      this.canvasRef.nativeElement.width / 10 + this.translationX,
      this.canvasRef.nativeElement.height / 9 + this.translationY
    );
    this.ctx.rotate(this.rotationAngle1 + 0.002);
    this.ctx.fillStyle = this.layer1Color;
    this.ctx.fillRect(
      -this.canvasRef.nativeElement.width / 4,
      -this.canvasRef.nativeElement.height / 7,
      this.canvasRef.nativeElement.width,
      this.canvasRef.nativeElement.height
    );
    this.ctx.restore();
  }

  public drawLayer3(): void {
    if (!this.ctx) return;

    this.ctx.save();
    this.ctx.translate(
      this.canvasRef.nativeElement.width / 1.1 + this.translationX,
      this.canvasRef.nativeElement.height / 1.1 + this.translationY
    );
    this.ctx.rotate(this.rotationAngle2 + 0.03);
    this.ctx.fillStyle = this.layer2Color;
    this.ctx.fillRect(
      -this.canvasRef.nativeElement.width / 2,
      -this.canvasRef.nativeElement.height / 2,
      this.canvasRef.nativeElement.width,
      this.canvasRef.nativeElement.height
    );
    this.ctx.restore();
  }

  public drawLayer2(): void {
    if (!this.ctx) return;

    this.ctx.save();
    this.ctx.translate(
      this.canvasRef.nativeElement.width / 2 + this.translationX,
      this.canvasRef.nativeElement.height / 2 + this.translationY
    );
    this.ctx.rotate(this.rotationAngle1);
    this.ctx.fillStyle = this.layer3Color;
    this.ctx.fillRect(
      -this.canvasRef.nativeElement.width / 2,
      -this.canvasRef.nativeElement.height / 2,
      this.canvasRef.nativeElement.width,
      this.canvasRef.nativeElement.height
    );
    this.ctx.restore();
  }

  public drawLayer1(): void {
    if (!this.ctx) return;

    this.ctx.save();
    this.ctx.translate(
      this.canvasRef.nativeElement.width / 2.8 + this.translationX,
      this.canvasRef.nativeElement.height / 3 + this.translationY
    );
    this.ctx.rotate(this.rotationAngle2);
    this.ctx.fillStyle = this.layer4Color;
    this.ctx.fillRect(
      -this.canvasRef.nativeElement.width / 1.4,
      -this.canvasRef.nativeElement.height / 7,
      this.canvasRef.nativeElement.width,
      this.canvasRef.nativeElement.height
    );
    this.ctx.restore();
  }
}
