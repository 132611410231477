<div class="flex flex-column gap-4">
  <app-panel
    [header]="'How to deposit?'"
    [text]="'Select the currency you want to deposit in the wallet. In the Deposit tab, the Deposit address field contains the address of the wallet to which you want to transfer money. Copy the wallet’s address or scan the QR code and make a transfer. Note, after each replenishment, the wallet address changes.'"
  ></app-panel>

  <form class="flex flex-column gap-4" [formGroup]="depositForm">
    <div class="deposit-panel">
      <div class="deposit-info">
        <div [ngClass]="{'no-qr': !isQrCodeVisible}" *ngIf="depositForm?.value?.depositAddress" class="qr-code">
          <div class="animate" *ngIf="isQrCodeVisible">
            <qrcode
              [qrdata]="depositForm.value.depositAddress"
              [cssClass]="'center'"
              [colorDark]="'#ffffff'"
              [colorLight]="'#000000'"
              [elementType]="'svg'"
              [errorCorrectionLevel]="'M'"
              [margin]="0"
              [scale]="1"
              [width]="180"
            ></qrcode>
          </div>
        </div>
        <div>
          <p class="currency-price-text">
    <span class="main-currency-text">1 {{currentCurrency.code | uppercase}}<span
      i18n="@@cryptoPrice"> ≈ </span></span> {{rate || 0}} {{gameCurrency.symbol}}
          </p>
          <p class="currency-price-text" i18n="@@PendingAmount">
            Pending 0 {{currentCurrency?.code | uppercase}} | 0 {{gameCurrency.symbol}}
          </p>
        </div>
      </div>
      <div class="qr-address-info">
        <p class="deposit-address-text">Deposit Address</p>
        <div>{{depositForm.value.depositAddress}}</div>
        <div class="qr-info-icons">
          <i #copyButton (click)="showCopied(copyButton, copied)" class="pi pi-copy deposit-address-icon copy"
             [cdkCopyToClipboard]="depositForm.value.depositAddress"></i>
          <i #copied class="pi pi-check deposit-address-icon copied"></i>
          <i class="pi pi-qrcode deposit-address-icon" (click)="isQrCodeVisible = !isQrCodeVisible"></i>
        </div>
      </div>
    </div>
    <div class="note-text" i18n="@@AddressChangeNote">Note that the address will change after every deposit</div>
  </form>
</div>
