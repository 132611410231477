import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'casino-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('expandInput', [
      state('collapsed', style({
        maxWidth: '0',
      })),
      state('expanded', style({
        maxWidth: '250px',
      })),
      transition('collapsed => expanded', animate('0.3s ease-in-out')),
      transition('expanded => collapsed', animate('0.3s ease-in-out'))
    ])
  ]
})
export class SearchComponent {
  public isSearchInputVisible = false;
  private _alwaysExpanded = false;
  @Input() public placeholder = 'Search';
  @Input() public type = 'text';
  @Input() public width: string;
  @Output() public onKeyup$ = new EventEmitter<string>();
  @Output() public onInput$ = new EventEmitter<string>();

  get alwaysExpanded(): boolean {
    return this._alwaysExpanded;
  }

  @Input() set alwaysExpanded(value: boolean) {
    this.isSearchInputVisible = value;
    this._alwaysExpanded = value;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this._alwaysExpanded) {
          this.isSearchInputVisible = true;
          this.cdr.detectChanges();
        } else {
          this.hideSearchInput();
        }
      }
    })
  }

  private hideSearchInput() {
    if (!this._alwaysExpanded) {
      this.isSearchInputVisible = false;
      this.cdr.detectChanges();
    }
  }

  public onInput(text: string) {
    this.onInput$.emit(text);
  }

  public onSearch(text: string, event?: MouseEvent) {
    if (!text) {
      this.hideSearchInput();
      event?.stopPropagation();
    }
    this.onKeyup$.emit(text);
  }

  public toggleSearchInput() {
    if (!this._alwaysExpanded) {
      this.isSearchInputVisible = !this.isSearchInputVisible;
      this.cdr.detectChanges();
    }
  }
}
