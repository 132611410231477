<div [style]="{width: isMobileView ? '100%' : '500px'}" [@loginSlideInAnimation]="'in'" class="login-container wrapper">
  <div class="scroll">
    <div class="card no-user-selection">
      <div class="close-btn" (click)="closeLoginPage()">
        <span>
          <i class="pi pi-times"></i>
        </span>
      </div>

      <form [formGroup]="loginForm" (ngSubmit)="login()" class="action">
        <div class="text-center">
          <h1 i18n="@@loginHeader">Login</h1>
        </div>

        <div class="input-box">
          <casino-input [id]="'identifier'"
                        [width]="'100%'"
                        [type]="'email'"
                        [hasError]="loginForm.get('identifier')?.touched && loginForm.get('identifier')?.hasError('required')"
                        [label]="'Email'"
                        [placeholder]="'Email'"
                        [validationMessage]="'The Email is required.'"
                        [touched]="loginForm.get('identifier')?.touched"
                        [fcn]="'identifier'"
                        [fg]="loginForm"
          ></casino-input>
        </div>

        <div class="input-box">
          <casino-input [id]="'password'"
                        [width]="'100%'"
                        [type]="showPassword ? 'text' : 'password' "
                        [hasError]="loginForm.get('password')?.touched && loginForm.get('password')?.hasError('required')"
                        [label]="'Password'"
                        [placeholder]="'Password'"
                        [validationMessage]="'The password is required.'"
                        [touched]="loginForm.get('password')?.touched"
                        [fcn]="'password'"
                        [fg]="loginForm"
          ></casino-input>
          <span class="password-toggle-icon" (click)="togglePasswordVisibility()">
                  <i [class]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'" id="eye"></i>
              </span>

          <small class="p-error block" *ngIf="loginForm.invalid && loginForm.get('password')?.touched ">
            <small class="p-error block" *ngIf="loginForm?.get('password')?.hasError('minlength')"
                   i18n="@@PasswordMinLength">
              The Password field must be a minimum of 8 characters
            </small>
          </small>
        </div>

        <div class="action-buttons">
          <div class="rememberMe">
            <input formControlName="rememberMe" type="checkbox">
            <label i18n="@@RememberMeLabel">Remember Me</label>
          </div>

          <div class="forgotPass" (click)="openForgotPasswordPage()">
            <a i18n="@@ForgotPasswordLink">Forgot Password</a>
          </div>
        </div>

        <div class="header-item text-btn">
          <button
            i18n="header login button @@loginButton"
            class="inner-btn"
            type="submit"
          >Login
          </button>
        </div>

        <div class="registerPart" i18n="@@NotAMember">
          Not a member?
          <a routerLinkActive="active" (click)="openRegisterPage()">Sign Up</a>
        </div>
      </form>
    </div>
  </div>
</div>
