<div class="flex flex-column gap-4 withdraw-content">
  <app-panel
    [header]="'How to withdraw?'"
    [text]="'Select the currency from which you want to withdraw money in the wallet. Go to the Withdraw tab, in the recipient address field, enter the address to which you want to withdraw money. In the amount field, enter the amount you want to withdraw and click the Withdraw button.'"
  ></app-panel>

  <div>
    <form class="flex flex-column gap-4 align-items-start" [formGroup]="withdrawForm" (ngSubmit)="withdraw()">
      <div [ngClass]="{'no-qr': !isQrReaderVisible}" class="qr-code">
        <app-qr-reader class="animate" style="width: 100%" *ngIf="isQrReaderVisible" (qrReaderValue)="readScannerValue($event)"></app-qr-reader>
      </div>
      <div class="withdraw-form-parent">
        <div class="withdraw-input-wrap">
          <div class="withdraw-address-input-icons">
            <i class="fa fa-clipboard withdraw-address-icon" (click)="paste()"></i>
            <i class="pi pi-qrcode withdraw-address-icon" (click)="isQrReaderVisible = !isQrReaderVisible"></i>
          </div>
          <casino-input class="account-content__item"
                        [width]="'100%'"
                        [readonly]="'true'"
                        [label]="'Withdraw Address'"
                        [placeholder]="'Withdraw Address'"
                        [value]="withdrawForm.value.withdrawAddress"
          ></casino-input>
        </div>
      </div>

      <div class="withdraw-form-parent">
        <div class="withdraw-input-wrap">
          <div
            class="withdraw-max"
            i18n="@@WithdrawMaxButton"
            (click)="getMax(currentWallet.balance)">
            MAX
          </div>
          <casino-input-number
            [width]="'100%'"
            [label]="currentCurrency?.code"
            [min]="0"
            [minFractionDigits]="0"
            [maxFractionDigits]="6"
            [value]="withdrawForm.value.cryptoCurrency"
            (onValueChange)="convertFromCrypto($event)"
          ></casino-input-number>
        </div>
      </div>

      <div class="withdraw-form-parent">
        <div class="withdraw-input-wrap">
          <div
            class="withdraw-max"
            i18n="@@WithdrawMaxButton"
            (click)="getMax(currentWallet.balance)">
            MAX
          </div>
          <casino-input-number
            [width]="'100%'"
            [label]="currentFiatCode | uppercase"
            [min]="0"
            [minFractionDigits]="0"
            [maxFractionDigits]="6"
            [value]="withdrawForm.value.fiat"
            (onValueChange)="convertFromFiat($event)"
          ></casino-input-number>
        </div>
      </div>

      <div class="text-btn">
        <button class="inner-btn">Withdraw</button>
      </div>
    </form>
  </div>
</div>
