import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {UtilService} from '../../services/util.service';
import {countries} from '../../util/countries';
import {CHANGE_PASSWORD_SUCCESS, REQUEST_ERROR} from '../../../../locale/multilingual-strings-constants';
import {Response} from '../../model/response.model';
import {NotifierService} from '../../services/notifier.service';
import {PermissionsEnum} from '../../permissions.enum';
import {AuthService} from '../../services/auth.service';
import {DeviceDetectorService} from '../../services/device-detector.service';
import {HttpIdentityService} from '../../../../../libs/submodules/identity/src/lib/identity-client.service';

@Component({
  selector: 'account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSettingsComponent implements OnInit {
  public genders: { name: string }[];
  public countries = countries;
  public changePasswordErrorMessages: string[] = [];
  public profileErrorMessages: string[] = [];
  public isMobileView: boolean;
  public hasProfileEditPermission = false;
  public hasProfileViewPermission = false;
  public profileForm: FormGroup;
  public changePasswordForm: FormGroup;
  public contactsForm : FormGroup;

  public selectedCountry: string;
  public countryCode: string;

  public constructor(
    @Inject(LOCALE_ID) private locale: string,
    private httpIdentityService: HttpIdentityService,
    private utilService: UtilService,
    private authService: AuthService,
    private notifierService: NotifierService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private detectorService: DeviceDetectorService
  ) {
  }

  public ngOnInit(): void {
    this.initDefaultFormGroups();
    this.initializePermissions();

    this.isMobileView = this.detectorService.isMobile();
    this.genders = this.utilService.getGenders();
    this.httpIdentityService.getProfileData().subscribe({
      next: res => {
        this.profileForm = this.utilService.initProfileFormGroup(res.value);
        if (!this.hasProfileEditPermission) {
          this.profileForm.disable();
        }
        this.cdr.detectChanges();
      },
      error: err => console.error(err),
    });
  }

  private initDefaultFormGroups() {
    this.profileForm = this.formBuilder.group({
      firstName: [null, [Validators.maxLength(50)]],
      lastName: [null, [Validators.maxLength(50)]],
      gender: [null],
      address: [null, [Validators.maxLength(100)]],
      country: [null],
      city: [null, [Validators.maxLength(50)]],
      zipCode: [null, [Validators.maxLength(10)]],
      dateOfBirth: [null],
      passportId: [null, [Validators.maxLength(20)]],
    });

    this.contactsForm =  new FormGroup({
      email: new FormControl(''),
      phoneNumber: new FormControl(''),
    });

    this.changePasswordForm = this.formBuilder.group({
      confirmPassword: ['', Validators.required],
      currentPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern(/^(?=.*[!@#$%^&*(),.?:{}|<>-])(?=.*[0-9])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*(),.?:{}|<>-]{8,}$/)]],
    }, {validator: this.passwordMatchValidator()});

  }

  public onCountryChange(event: string) {
    this.selectedCountry = event;
    this.updatePhoneNumberCountryCode();

  }

  private initializePermissions(): void {
    this.hasProfileEditPermission = this.authService.hasPermission(PermissionsEnum.profile_edit);
    this.hasProfileViewPermission = this.authService.hasPermission(PermissionsEnum.profile_read);
  }

  private updatePhoneNumberCountryCode() {
    this.countryCode = <string>countries.find(country => country.code === this.selectedCountry)?.phoneCode;
    const phoneNumberControl = this.profileForm.get('phoneNumber');

    if (this.countryCode && phoneNumberControl) {
      phoneNumberControl.setValue(this.countryCode + phoneNumberControl.value);
    }
  }

  public saveProfile() {
    if (this.profileForm.valid) {
      this.httpIdentityService.saveProfileData(this.profileForm.value).subscribe({
        next: res => {
          if (!res.succeed) {
            this.profileErrorMessages = res.errors;
            this.cdr.detectChanges()
          }
        },
        error: err => {
          this.profileErrorMessages = [REQUEST_ERROR[this.locale]];
          this.cdr.detectChanges()
          console.error(err)
        },
      });
    } else {
      this.profileForm.markAllAsTouched();
    }
  }

  public saveContacts() { // todo
    console.log(this.contactsForm.value);
    // this.httpDataService.save***(this.contactsForm.value);
  }

  private handleSuccessfulChangePassword(): void {
    this.notifierService.handleSuccessRequest(CHANGE_PASSWORD_SUCCESS);
    this.changePasswordForm.reset();
    this.changePasswordErrorMessages = [];
  }

  private handleFailedChangePassword(errors: string[]): void {
    this.notifierService.handleErrors(errors);
    this.changePasswordErrorMessages = errors;
  }

  public changePassword() {
    if (this.changePasswordForm.valid) {
      this.httpIdentityService.changePassword(this.changePasswordForm.value).subscribe({
        next: (res: Response<null>) => {
          if (res.succeed) {
            this.handleSuccessfulChangePassword()
          } else {
            this.handleFailedChangePassword(res.errors)
          }
        },
        error: err => {
          this.changePasswordErrorMessages = [REQUEST_ERROR[this.locale]];
          console.error(err);
        },
        complete: () => this.cdr.detectChanges()
      });
    } else {
      this.changePasswordForm.markAllAsTouched();
    }
  }

  private passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const newPassword = control.get('newPassword')!;
      const confirmPassword = control.get('confirmPassword')!;

      if (!!confirmPassword.value && newPassword.value !== confirmPassword.value) {
        return {'passwordMismatch': true};
      }
      return null;
    };
  }
}
