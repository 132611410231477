import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CurrencyChangeService} from '../../services/currency-change.service';
import {Currency} from '../../model/account/currency.model';
import {WalletModel} from '../../model/wallet/user-wallet/wallet.model';
import {UtilService} from '../../services/util.service';
import {CurrencyConvertService} from '../../services/currency-convert.service';
import {switchMap, zip} from 'rxjs';
import {HttpDataService} from '../../services/http/http-data.service';
import {map} from 'rxjs/operators';
import {GAME_CURRENCY_ICONS_BG, LOCAL_STORAGE_GAME_CURRENCY} from '../../constants';
import {AuthService} from '../../services/auth.service';
import {PermissionsEnum} from '../../permissions.enum';

@Component({
  selector: 'wallets',
  templateUrl: './wallets.component.html',
  styleUrls: ['./wallets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WalletsComponent implements OnInit {
  public currentCurrency!: Currency;
  public gameCurrency!: Currency;
  public currentWallet!: WalletModel | null;
  public rate: number;
  public rates: { [currencyCode: string]: number } = {};
  public currenciesBarVisible: boolean;
  public isCrypto = true;
  public mainCurrencies: Currency[];
  public selectedMainCurrency: Currency;
  public wallets: WalletModel[];
  public transactionsBarVisible: boolean;
  public transactionsSettingsBarVisible: boolean;
  public walletSettingsBarVisible: boolean;
  public gameCurrencies: Currency[];
  public faqBarVisible: boolean;
  public transactionTypes: { id: number, name: string }[];

  @Output() public newItemEvent = new EventEmitter<string>();
  public readonly gameCurrencyIconsBG = GAME_CURRENCY_ICONS_BG;

  public constructor(private currencyChangeService: CurrencyChangeService,
                     private cdr: ChangeDetectorRef,
                     private utilService: UtilService,
                     private currencyConvertService: CurrencyConvertService,
                     private httpDataService: HttpDataService,
                     private authService: AuthService,
  ) {
  }

  public ngOnInit() {
    this.transactionTypes = this.utilService.getTransactionTypes();
    this.getCurrentCurrency();
    this.getGameCurrency();
    if (this.authService.hasPermission(PermissionsEnum.wallet_manage)) {
      this.getCurrentWalletPeriodically();
    }
    zip(
      this.utilService.getMainCurrencies(),
      this.utilService.getGameCurrencies(),
      this.httpDataService.getWallets(),
      this.httpDataService.getCurrencies(),
    ).subscribe({
      next: ([mainCurrencies, gameCurrencies, walletsRes, currenciesRes]) => {
        this.wallets = walletsRes.value.items as WalletModel[];
        this.wallets.forEach(w => {
          const currency = currenciesRes.value.find(c => c.id === w.currencyId);
          if (currency) {
            w.currency = currency;
          }
        });
        this.mainCurrencies = mainCurrencies as Currency[];
        this.gameCurrencies = gameCurrencies as Currency[];
        this.initRates();
        this.loadSelectedMainCurrency();
        this.loadSelectedGameCurrency();
        this.cdr.detectChanges();
      }
    });
  }

  private loadSelectedMainCurrency(): void {
    const selectedMainCurrencyId = this.utilService.getSelectedMainCurrency();
    this.onMainCurrencyChange(selectedMainCurrencyId as string);
  }

  private loadSelectedGameCurrency(): void {
    const selectedGameCurrencyId = this.utilService.getSelectedGameCurrency();
    this.onGameCurrencyChange(selectedGameCurrencyId as string);
  }

  public onMainCurrencyChange(currencyId: string) {
    this.selectedMainCurrency = this.utilService.updateSelectedMainCurrency(currencyId, this.mainCurrencies);
  }

  private getCurrentCurrency() {
    this.currencyChangeService.isMainCurrencyChange$
      .subscribe({
        next: currency => {
          if (this.authService.hasPermission(PermissionsEnum.currencies_read)
          && this.authService.hasPermission(PermissionsEnum.wallet_manage)) {
            this.currentCurrency = currency;
            this.getCurrentWallet();
            if (this.gameCurrency) {
              localStorage.setItem(LOCAL_STORAGE_GAME_CURRENCY, this.gameCurrency.id);
              this.currencyChangeService.notifyFiat(this.gameCurrency);
            }
            this.cdr.detectChanges();
          }
        },
        error: error => console.error(error),
      });
  }

  private getGameCurrency() {
    this.currencyChangeService.isFiatCurrencyChange$.pipe(
      switchMap((gameCurrency: Currency) => {
        this.gameCurrency = gameCurrency;
        this.cdr.detectChanges();
        return this.currencyConvertService.getCurrencyData(this.gameCurrency.code, this.currentCurrency.code);
      })
    ).subscribe({
      next: data => {
        const obj = data.value[`${this.currentCurrency.code.toUpperCase()}`];
        const num = obj ? (obj[this.gameCurrency.code.toUpperCase()] as number) : null;
        if (num) {
          this.rate = (data.value && num) ? +num.toFixed(6) : 0;
          this.cdr.detectChanges();
        }
      },
      error: error => console.error(error),
    });
  }

  private getCurrentWalletPeriodically() {
    this.utilService.getCurrentWalletPeriodically().subscribe({
      next: currentWallet => {
        this.currentWallet = currentWallet;
        this.cdr.detectChanges();
      },
      error: err => console.error(err),
    });
  }

  private getCurrentWallet() {
    this.utilService.getCurrentWallet().subscribe({
      next: currentWallet => {
        this.currentWallet = currentWallet;
        this.cdr.detectChanges();
      },
      error: err => console.error(err),
    });
  }

  public openExternalLink(url: string) {
    window.open(url, '_blank');
  }

  public openCurrenciesBar() {
    this.currenciesBarVisible = true;
  }

  public openTransactionsBar() {
    this.transactionsBarVisible = true;
  }

  public openWalletSettingsBar() {
    this.walletSettingsBarVisible = true;
  }

  public openFAQBar() {
    this.faqBarVisible = true;
  }

  public onGameCurrencyChange(currencyId: string) {
    this.gameCurrency = this.utilService.updateSelectedGameCurrency(currencyId, this.gameCurrencies);
  }

  public changeMainCurrency(mainCur: Currency) {
    this.utilService.updateSelectedMainCurrency(mainCur.id, this.mainCurrencies);
    this.currenciesBarVisible = false;
  }

  private initRates() {
    zip(...[...this.mainCurrencies, ...this.gameCurrencies].map(c => this.currencyConvertService.getCurrencyData(this.gameCurrency.code, c.code)
      .pipe(
        map(data => ({data, currency: c})),
      )))
      .subscribe({
        next: (results: any[]) => {
          for (const res of results) {
            const obj = res.data.value[`${this.currentCurrency.code.toUpperCase()}`];
            const num = obj ? (obj[this.gameCurrency.code.toUpperCase()] as number) : null;
            if (num) {
              const rate = (res.data.value && num) ? +num.toFixed(6) : 0;
              this.rates[res.currency.code] = rate;
            }
          }
          this.cdr.detectChanges();
        },
        error: error => console.error(error),
      });
  }
}
