import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {GameCategoryModel} from '../../model/game/game-category.model';
import {CategoryTypeEnum} from '../../model/category-type.enum';
import {map, Observable} from 'rxjs'
import {HttpDataService} from '../../services/http/http-data.service';
import {catchError} from 'rxjs/operators';
import {NavigationService} from '../../services/navigation.service';
import {CarouselResponsiveOptions} from 'primeng/carousel/carousel.interface';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'providers-slider',
  templateUrl: './providers-slider.component.html',
  styleUrls: ['./providers-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProvidersSliderComponent implements OnInit {
  @Input() public isMobileView = false;
  @Input() public isVisible: boolean;
  @Output() public isVisibleChange = new EventEmitter<boolean>();
  public providers$: Observable<GameCategoryModel[]> = new Observable<GameCategoryModel[]>();
  public selectedProvider = '';
  public responsiveOptions: CarouselResponsiveOptions[] = [
    {
      breakpoint: '2000px',
      numVisible: 8,
      numScroll: 8,
    },
    {
      breakpoint: '1860px',
      numVisible: 7,
      numScroll: 7,
    },
    {
      breakpoint: '1665px',
      numVisible: 6,
      numScroll: 6,
    },
    {
      breakpoint: '1480px',
      numVisible: 5,
      numScroll: 5,
    },
    {
      breakpoint: '1300px',
      numVisible: 4,
      numScroll: 4,
    },
    {
      breakpoint: '1110px',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '920px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '730px',
      numVisible: 1,
      numScroll: 1,
    },
  ];
  public responsiveOptionsForMobile: CarouselResponsiveOptions[] = [
    {
      breakpoint: '1100px',
      numVisible: 8,
      numScroll: 3,
    },
    {
      breakpoint: '1000px',
      numVisible: 7,
      numScroll: 3,
    },
    {
      breakpoint: '820px',
      numVisible: 6,
      numScroll: 3,
    },
    {
      breakpoint: '700px',
      numVisible: 5,
      numScroll: 3,
    },
    {
      breakpoint: '580px',
      numVisible: 4,
      numScroll: 3,
    },
    {
      breakpoint: '480px',
      numVisible: 3,
      numScroll: 2,
    },
    {
      breakpoint: '380px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '210px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  constructor(
    private navigationService: NavigationService,
    private httpDataService: HttpDataService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.updateSelectedProviderFromParams();

    this.providers$ = this.httpDataService.getAllCategories().pipe(
      map(allCategories => {
        return allCategories.value.filter((category: GameCategoryModel) => category.categoryType === CategoryTypeEnum.GameProvider)
          .map((category: GameCategoryModel) => {
            category.url = `assets/casino/categories/${category.name.toLowerCase()}.svg`;
            category.urlActive = `assets/casino/categories/${category.name.toLowerCase()}_active.svg`;
            return category;
          });
      }),
      catchError(error => {
        console.error('Error fetching providers:', error);
        return [];
      })
    );
    this.cdr.detectChanges();
  }

  private updateSelectedProviderFromParams() {
    this.route.params.subscribe(value => {
      this.selectedProvider = value['category']?.toString();
      this.cdr.detectChanges();
    })
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selectedProvider = event.urlAfterRedirects.slice(1);
        this.cdr.detectChanges();
      }
    });
  }

  public filterGamesByCategory(categoryName: string) {
    this.navigationService.navigateToHome(categoryName).then(() => {
      if (this.isMobileView) {
        this.isVisible = false;
        this.isVisibleChange.emit(this.isVisible);
      }
    });
  }
}
