export enum NavigationEnum {
  users,
  usersByCreator,
  hostedServices,
  games,
  providers,
  roles,
  organizations,
  messages,
}
