<div class="transactions-wrapper">
  <div *ngIf="transactionForm.controls['transactionType1'].value" class="flex flex-column gap-2">
    <div class="transactions-title">{{'Wallet transactions' | uppercase}}</div>
    <div class="transactions-table">
      <p-table [value]="transactionWalletRecords" [tableStyle]="{ 'min-width': '30rem' }">
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let header of transactionWalletHeaders; trackBy: trackByHeader">{{header}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record>
          <tr>
            <td>
              <i class="pi pi-copy" [cdkCopyToClipboard]="record.transactionId"></i>
            </td>
            <td>{{ record.transactionType }}</td>
            <td>{{ record.balance.value }}</td>
            <td>{{ record.amount.value }}</td>
            <td>{{ record.dateCreated.toLocaleString() }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5">No transaction in your history to display yet</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div *ngIf="transactionForm.controls['transactionType2'].value" class="flex flex-column gap-2">
    <div class="transactions-title">{{'Crypto transactions' | uppercase}}</div>
    <div class="transactions-table">
      <p-table [value]="transactionCryptoRecords" [tableStyle]="{ 'min-width': '30rem' }">
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let header of transactionCryptoHeaders; trackBy: trackByHeader">{{header}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record>
          <tr>
            <td>
              <i class="pi pi-copy" [cdkCopyToClipboard]="record.transactionId"></i>
            </td>
            <td>{{ record.transactionType }}</td>
            <td>{{ record.balance.value }}</td>
            <td>{{ record.amount.value }}</td>
            <td>{{ record.dateCreated.toLocaleString() }}</td>
            <td>{{ record.transaction }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6">No transaction in your history to display yet</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div *ngIf="transactionForm.controls['transactionType3'].value" class="flex flex-column gap-2">
    <div class="transactions-title">{{'Transfer transactions' | uppercase}}</div>
    <div class="transactions-table">
      <p-table [value]="transactionTransferRecords" [tableStyle]="{ 'min-width': '30rem' }">
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let header of transactionTransferHeaders; trackBy: trackByHeader">{{header}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record>
          <tr>
            <td>
              <i class="pi pi-copy" [cdkCopyToClipboard]="record.transactionId"></i>
            </td>
            <td>{{ record.fromUserName }}</td>
            <td>{{ record.toUserName }}</td>
            <td>{{ record.amount.value }}</td>
            <td>{{ record.dateCreated.toLocaleString() }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5">No transaction in your history to display yet</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div *ngIf="transactionForm.controls['transactionType4'].value" class="flex flex-column gap-2">
    <div class="transactions-title">{{'Casino transactions' | uppercase}}</div>
    <div class="transactions-table">
      <p-table [value]="transactionCasinoRecords" [tableStyle]="{ 'min-width': '30rem' }">
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let header of transactionCasinoHeaders; trackBy: trackByHeader">{{header}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record>
          <tr>
            <td>
              <i class="pi pi-copy" [cdkCopyToClipboard]="record.transactionId"></i>
            </td>
            <td>{{ record.gameName }}</td>
            <td>{{ record.balance.value }}</td>
            <td>{{ record.amount.value }}</td>
            <td>{{ record.dateCreated.toLocaleString() }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5">No transaction in your history to display yet</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div *ngIf="transactionForm.controls['transactionType5'].value" class="flex flex-column gap-2">
    <div class="transactions-title">{{'Referral system transactions' | uppercase}}</div>
    <div class="transactions-table">
      <p-table [value]="transactionReferralSystemRecords" [tableStyle]="{ 'min-width': '30rem' }">
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let header of transactionReferralSystemHeaders; trackBy: trackByHeader">{{header}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record>
          <tr>
            <td>
              <i class="pi pi-copy" [cdkCopyToClipboard]="record.transactionId"></i>
            </td>
            <td>{{ record.transactionType }}</td>
            <td>{{ record.userName }}</td>
            <td>{{ record.balance.value }}</td>
            <td>{{ record.amount.value }}</td>
            <td>{{ record.dateCreated.toLocaleString() }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6">No transaction in your history to display yet</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-sidebar
  #transactionSettings
  class="filter-transactions-bar"
  [style]="{height: '100%', borderRadius: '10px'}"
  [modal]="false"
  [visible]="transactionsSettingsBarVisible"
  (visibleChange)="onSettingsVisibleChange($event)"
  position="bottom"
>
  <ng-template pTemplate="closeicon">
    <i class="pi pi-arrow-left"></i>
  </ng-template>
  <ng-template pTemplate="content">
    <form [formGroup]="transactionForm" (ngSubmit)="filterTransactions()">
      <div class="flex flex-column gap-4 tr-settings">
        <div class="flex flex-column gap-4 align-items-start no-user-selection">
          <div style="font-size: 20px">Filter transactions</div>
          <div class="filter-tr-separator"></div>
          <div formGroupName="currencies" class="filter-tr-section flex flex-column gap-3">
            <div class="tr-section-title">{{'CURRENCY'}}</div>
            <div class="flex flex-column gap-1">
              <label class="filter-tr-currency-label" *ngFor="let c of currencies">
                <div class="filter-tr-currency-option" [for]="'filter-tr-curr-' + c.code">
                  <p-checkbox [formControlName]="c.id" [binary]="true" [inputId]="'filter-tr-curr-' + c.code"></p-checkbox>
                  <img width="30px"
                       [src]="'assets/cryptocurrencies/' + (c.code | lowercase) + '.svg'">
                  <div>
                    <span>{{c.name}}</span><span class="filter-tr-currency-code"> ({{c.code}})</span>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div class="filter-tr-separator"></div>
          <div class="filter-tr-section flex flex-column gap-3">
            <div class="tr-section-title">{{ 'FILTER BY DATE' }}</div>
            <div class="flex flex-column gap-4">
              <div class="calendar">
                <casino-date-picker class="account-content__item"
                                    [id]="'from-date'"
                                    [showClear]="true"
                                    [placeholder]="'From Date'"
                                    [label]="'From'"
                                    [value]="transactionForm['from']"
                                    (onValueChange)="transactionForm.controls['from'].setValue($event)">
                </casino-date-picker>
                <casino-date-picker class="account-content__item"
                                    [id]="'to-date'"
                                    [showClear]="true"
                                    [placeholder]="'To Date'"
                                    [label]="'To'"
                                    [value]="transactionForm['to']"
                                    (onValueChange)="transactionForm.controls['to'].setValue($event)">
                </casino-date-picker>
              </div>
            </div>
          </div>
          <div class="filter-tr-separator"></div>
          <div class="filter-tr-section flex flex-column gap-3">
            <div class="tr-section-title">{{'TRANSACTION TYPE'}}</div>
            <div class="flex flex-column gap-4">
              <div class="flex flex-column gap-1">
                <label class="filter-tr-currency-label" *ngFor="let tt of transactionTypes">
                  <div class="filter-tr-currency-option" [for]="'filter-tr-tt-' + tt.name">
                    <p-checkbox
                      [binary]="true"
                      [inputId]="'filter-tr-tt-' + tt.name"
                      [formControlName]="'transactionType' + tt.id"
                    ></p-checkbox>
                    <div>
                      <span>{{tt.name}}</span>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <button
            pButton
            type="submit"
            class="filter-tr-btn">
            Done
          </button>
        </div>
      </div>
    </form>
  </ng-template>
</p-sidebar>
