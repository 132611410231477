<ng-container *ngIf="fg && fcn; else wit">
  <form [formGroup]="fg">
    <div class="app-input">
      <span class="p-float-label">
        <textarea class="app-input__field app-input__field--color"
                  [style]="{width: width}"
                  [ngClass]="{'ng-dirty': touched}"
                  [disabled]="disabled"
                  placeholder="{{placeholder}}"
                  id="{{id}}"
                  rows="{{rows}}"
                  cols="{{cols}}"
                  pInputTextarea
                  formControlName="{{fcn}}"
        ></textarea>
        <label class="app-input__label">{{ label }}</label>
      </span>
      <ng-container *ngIf="validationMessage">
        <small
          id="{{id}}-help"
          class="p-error"
          *ngIf="hasError">
          {{ validationMessage }}
        </small>
      </ng-container>
    </div>
  </form>
</ng-container>

<ng-template #wit>
  <div class="app-input">
    <span class="p-float-label">
      <textarea class="app-input__field app-input__field--color"
                [style]="{width: width}"
                [ngClass]="{'ng-dirty': touched}"
                [disabled]="disabled"
                placeholder="{{placeholder}}"
                id="{{id}}"
                rows="{{rows}}"
                cols="{{cols}}"
                pInputTextarea
                [value]="value ?? ''"
                (change)="onValueChange.emit($event.target.value)"
      ></textarea>
      <label class="app-input__label">{{ label }}</label>
    </span>
    <ng-container *ngIf="validationMessage">
      <small
        id="{{id}}-help"
        class="p-error"
        *ngIf="hasError">
        {{ validationMessage }}
      </small>
    </ng-container>
  </div>
</ng-template>
