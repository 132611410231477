<div style="height: 100%; box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);">
  <p-table [style]="{height: '100%'}" #table
           [value]="value"
           [lazy]="lazy"
           [paginator]="paginator"
           [paginatorDropdownAppendTo]="'body'"
           [(first)]="first"
           [totalRecords]="totalRecords"
           [rowHover]="true"
           [rows]="rowsLimit"
           [virtualScroll]="virtualScroll"
           [virtualScrollOptions]="virtualScrollOptions"
           [virtualScrollItemSize]="60"
           [rowsPerPageOptions]="rowsPerPageOptions"
           [showCurrentPageReport]="showCurrentPageReport"
           [filterDelay]="filterDelay"
           [globalFilterFields]="globalFilterField"
           [selectionMode]="selectionMode"
           (onPage)="onPageEvent($event)"
           (onLazyLoad)="onLazyLoadEvent($event)"
           (onRowSelect)="onRowSelectEvent($event)"
           rowGroupMode="subheader"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">

    <ng-container *ngIf="globalFilterField.length">
      <ng-template pTemplate="caption">
        <div style="text-align: left">
          <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
          <input type="text"
                 pInputText
                 size="50"
                 placeholder="{{globalFilterPlaceholder}}"
                 style="width:auto"
                 [(ngModel)]=filterValue
                 (input)="table.filterGlobal(filterValue, globalFilterMatchMode)">
        </div>
      </ng-template>
    </ng-container>

    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let field of fields">
          <th class="searchable-field" pSortableColumn="{{field.name}}">
            <div class="th-content">
              <div class="left-part">
                {{field.displayName}}
                <p-sortIcon *ngIf="field.sortable" field="{{field.name}}"></p-sortIcon>
                <p-columnFilter *ngIf="field.searchable" type="text" field="{{ field.name }}"
                                display="menu">
                </p-columnFilter>
              </div>
            </div>
          </th>
        </ng-container>
        <ng-container *ngFor="let additionalField of additionalFields">
          <th>
            <div class="th-content">
              <div class="left-part">
                {{additionalField.displayName}}
              </div>
            </div>
          </th>
        </ng-container>
        <th *ngIf="hasMenuPermission">Actions</th>
      </tr>

      <ng-container *ngIf="loading">
        <tr class="tr" *ngFor="let _ of [1, 2, 3, 4, 5]">
          <td class="empty-cell" *ngFor="let _ of fields">
            <ng-container *ngTemplateOutlet="loadingSkeleton"></ng-container>
          </td>
          <td class="empty-cell" *ngFor="let _ of additionalFields">
            <ng-container *ngTemplateOutlet="loadingSkeleton"></ng-container>
          </td>
          <td class="empty-cell" *ngIf="hasMenuPermission">
            <ng-container *ngTemplateOutlet="loadingSkeleton"></ng-container>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="groupheader" let-customer>
      <ng-content select="[SubHeader]"></ng-content>
    </ng-template>

    <ng-template pTemplate="body" let-row>
      <tr *ngIf="selectionMode && !loading" [pSelectableRow]="row"
          [ngStyle]="{'background-color': row['greenBGColor'] ? 'rgba(167, 255, 94, 0.3)' : row['redBGColor'] ? 'rgba(255, 0, 0, 0.2)': null}">
        <ng-container *ngTemplateOutlet="tableBody; context: { $implicit: row }"></ng-container>
      </tr>
      <tr *ngIf="!selectionMode && !loading"
          [ngStyle]="{'background-color': row['greenBGColor'] ? 'rgba(167, 255, 94, 0.3)' : row['redBGColor'] ? 'rgba(255, 0, 0, 0.2)': null}">
        <ng-container *ngTemplateOutlet="tableBody; context: { $implicit: row }"></ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <tr class="tr" *ngFor="let _ of loadingRows">
        <td class="empty-cell" *ngFor="let _ of fields">
          <ng-container *ngTemplateOutlet="loadingSkeleton"></ng-container>
        </td>
        <td class="empty-cell" *ngFor="let _ of additionalFields">
          <ng-container *ngTemplateOutlet="loadingSkeleton"></ng-container>
        </td>
        <td class="empty-cell" *ngIf="hasMenuPermission">
          <ng-container *ngTemplateOutlet="loadingSkeleton"></ng-container>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr *ngIf="!loading && !loadingRows.length">
        <td colspan="8">No Data Available</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-tieredMenu #menu
              [popup]="true"
              [appendTo]="'body'"
              [model]="menuItems"></p-tieredMenu>

<ng-template #tableBody let-row>
  <ng-container *ngFor="let field of fields">
    <ng-container *ngIf="!field.isCopy && !field.progress">
      <td *ngIf="!field.type" class="app__table-cell">
        <ng-container *ngIf="!field.flagIcon; else flagContainer">
          <ng-container *ngIf="row[$any(field).contentConditionField]===true">
            <span style="color: #25a62b;" class="app-table-content" [innerHTML]="content"></span>
          </ng-container>
          <ng-container *ngIf="row[$any(field).contentConditionField]===false">
            <span style="color: #a6860c;" class="app-table-content" [innerHTML]="secondaryContent"></span>
          </ng-container>
          <ng-container *ngIf="!field.secondary || row['secondaryFieldsLoaded']; else loadingSkeleton">
            <ng-container *ngIf="row[field.name] !== null && row[field.name] !== undefined ; else emptyDiv">
              {{ row[field.name] }}
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-template #flagContainer>
          <ng-container *ngIf="!field.secondary || row['secondaryFieldsLoaded']; else loadingSkeleton">
            <span style="width: 100%; height: 22px;" [pTooltip]="row[field.name]?.countryName"
                  [class]="row[field.name]?.flagIcon"></span>
          </ng-container>
        </ng-template>
      </td>

      <ng-container *ngIf="field.type == fieldTypesEnum.date">
        <ng-container *ngIf="!field.secondary || row['secondaryFieldsLoaded']; else loadingCell">
          <td *ngIf="!field.showTimeTooltip" class="app__table-cell">
            {{ row[field.name] | date }}
          </td>
          <td *ngIf="field.showTimeTooltip" class="app__table-cell"
              [pTooltip]="(row[field.name] | date:'mediumTime') || undefined" [autoHide]="false" tooltipPosition="left">
            {{ row[field.name] | date }}
          </td>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="field.type == fieldTypesEnum.dateTime">
        <ng-container *ngIf="!field.secondary || row['secondaryFieldsLoaded']; else loadingCell">
          <td class="app__table-cell" tooltipPosition="left">
            {{ row[field.name] | date :'medium'}}
          </td>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="field.type == fieldTypesEnum.object">
        <ng-container *ngIf="row[field.name] && row[field.name]['fieldLoaded']; else loadingCell">
          <td class="app__table-cell">
            {{ row[field.name].value }}
          </td>
        </ng-container>
      </ng-container>

      <td *ngIf="field.type == fieldTypesEnum.boolean" class="app__table-cell">
        <ng-container *ngIf="!field.secondary || row['secondaryFieldsLoaded']; else loadingSkeleton">
          <p-checkbox
            formControlName="{{field.name}}"
            id="field.name"
            [disabled]="true"
            [binary]="true"></p-checkbox>
        </ng-container>
      </td>
    </ng-container>
    <td *ngIf="field.isCopy" class="app__table-cell">
      <button
        #copyButton
        pButton
        type="button"
        class="btn copy-btn"
        (click)="utilService.showCopied(copyButton, copied)"
        [cdkCopyToClipboard]="row[field.name]">
        <i class="pi pi-copy copy-icon"></i>
      </button>
      <div
        #copied
        class="copied-btn">
        <i class="pi pi-check"></i>
      </div>
    </td>

    <td *ngIf="field.progress" class="app__table-cell">
      <p-progressBar [value]="row[field.name]">
        <ng-template pTemplate="content" let-value>
          <span>{{value}}</span>
        </ng-template>
      </p-progressBar>
    </td>
  </ng-container>

  <ng-container *ngFor="let additionalField of additionalFields">
    <td *ngIf="additionalField.type == fieldTypesEnum.boolean" class="app__table-cell">
      <p-checkbox
        [(ngModel)]="row['userIsSelected']"
        [disabled]="additionalField.readonly!"
        [binary]="true"
        (onChange)="changeRowData(row, $event)"
      ></p-checkbox>
    </td>
  </ng-container>

  <td *ngIf="hasMenuPermission" class="app__table-cell">
    <ng-container *ngIf="menuItems.length > 1">
      <button
        pButton
        type="button"
        (click)="toggleMenu(menu, $event, row)"
        icon="pi pi-ellipsis-v"></button>
    </ng-container>
    <ng-container *ngIf="menuItems.length === 1">
      <ng-container *ngIf="menuItems[0].icon; else label">
        <button
          pButton
          type="button"
          (click)="onAction(row)"
          icon="pi {{menuItems[0].icon}}"></button>
      </ng-container>
      <ng-template #label>
        <button
          pButton
          type="button"
          (click)="onAction(row)"
        >{{ menuItems[0].label }}
        </button>
      </ng-template>
    </ng-container>
  </td>
</ng-template>

<ng-template #loadingSkeleton>
  <p-skeleton width="6em" styleClass="mb-2"></p-skeleton>
</ng-template>

<ng-template #loadingCell>
  <td class="app__table-cell">
    <ng-container *ngTemplateOutlet="loadingSkeleton"></ng-container>
  </td>
</ng-template>

<ng-template #emptyDiv>
  <div style="height: 38px;"></div>
</ng-template>
