import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LOCAL_STORAGE_REFERRAL_ID} from '../constants';

@Component({
  template: '',
})
export class ReferralRedirectComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    const referralId = this.route.snapshot.paramMap.get('referralId');
    if (referralId) {
      localStorage.setItem(LOCAL_STORAGE_REFERRAL_ID, referralId);
    }
    this.router.navigateByUrl('/home');
  }
}
