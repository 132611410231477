import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Currency} from '../../../model/account/currency.model';
import {Response} from '../../../model/response.model';
import {HttpDataService} from '../../../services/http/http-data.service';
import {of, switchMap} from 'rxjs';
import {UtilService} from '../../../services/util.service';
import {AuthService} from '../../../services/auth.service';
import {PermissionsEnum} from '../../../permissions.enum';
import {WalletModel} from '../../../model/wallet/user-wallet/wallet.model';
import {DepositModel} from '../../../model/wallet/user-wallet/deposit.model';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss'],
})
export class DepositComponent {
  private _currentWallet: WalletModel;
  private _currentCurrency: Currency
  public isQrCodeVisible = true;
  public pendingBalance = 0;  // todo ????S
  @Input() public rate: number;
  @Input() public gameCurrency: Currency;

  @Input()
  set currentCurrency(value: Currency) {
    this._currentCurrency = value;
  }

  get currentCurrency(): Currency {
    return this._currentCurrency;
  }

  @Input()
  public set currentWallet(value: WalletModel) {
    if (this._currentWallet?.id !== value.id) {
      this._currentWallet = value;
      this.onWalletChange();
    }
  }

  public get currentWallet(): WalletModel {
    return this._currentWallet;
  }

  public depositForm = new FormGroup({
    depositAddress: new FormControl(''),
  });

  public constructor(
    private cdr: ChangeDetectorRef,
    private httpDataService: HttpDataService,
    private utilService: UtilService,
    private authService: AuthService,
  ) {
  }

  public onWalletChange() {
    if (this.authService.hasPermission(PermissionsEnum.wallet_deposit)) {
      this.httpDataService.getWalletDeposit(this._currentWallet!.id).pipe(
        switchMap((data: Response<DepositModel>) => {
          if (data.succeed) {
            this.depositForm.value.depositAddress = data.value.address;
            this.cdr.detectChanges();
            return this.httpDataService.getWalletPendingBalance(this._currentWallet!.id);
          }
          return of(null);
        })
      ).subscribe({
        next: (data) => {
          this.pendingBalance = data?.succeed ? data.value : 0;
          this.cdr.detectChanges();
        },
        error: error => console.error(error),
      });
    }
  }

  public showCopied(copy: HTMLElement, copied: HTMLElement) {
    this.utilService.showCopied(copy, copied, 'unset');
  }
}
