import {Component} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {NavigationService} from '../../services/navigation.service';
import {DeviceDetectorService} from '../../services/device-detector.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public isMobileDevice: boolean;

  public constructor(
    private navigationService: NavigationService,
    private authService: AuthService,
    private detectorService: DeviceDetectorService,
  ) {
    this.isMobileDevice = this.detectorService.isMobile();
  }

  public openExternalLink(url: string) {
    window.open(url, '_blank');
  }

  public openRightBar(section: string) {
    const isAuthenticated = this.authService.isAuthenticated();
    this.navigationService.openRightBar(section, isAuthenticated);
  }
}
