<p-accordion [activeIndex]="activePanelIndex ? activePanelIndex : null">
  <p-accordionTab hidden *ngIf="isAuthenticated">
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <img width="22" class="no-user-selection" [src]="'assets/identity/notification_settings.svg'">
        <span class="vertical-align-middle" i18n="@@notificationsHeader">Notifications</span>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div class="settings-content">
        <p i18n="@@notificationsDescription">On this page, you can manage internal and external notifications.</p>
        <div class="notifications-settings-container">
          <div class="notifications-settings-item" i18n="@@notificationsType">Notifications</div>
          <div class="notifications-settings-item" i18n="@@notificationsInternal">Internal</div>
          <div class="notifications-settings-item"></div>
          <div class="notifications-settings-item" i18n="@@notificationsSystem">System notifications</div>
          <div class="notifications-settings-item">
            <p-checkbox (onChange)="onChange($event)" [binary]="true" inputId="binary"></p-checkbox>
          </div>
          <div class="notifications-settings-item">
            <p-checkbox (onChange)="onChange($event)" [binary]="true" inputId="binary"></p-checkbox>
          </div>
          <div class="notifications-settings-item" i18n="@@notificationsPromo">Promo notifications</div>
          <div class="notifications-settings-item">
            <p-checkbox (onChange)="onChange($event)" [binary]="true" inputId="binary"></p-checkbox>
          </div>
          <div class="notifications-settings-item">
            <p-checkbox (onChange)="onChange($event)" [binary]="true" inputId="binary"></p-checkbox>
          </div>
          <div class="notifications-settings-item" i18n="@@notificationsPromoResults">Promo results notifications</div>
          <div class="notifications-settings-item">
            <p-checkbox (onChange)="onChange($event)" [binary]="true" inputId="binary"></p-checkbox>
          </div>
          <div class="notifications-settings-item">
            <p-checkbox (onChange)="onChange($event)" [binary]="true" inputId="binary"></p-checkbox>
          </div>
        </div>
      </div>
    </ng-template>
  </p-accordionTab>

  <p-accordionTab *ngIf="isAuthenticated">
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <img width="22" class="no-user-selection" [src]="'assets/identity/application_settings.svg'">
        <span class="vertical-align-middle" i18n="@@generalSettings">General</span>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div class="settings-content"
           [ngClass]="{ 'account-inputs' : !isMobileView, 'account-inputs__mobile': isMobileView }">
        <div [ngClass]="{ 'account-content' : !isMobileView, 'account-content__mobile': isMobileView }">
          <casino-dropdown
            [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
            [id]="'language-Dropdown'"
            [width]="isMobileView ? '100%' : '250px'"
            [label]="'Language'"
            [options]="languages"
            [placeholder]="'Language'"
            [optionLabel]="'threeLetterISOLanguageName'"
            [optionValueIsObject]="true"
            [itemTemplate]="itemTemplate"
            [objectValue]="selectedLanguage"
            (onValueChange)="onLanguageChange($event)">
            <ng-template #itemTemplate let-item>
              <div class="flex flex-row align-items-center gap-2">
                <div class="flag-container">
                  <img width="20px"
                       class="no-user-selection"
                       [src]="'assets/languages/' + item.twoLetterISOLanguageName + '.png'">
                </div>
                <div class="flex align-items-center gap-2">
                  <div>{{ item.threeLetterISOLanguageName | uppercase }}</div>
                </div>
              </div>
            </ng-template>
          </casino-dropdown>
          <casino-dropdown
            [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
            [id]="'time-zone-Dropdown'"
            [width]="isMobileView ? '100%' : '250px'"
            [label]="'Time zone'"
            [options]="timeZones"
            [placeholder]="'Time zone'"
            [optionLabel]="undefined"
            [optionValue]="undefined"
            [optionValueIsObject]="true"
            [objectValue]="selectedTimeZone"
            (onValueChange)="onTimeZoneChange($event)"
          ></casino-dropdown>
        </div>
      </div>
    </ng-template>
  </p-accordionTab>

  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <img width="22" class="no-user-selection" [src]="'assets/identity/theme.svg'">
        <span class="vertical-align-middle" i18n="@@theme">Theme</span>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div class="settings-content">
        <p i18n="@@customizeInterface">Customize website interface.</p>
        <div [ngClass]="{ 'account-content' : !isMobileView, 'account-content__mobile': isMobileView }">
          <casino-dropdown [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
                           [id]="'theme-Dropdown'"
                           [width]="isMobileView ? '100%' : '250px'"
                           [label]="'Theme'"
                           [options]="themes"
                           [placeholder]="'Theme'"
                           [optionLabel]="'name'"
                           [optionValueIsObject]="true"
                           [objectValue]="selectedTheme"
                           (onValueChange)="onThemeChange($event)"
          ></casino-dropdown>
        </div>
      </div>
    </ng-template>
  </p-accordionTab>

  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <img width="22" class="no-user-selection" [src]="'assets/identity/agreement.svg'">
        <span class="vertical-align-middle" i18n="@@agreement">Agreement</span>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div class="settings-content">
        <div>
          <p i18n="@@readAgreement">
            PLEASE READ THIS PLAYER AGREEMENT CAREFULLY. By using the site, you acknowledge your full understanding and
            acceptance of the terms contained within this Player Agreement, and confirm that you are at least 19 years
            of age.</p>
        </div>
        <div>
          <h4 i18n="@@introduction">1.0 Introduction</h4>
          <h4 i18n="@@definitionsInterpretation">1.01 Definitions and Interpretation</h4>
          <p i18n="@@seeScheduleA">See Schedule A for meanings of certain words and terms used within this Player
            Agreement.</p>

          <h4 i18n="@@introduction">1.02 Introduction</h4>
          <p i18n="@@introductionParagraph">
            We offer to those, who are 19 years of age or older, the opportunity to register for an Account near us and,
            play games offered on the Site.
          </p>
          <p i18n="@@rightToDiscontinue">We have the right at any time and in its sole and absolute discretion to,
            without notice, discontinue or
            limit the sale of games through our site or to modify the manner in which it sells games.</p>
          <h4 i18n="@@rules">1.03 Rules</h4>
          <p i18n="@@gameRules">All games offered on Site are governed by and offered subject to the Rules that apply to
            each particular
            game.</p>

          <h4 i18n="@@registration">2.0 Registration</h4>

          <h4 i18n="@@registrationRequirement">2.01 Registration Requirement</h4>

          <p i18n="@@beforePlaying">Before you are permitted to play any games on the Site, you must be a Registered
            Player.</p>

          <h4 i18n="@@registrationEligibility">2.02 Registration Eligibility</h4>

          <p i18n="@@registeredPlayerEligibility">In order to become a Registered Player, you must be at least 19 years
            of age, not enrolled in a Voluntary
            Self-Exclusion program at any BC gaming facility, not Otherwise Prohibited and able to satisfy any
            requirements which may have from time to time to confirm your identity.</p>

          <h4 i18n="@@trueAccurateCompleteInfo">2.03 True, Accurate and Complete Information</h4>

          <p i18n="@@accountInfoUpdate">You must provide us with accurate and current Account information and
            immediately update your Account
            information with any change to information you previously supplied to us to ensure such information remains
            true, accurate and complete.</p>

          <h4 i18n="@@discretionToRefuse">2.04 Our Absolute Discretion to Refuse, Suspend or Terminate</h4>

          <p i18n="@@rightToTerminate">We have the right at any time and in its sole and absolute discretion to, without
            notice, refuse to grant,
            suspend or terminate your Account if you breach or are suspected of breaching any term of this Player
            Agreement, Rules, Terms of Use, Promotion Conditions or if we are not satisfied that the Registration
            information you provided is true, accurate and current.</p>

          <h4 i18n="@@playerResponsibility">3.0 Player Responsibility</h4>

          <h4 i18n="@@personalUse">3.01 Personal Use</h4>

          <p i18n="@@accountPersonalUse">You may only use your Account for your personal use and are not permitted to
            use your Account or any
            content on Site for any other purpose including business or commercial purposes.</p>

          <h4 i18n="@@interfereGames">3.02 Interfere with Games</h4>

          <p i18n="@@noInterference">You will not interfere or manipulate with the operations of the Computer Systems or
            the normal play of any
            Game, nor will you attempt to do so.</p>

          <h4 i18n="@@gameErrors">3.03 Game Errors</h4>

          <p i18n="@@reportErrors">If you become aware that any Game contains any error, you must report this to us
            immediately. Further, you
            agree not to take advantage of any such error.</p>

          <h4 i18n="@@noPlayCountries">3.04 No Play in countries where gambling restricted</h4>

          <p i18n="@@noPlayCountry">You shall not use your Account or play, purchase, or attempt to purchase any Game
            while you are physically
            located in the country where gambling restricted.</p>

          <h4 i18n="@@prohibitedActivities">3.05 Prohibited Activities</h4>

          <p i18n="@@noEngageProhibited">You will not engage in Prohibited Activities. If you suspect a player is
            engaging in Prohibited Activities,
            you must report this to us immediately. You acknowledge that playing online Games poses inherent risks
            including the risk that other players are engaging in Prohibited Activities. We will not be liable for any
            losses or damages incurred by you due to Prohibited Activities of other players.</p>

          <h4 i18n="@@familiarWithRules">3.06 Responsibility to be Familiar with Rules</h4>

          <p i18n="@@ruleAmendment">We have the right at any time and in its sole and absolute discretion to amend any
            Rules, Terms of Use, and
            Promotion Conditions. It is your responsibility to be familiar with the current Rules, Terms of Use, and
            Promotion Conditions at all times.</p>

          <p i18n="@@acceptanceOfTerms">You acknowledge and agree that your continued use of Site’s constitutes your
            acceptance of the
            terms of the Rules, Terms of Use, Encore Rewards Terms and Conditions and Promotion Conditions in force at
            that time.</p>

          <h4 i18n="@@account">4.0 Account</h4>

          <h4 i18n="@@usernamePassword">4.01 Username, Password</h4>

          <p i18n="@@securityCreateUsername">For your security, you must create your own unique username and password.
            You will be required to enter
            your username and password prior to accessing your Account. It is your responsibility to keep your username
            and password confidential. We are not liable for any access to or use of your Account, including situations
            in which the access was unauthorized or unintended. This includes but is not limited to circumstances where
            you inadvertently or otherwise disclose your username and/or password, when you use Biometric authentication
            functionality (such as fingerprint or face ID) to login to your account or where you use software that
            automatically enters or saves your username and/or password.</p>

          <h4 i18n="@@emailAccount">4.02 Email Account</h4>

          <p i18n="@@associatedEmail">You must provide an email account to be associated with your Account to receive
            email verifications from us
            relating to your Account. It is your responsibility to keep your associated email account secure.</p>

          <h4 i18n="@@oneAccount">4.03 One Account</h4>

          <p i18n="@@singleAccount">You may hold only one (1) Account. Only the Registered Player, acting on his or her
            own behalf, is
            permitted to use the Account.</p>

          <h4 i18n="@@accountFunding">4.04 Account Funding</h4>

          <p i18n="@@sufficientFunds">You must have sufficient funds in your Account in order to complete a
            purchase.</p>

          <h4 i18n="@@accountHolderAuthorizedUser">4.05 Account Holder or Authorized User</h4>

          <p i18n="@@primaryAccountHolder">For all deposit methods involving an issuing financial institution, you must
            be the primary account holder
            or an authorized user recognized by the issuing financial institution. On request, you must provide us with
            such information and documentation as we determine necessary to enable near us to verify your status as the
            primary account holder or authorized user with the issuing financial institution. If you fail to comply with
            these obligations, we may close your Account and you forfeit all rights to the balance in your Account.</p>

          <h4 i18n="@@limitsOnDeposits">4.06 Limits on Deposits</h4>

          <p i18n="@@weeklyDepositLimit">You will not be able to deposit more than the Weekly Deposit Limit.</p>

          <h4 i18n="@@accountBalanceLimit">4.07 Account Balance Limit</h4>

          <p i18n="@@maintainBalanceLimit">You will not be permitted to maintain more than the Account Balance Limit in
            your Account for longer than
            72 hours. If you exceed the Account Balance Limit for longer than 72 hours, we will initiate an Account
            withdrawal on your behalf. We can, from time to time, change the amount of the Account Balance Limit.</p>

          <h4 i18n="@@closing_of_account_by_us">4.08 Closing of Account by us</h4>
          <p i18n="@@closing_of_account_by_us_description">
            We may close your account if it is an Inactive Account. In the event that we close your Account, you must
            provide us with such information and documentation as we determine necessary to enable us to deliver the
            balance of the Account (except any balance from Tokens) to you. We will deliver the balance under five
            dollars ($5.00) only by Direct Deposit. In the event that you do not provide BCLC with such information
            within eight weeks of your Account being closed, you forfeit all rights to the balance in your Account.</p>

          <h4 i18n="@@closing_of_account_by_registered_player">4.09 Closing of Account by Registered Player</h4>
          <p i18n="@@closing_of_account_by_registered_player_description_1">In the event that you wish to close your
            Account, you must provide us with:</p>
          <p i18n="@@closing_of_account_by_registered_player_description_2">a. notice of your intent to close the
            Account in a form determined by us; and</p>
          <p i18n="@@closing_of_account_by_registered_player_direct_deposit">We will deliver balances under five dollars
            ($5.00) only by Direct Deposit.</p>

          <h4 i18n="@@withdrawals">5.0 Withdrawals</h4>

          <h4 i18n="@@account_withdrawals">5.01 Account Withdrawals</h4>
          <p i18n="@@account_withdrawals_description">Withdrawals from your Account will only be made in the following
            circumstances:</p>
          <p i18n="@@account_withdrawals_condition_a">a. as and when you submit a proper request;</p>
          <p i18n="@@account_withdrawals_condition_b">b. upon closure of your Account;</p>
          <p i18n="@@account_withdrawals_condition_c">c. in the event your Account exceeds the Account Balance Limit for
            more than 72 hours.</p>

          <h4 i18n="@@withdrawals_less_than_100k">5.02 Withdrawals less than $100,000</h4>
          <p i18n="@@withdrawals_less_than_100k_description">Withdrawals less than one hundred thousand ($100,000)
            dollars will generally be paid by Direct Deposit.</p>
          <p i18n="@@withdrawals_less_than_100k_payout_location">Payout location.</p>

          <h4 i18n="@@withdrawals_100k_or_more">5.03 Withdrawals of $100,000 or More</h4>
          <p i18n="@@withdrawals_100k_or_more_description">Withdrawals of one hundred thousand ($100,000) dollars or
            greater will be paid by cheque to you in person
            at a designated by us Prize Payout location.</p>

          <h4 i18n="@@refunds_unused_deposits">5.04 Refunds of Unused Deposits</h4>
          <p i18n="@@refunds_unused_deposits_description">Refunds of unused deposits must be returned to the same
            account number. Partial refunds will only occur if
            the Account balance, at the time of the withdrawal request, is less than the original deposit.</p>

          <h4 i18n="@@chargeback">5.05 Chargeback</h4>
          <p i18n="@@chargeback_description">In the event of a Chargeback, we may, in its sole and absolute discretion,
            carry out one or more of the
            following actions:</p>
          <p i18n="@@chargeback_action_a">a. suspend your Account pending resolution of any dispute relating to the
            Chargeback.</p>
          <p i18n="@@chargeback_action_b">b. request information from you regarding the circumstances of the Chargeback
            and provide such information
            to the relevant financial institution for the purposes of resolving any dispute relating to the
            Chargeback;</p>
          <p i18n="@@chargeback_action_c">c. debit your Account with the amount of the Chargeback and if that debit
            results in a negative balance,
            claim from you the amount of such negative balance; and</p>
          <p i18n="@@chargeback_action_d">d. terminate your Account.</p>

          <h4 i18n="@@tokens">6.0 Tokens</h4>

          <h4 i18n="@@acquiring_tokens">6.01 Acquiring Tokens</h4>
          <p i18n="@@acquiring_tokens_description">Periodically, we may offer incentives in the form of Tokens that will
            be credited to your Account.</p>

          <h4 i18n="@@token_use">6.02 Token Use</h4>
          <p i18n="@@token_use_description">Tokens can be used for Site purchases only. Tokens in your Account will be
            applied towards applicable on
            the Site’s purchases before any other funds from your Account.</p>

          <h4 i18n="@@limits_on_token_use">6.03 Limits on Token Use</h4>
          <p i18n="@@limits_on_token_use_description">The Token incentive may be discontinued at our discretion. Tokens
            will expire in accordance with the notice
            period as announced by us.</p>

          <h4 i18n="@@purchasing_games">7.0 Purchasing Games on Site</h4>

          <h4 i18n="@@accuracy_of_purchase">7.01 Accuracy of Purchase and Play Decision</h4>
          <p i18n="@@accuracy_of_purchase_description">You are solely responsible for ensuring that the details of your
            purchase and/or play decisions are correct
            before you submit your purchase and/or play decisions for processing. In the event of any dispute or
            discrepancy regarding any aspect of your purchase and/or play decisions, THE INFORMATION IN THE COMPUTER
            SYSTEMS WILL PREVAIL, AND ONLY THE PURCHASE AND/OR PLAY DECISION RECORDED BY THE COMPUTER SYSTEMS WILL
            PARTICIPATE IN THE GAME.</p>

          <h4 i18n="@@notification_and_receipts">7.02 Notification and Receipts</h4>
          <p i18n="@@notification_and_receipts_description">If your purchase is fully processed by our prior to the
            cut-off time established by us, which said
            processing includes, without limitation, the complete transfer from your Account of the full amount required
            to pay for the purchase, we will issue a Receipt which will show, among other things, the selection(s) and
            the date(s) of the draw(s) or event(s) for which the selection(s) are valid, the amount wagered and a
            receipt ID number. All valid Receipts are deemed to be Tickets under the applicable Rules that govern the
            particular Game.</p>

          <h4 i18n="@@no_cancellation">7.03 No Cancellation of Purchase</h4>
          <p i18n="@@no_cancellation_description">Once you have made a purchase, you may not cancel it.</p>

          <h4 i18n="@@right_to_refuse">7.04 Right to Refuse</h4>
          <p i18n="@@right_to_refuse_description">We reserve the right to, in its sole and absolute discretion, refuse
            any Game purchase, play decision or
            selection for any reason whatsoever.</p>

          <h4 i18n="@@cut_off_times">7.05 Cut-Off Times</h4>
          <p i18n="@@cut_off_times_description">Purchases and/or play decisions will not be accepted or processed after
            the posted cut-off time. We have
            the right to close your Account and pay out any unredeemed funds therein. Any balance from Tokens will not
            be paid out.</p>

          <h4 id="settings-privacy" i18n="@@privacy">8.0 Privacy</h4>
          <p i18n="@@privacy_description">We are committed to protecting your privacy in accordance with the Privacy
            Policy. Your personal
            information will be collected in accordance with the Freedom of Information and Protection of Privacy Act
            and will be used, accessed, disclosed and stored by us, its service providers and Authorized Providers both
            inside and outside of Canada, for the purposes described in the Privacy Policy, as may be updated from time
            to time. By registering for an Account, you agree to the above.</p>

          <h4 i18n="@@breach_of_agreement">9.0 Breach of Agreement</h4>

          <h4 i18n="@@player_breach">9.01 Player Breach</h4>
          <p i18n="@@player_breach_description">In addition to remedies described in this Player Agreement for a breach
            of a specific term or condition, if
            you breach any term or condition of this Player Agreement, Rules, Terms of Use, or Promotion Conditions we
            shall be entitled to take one or more of the following actions as it determines in its sole and absolute
            discretion:</p>
          <p i18n="@@player_breach_actions">a. suspend or terminate your Account;</p>
          <p i18n="@@player_breach_actions">b. seize from your Account an amount of our determines necessary to
            compensate us for its actual or
            potential losses or liabilities resulting from such breach;</p>
          <p i18n="@@player_breach_actions">c. notify any third parties we determine to be appropriate in the
            circumstances, including law enforcement
            agencies; and</p>
          <p i18n="@@player_breach_actions">d. take legal action against you, including the right to claim all legal
            costs and expense in making such
            action.</p>

          <h4 i18n="@@withhold_prizes">9.02 Right to Withhold Prizes</h4>
          <p i18n="@@withhold_prizes_description">
            We have the right at any time and in its absolute discretion to, without notice, withhold any prize from you
            if you breach or are suspected of breaching any term of this Player Agreement, Rules, Terms of Use, or
            Promotion Conditions, including if we are not satisfied that the Registration information you provided is
            true, accurate and current.
          </p>

          <h4 i18n="@@our_errors">10.0 Our Errors</h4>
          <p i18n="@@our_errors_description">
            You acknowledge that a number of circumstances may occur where our error occurs, including where we accept a
            bet on a Game in error, award a prize in error, credits your Account in error or processes a withdrawal from
            your Account in error. Such errors may arise from computer malfunction or human error, including by us or
            Authorized Provider employee or contractor. We reserve the right to take all steps it deems necessary to
            correct such errors. In the event of our pays money to you in error, you agree that you will hold such money
            in trust and return such money on demand to us. You acknowledge and agree that when money paid to you in
            error is subsequently used for a wager, we may cancel such wager and demand the return of any subsequent
            prize as also being held in trust for us.
          </p>

          <h4 i18n="@@limitation_of_liability">11.0 Limitation of Liability</h4>
          <p i18n="@@limitation_of_liability_description">
            Our incurs no liability towards you as a result of a Force Majeure Event. In all other cases, whether the
            liability is contractual or in tort including negligence on its part or that of its employees, our liability
            is limited, if the claim is based on a valid prize claim as determined by us, to the cost of the prize or,
            otherwise, to the net loss of your participation in the Game in question that gave rise to the relevant
            liability, with net loss being the amount of your wagers for that Game less winnings from that Game. You
            agree that BCLC shall not be liable to you under any circumstances for a greater amount.
          </p>

          <h4 i18n="@@general">12.0 General</h4>

          <h4 i18n="@@dispute">12.01 Dispute</h4>
          <p i18n="@@dispute_description">
            In the event of a dispute arising out of a discrepancy between the information displayed on your screen,
            including information about the outcome of a game, and the information recorded in the Computer Systems, the
            information recorded in the Computer Systems will prevail.
          </p>

          <h4 i18n="@@interpretation">12.02 Interpretation</h4>
          <p i18n="@@interpretation_description">
            In this Player Agreement: (a) a definition applies to other forms of the word; (b) the term “including”
            means “including without limiting the generality of the foregoing”; and (c) headings are for convenience of
            reference only and do not affect the interpretation of this Player Agreement.
          </p>

          <h4 i18n="@@governing_law">12.03 Governing Law</h4>
          <p i18n="@@governing_law_description">
            All aspects of your use of Site, including this Player Agreement, are governed by and must be construed in
            accordance with the laws of your country.
          </p>

          <h4 i18n="@@intellectual_property">12.04 Intellectual Property</h4>
          <p i18n="@@intellectual_property_description">
            We are the owner or licensee of the copyright, trademarks and all other intellectual property rights in and
            to all aspects of Site, and the content therein. Notwithstanding anything else on Site, including this
            Player Agreement, Rules, Terms of Use, or Promotion Conditions you acquire no rights in or to any such
            copyright, trademarks or other intellectual property rights.
          </p>

          <h4 i18n="@@entire_agreement">12.05 Entire Agreement</h4>
          <p i18n="@@entire_agreement_description">
            This Player Agreement and the applicable Rules, Terms of Use, and Promotion Conditions constitute the entire
            agreement and understanding between you and we in relation to all aspects of your use of Site.
          </p>

          <h4 i18n="@@enforceability">12.06 Enforceability</h4>
          <p i18n="@@enforceability_description">
            The invalidity or unenforceability of any portion of this Player Agreement shall not affect the validity or
            enforceability of the other portions of the Player Agreement.
          </p>

          <h4 i18n="@@no_assignment">12.07 No Assignment</h4>
          <p i18n="@@no_assignment_description">
            Your Account cannot be assigned or transferred.
          </p>

          <h4 i18n="@@schedule_definitions">Schedule A - Definitions</h4>
          <p i18n="@@schedule_definitions_description">
            "Account" means your personal transactions which are recorded and maintained by us. Your Account is not a
            bank account and is not insured by the CDIC. Any funds deposited to your Account will not earn interest.
          </p>

          <p i18n="@@account_balance_limit">
            "Account Balance Limit" means the maximum value of funds that you can maintain in your Account as determined
            by us and subject to change without notice.
          </p>

          <p i18n="@@authorized_providers">
            "Authorized Providers" means such other gaming entities that are authorized to operate a lottery scheme as
            defined by the Criminal Code and that we collaborate with to offer Games on Site from time to time.
          </p>

          <p i18n="@@chargeback">
            "Chargeback" means a request by a financial institution to us to return funds as a result of a cardholder
            contacting the financial institution to initiate a refund for a payment transaction pertaining to your
            Account that was made using the card or an account associated with the card.
          </p>

          <p i18n="@@computer_systems">
            "Computer Systems" means the computer systems utilized by us for the operation of Games.
          </p>

          <p i18n="@@direct_deposit">
            "Direct Deposit" means an electronic deposit of funds directly into your account.
          </p>

          <p i18n="@@force_majeure_event">
            "Force Majeure Event" means an event that interferes with our performance of its obligations in conducting,
            managing, or operating Games under this Agreement, in whole or in part, from fire, flood, earthquake, or
            other act of God, act of war privacy, or other violence, failure of telecommunications, legislative changes,
            regulatory changes, riots epidemics, or other calamity.
          </p>

          <p i18n="@@inactive_account">
            "Inactive Account" means an account that has had no deposits and no Game purchases during a one-year period.
          </p>

          <p i18n="@@otherwise_prohibited">
            "Otherwise Prohibited" means that an individual has been determined by us in its sole discretion to be
            ineligible to register or play on Site.
          </p>

          <p i18n="@@prohibited_activities">
            "Prohibited Activities" means actual or attempted cheating, collusion, fraud, illegal activity, or any other
            activity deemed inappropriate by our including:
            <br>
            a. game manipulation, such as hedging bets or depositing funds and requesting them to be withdrawn after
            minimal bets are placed;
            <br>
            b. using any automated device or software that makes decisions for you or takes your place as a live player;
            or
            <br>
            c. exploiting errors in the software used in connection with the operation of Site.
          </p>

          <p i18n="@@promotion_conditions">
            "Promotion Conditions" means conditions established by us that apply to promotions offered on Site.
          </p>

          <p i18n="@@receipt">
            "Receipt" means a detailed record of your purchase transaction, which is issued by us at the time a
            transaction is completed and deemed to be a receipt under the applicable Rules that govern the particular
            Game.
          </p>

          <p i18n="@@registered_player">
            "Registered Player" means an individual who has been issued an Account that has not been closed.
          </p>

          <p i18n="@@registration">
            "Registration" means the process whereby you provide information to us and satisfy our requirements, and if
            approved by us, become a Registered Player.
          </p>

          <p i18n="@@token">
            "Token" means game vouchers awarded by us which can be used for Site purchases only and cannot be redeemed
            for cash or paid out from your Account.
          </p>

          <p i18n="@@weekly_deposit_limit">
            "Weekly Deposit Limit" means the maximum amount of funds that you may deposit in any seven (7) day period as
            determined by us and subject to change without notice.
          </p>
        </div>
      </div>
    </ng-template>
  </p-accordionTab>

  <p-accordionTab id="settings-about-tab">
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <img width="22"
             class="no-user-selection"
             [src]="'assets/identity/about.svg?'">
        <span class="vertical-align-middle" i18n="@@about">About</span>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div class="settings-content">
        <p i18n="@@welcomeMessage">
          Dear visitor. Welcome to our website. The site created by players for players. Here you will find table
          games, arcade games, slots games from different providers, draws and ways to earn passive income. In short –
          we give you an opportunity to combine business with pleasure.
        </p>
        <p i18n="@@whatToDo">
          What do we need to do?
        </p>
        <div>
          <ol>
            <li class="about-item" i18n="@@registrationHeader">
              Registration
            </li>
            <p i18n="@@registrationExplanation">
              First of all, you need to register. Registration consists of 2 stages; On the first you enter your email
              (it will be your login) and password. On the second – your data (Name, surname, address, date of birth and
              phone number).
            </p>
            <li i18n="@@depositAndWithdrawal" class="about-item">
              Deposit and withdrawal from the wallet
            </li>
            <ol>
              <li i18n="@@refillWallet">Refill wallet</li>
              <p i18n="@@refillWalletExplanation">
                Go to the “Wallet” tab in your personal account. There you will see currencies that are supported in the
                system and the accounts that the system created for you during registration. The wallet password is the
                password you entered during registration. Please, keep it. If you lose your password, you will lose
                access to your wallet and your funds.
              </p>
              <li i18n="@@withdrawals">Withdrawals</li>
              <p i18n="@@withdrawalsExplanation">
                To withdraw please paste the address of account in the textbox “withdrawal address”, enter amount to the
                textbox “Amount” and press “Withdraw” button.
              </p>
            </ol>
            <li class="about-item" i18n="@@launchMechanisms">
              Launch mechanisms that allow you to earn
            </li>
            <ol>
              <li i18n="@@referralProgram">Referral program</li>
              <p i18n="@@referralProgramExplanation">Do you want to receive a stable income?</p>
              <p i18n="@@referralProgramDetails">
                Invite players. Tell them about the system and send them a link pointed bellow for registration.
                Throughout the time when the players play in our resource, you will receive 1 % of the amount they
                withdraw from the system. If the players you invite bring in new players, you will also profit from
                their earnings. This chain is endless. With the right approach, you can create a system that provides
                you with a stable income in a short time. The system is created by the players – for the players. The
                payout percentage of the system is the highest. The involved players will thank you for it.
              </p>
            </ol>
            <li class="about-item" i18n="@@playing">Playing</li>
            <ol>
              <li i18n="@@bonuses">Bonuses</li>
              <p i18n="@@bonusesExplanation">
                Through the “Magic wheel” are played bonus spins. Winnings that you receive from bonus spins can be
                cashed out. Players who play for real money will be able to spin the wheel after every 12 hours for free
                and win the bonus spins.
              </p>
              <li i18n="@@theGames">The games</li>
              <p i18n="@@theGamesExplanation">
                The system has a lot of games with different options. Choose your favorite game and enjoy it. Some of
                the providers have jackpots. You can play any of the games for free or for real money.
              </p>
              <p i18n="@@goodLuckMessage">
                We wish you a pleasant pastime and good luck in playing games.
              </p>
            </ol>
          </ol>
        </div>
      </div>
    </ng-template>
  </p-accordionTab>
</p-accordion>
