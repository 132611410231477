import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Response} from '../model/response.model';
import {FormGroup} from '@angular/forms';
import {tap} from 'rxjs/operators';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ForgotPasswordComponent} from '../components/forgot-password/forgot-password.component';
import {JwtHelperService} from '@auth0/angular-jwt';
import {UtilService} from './util.service';
import {NavigationService} from './navigation.service';
import {NotifierService} from './notifier.service';
import {LOCAL_STORAGE_AUTH_TOKEN} from '../constants';
import {HttpIdentityService} from '../../../../libs/submodules/identity/src/lib/identity-client.service';
import {TokenModel} from '../../../../libs/submodules/identity/src/lib/models/token.model';

@Injectable()
export class AuthService {
  public ref: DynamicDialogRef | undefined;
  public permissions: string[];
  public userIsAuthorized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public constructor(
    private httpIdentityService: HttpIdentityService,
    private jwtHelper: JwtHelperService,
    private dialogService: DialogService,
    private utilService: UtilService,
    private notifierService: NotifierService,
    private navigationService: NavigationService,
  ) {
    this.jwtHelper = new JwtHelperService();
    this.permissions = this.permissions || this.getPermissionsFromLocalStorage();
  }

  public refreshToken(): Observable<Response<TokenModel>> {
    return this.httpIdentityService.refreshToken();
  }

  public isAuthenticated(): boolean {
    return this.userIsAuthorized$.value;
  }

  public logout(): void {
    if (this.userIsAuthorized$.value) {
      localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN);
      this.resetAccountAuthData(false);
    }
    this.navigationService.navigateToHome().then();
    this.httpIdentityService.logout().subscribe({
      next: () => {
        console.log('logout');
      },
      error: err => this.notifierService.handleRequestError(err)
    })
  }

  public login(data: FormGroup): Observable<Response<any>> {
    return this.httpIdentityService.login(data.value).pipe(
      tap((response) => {
        localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, response.value.accessToken.value || '');
        this.resetAccountAuthData(true);
        this.utilService.currentUserId$.next(this.utilService.getUserIdFromDetails());
      }),
    );
  }

  public register(data: FormGroup) {
    return this.httpIdentityService.register(data.value)
  }

  public showTokenExpiredPopup() {
    this.ref = this.dialogService.open(ForgotPasswordComponent, {
      header: 'Token is expired',
      width: '400px',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      maximizable: true
    });
    this.ref.close();
  }

  public getPermissionsFromLocalStorage(): string[] {
    return UtilService.getAccountPermissions() as string[];
  }

  public hasPermission(permissionName: string, restPermissions: string[] = []): boolean {
    if (!this.permissions) {
      return false;
    }
    return this.permissions.some(item => [permissionName, ...restPermissions].includes(item));
  }

  public resetAccountAuthData(userIsAuthorized: boolean) {
    if (userIsAuthorized) {
      this.permissions = this.getPermissionsFromLocalStorage();
      this.userIsAuthorized$.next(userIsAuthorized);
    } else {
      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, '');
      this.userIsAuthorized$.next(userIsAuthorized);
    }
  }

  public handleHttpResponse(res: any) {
    if (res.ok) {
      res.body = {
        succeed: true,
        value: JSON.parse(JSON.stringify(res.body)),
        errors: [],
      };
    }
    return res;
  }
}
