import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SearchService} from '../../services/search.service';
import {NavigationService} from '../../services/navigation.service';

@Component({
  selector: 'mobile-access-panel',
  templateUrl: './mobile-access-panel.component.html',
  styleUrls: ['./mobile-access-panel.component.scss']
})
export class MobileAccessPanelComponent implements OnInit {
  public isProviderVisible = false;
  public isSearchOpen = false;

  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;

  constructor(
    private searchService: SearchService,
    private navigationService: NavigationService,
  ) {
  }

  public ngOnInit(): void {
    this.navigationService.openProviderSlide$.subscribe(res => {
      this.isProviderVisible = !!res.value;
    });
  }

  public openLeftBar() {
    this.isSearchOpen = false;
    this.navigationService.closeProviderSlide();
    this.navigationService.openLeftBar();
  }

  public openSearch() {
    this.isSearchOpen = !this.isSearchOpen;
    this.navigationService.closeProviderSlide();
    setTimeout(() => {
      this.focusSearchInput();
    }, 0);
  }

  public focusSearchInput() {
    if (this.searchInput && this.searchInput.nativeElement) {
      this.searchInput.nativeElement.blur();
      this.searchInput.nativeElement.focus();
    }
  }

  public searchGame(searchGameName: string) {
    this.searchService.setSearchValue(searchGameName);
    if (searchGameName) {
      const providerNameEls = document.getElementsByClassName('provider-name');
      if (providerNameEls.length) {
        providerNameEls[0].scrollIntoView();
      }
    }
  }

  public openProviderSlide() {
    this.isProviderVisible = !this.isProviderVisible;
    if (this.isProviderVisible) {
      this.isSearchOpen = false;
    }

    if (this.isProviderVisible) {
      this.navigationService.openProviderSlide();
    } else {
      this.navigationService.closeProviderSlide();
    }
  }
}
