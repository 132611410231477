import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {UtilService} from '../../../../shared/services/util.service';
import {GameModel} from '../../../../shared/model/game/game.model';
import {AuthService} from '../../../../shared/services/auth.service';
import {Platform} from '@angular/cdk/platform';
import {NavigationService} from '../../../../shared/services/navigation.service';

@Component({
  selector: 'app-game-card',
  templateUrl: './game-card.component.html',
  styleUrls: ['./game-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameCardComponent implements OnInit {

  @Input() game: GameModel;
  @Output() removeLiked = new EventEmitter<string>();
  public isLiked: boolean;
  public src = '';
  public isMobilView: boolean;
  public show: boolean;

  public constructor(public cdr: ChangeDetectorRef,
                     private utilService: UtilService,
                     private authService: AuthService,
                     private platform: Platform,
                     private navigationService: NavigationService,
  ) {
  }

  public ngOnInit(): void {
    this.isMobilView = this.platform.IOS || this.platform.ANDROID;
    this.show = !this.isMobilView;
    const gameProviderName = this.utilService.getProviderNameById(this.game.gameProviderId).replace(/\s/g, '');
    const gameName = this.game.name.replace(/\s/g, '');
    this.src = `assets/casino/providers/${gameProviderName}/${gameName}/thumbnail.jpg`;
    const key = `Favorite/${this.game.gameProviderId}/${this.game.id}`;
    this.isLiked = localStorage.getItem(key) === 'true';
  }

  public openGame(isDemo: boolean): void {
    if (!this.authService.isAuthenticated() && !isDemo) {
      this.navigationService.showLogin();
    } else {
      this.utilService.openGame(
        this.game,
        isDemo
      );
    }
  }

  public updateLikedGames() {
    const key = `Favorite/${this.game.gameProviderId}/${this.game.id}`;
    localStorage.setItem(key, this.isLiked ? 'false' : 'true');
    this.isLiked = !this.isLiked;
    if (!this.isLiked) {
      this.removeLiked.emit(this.game.id);
    }
  }

  public showButtonsOnTap() {
    if (this.isMobilView && !this.show) {
      this.show = !this.show;
    }
  }
}
