import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorService {
  private isMobileDevice = false;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.detectDeviceType();
  }

  private detectDeviceType(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobileDevice = result.matches;
      });
  }

  isMobile(): boolean {
    return this.isMobileDevice;
  }
}
