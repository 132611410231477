import {ChangeDetectionStrategy, Component} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {DeletePopupDataModel} from '../../model/delete-popup-data-model/delete-popup-data.model';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeletePopup {
  private readonly id: string;
  public data: DeletePopupDataModel[];

  public constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) {
    this.data = config.data.data;
    this.id = config.data.id;
  }

  public isDateField(value: any): boolean {
    if (value instanceof Date) {
      return true;
    }
    if (typeof value === 'string') {
      const date = new Date(value);
      return !isNaN(date.getTime());
    }
    return false;
  }

  public onDelete() {
    this.ref.close({
      id: this.id,
      delete: true,
    });
  }
}
