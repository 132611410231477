<div [style]="{width: isMobileView ? '100%' : '500px'}" [@registrationSlideInAnimation]="'in'" class="registration-container wrapper">
  <div class="scroll">
    <div class="card no-user-selection">
      <div class="close-btn" (click)="closeRegisterPage()">
        <span>
          <i class="pi pi-times"></i>
        </span>
      </div>

      <form [formGroup]="registrationForm" (ngSubmit)="register()" class="action">
        <div class="text-center">
          <h1 i18n="@@RegisterTitle">Register</h1>
        </div>

        <div class="input-box">
          <casino-input [id]="'email'"
                        [width]="'100%'"
                        [type]="'email'"
                        [hasError]="registrationForm.get('email')?.touched && registrationForm.get('email')?.hasError('required')"
                        [label]="'Email'"
                        [placeholder]="'Email'"
                        [validationMessage]="'The Email is required.'"
                        [touched]="registrationForm.get('email')?.touched"
                        [fcn]="'email'"
                        [fg]="registrationForm"
          ></casino-input>
          <small class="p-error block" *ngIf="registrationForm.invalid && registrationForm.get('email')?.touched ">
            <small class="p-error block" *ngIf="registrationForm?.get('email')?.hasError('email')"
                   i18n="@@EmailInvalid">
              Email is invalid
            </small>
          </small>
        </div>

        <div class="input-box">
          <casino-input [id]="'password'"
                        [width]="'100%'"
                        [type]="showPassword ? 'text' : 'password' "
                        [hasError]="registrationForm.get('password')?.touched && registrationForm.get('password')?.hasError('required')"
                        [label]="'Password'"
                        [placeholder]="'Password'"
                        [validationMessage]="'The password is required.'"
                        [touched]="registrationForm.get('password')?.touched"
                        [fcn]="'password'"
                        [fg]="registrationForm"
          ></casino-input>
          <span class="password-toggle-icon" (click)="togglePasswordVisibility()">
            <i [class]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'" id="eye"></i>
          </span>
          <small class="p-error block" *ngIf="registrationForm.invalid && registrationForm.get('password')?.touched ">
            <small class="p-error block" *ngIf="registrationForm?.get('password')?.hasError('pattern')"
                   i18n="@@passwordPatternError">
              The Password field must be a minimum of 8 characters, at least one non-alphanumeric character, one digit
              ('0'-'9'), and one uppercase ('A'-'Z').
            </small>
          </small>
        </div>

        <div class="input-box">
          <casino-dropdown class="account-content__item"
                           [id]="'country-dropdown'"
                           [width]="'100%'"
                           [label]="'Select a country'"
                           [options]="countries"
                           [placeholder]="'Select a country'"
                           [optionLabel]="'name'"
                           [optionValue]="'code'"
                           [value]="registrationForm.controls.country"
                           (onValueChange)="registrationForm.controls.country.setValue($event)"
          ></casino-dropdown>
          <small class="p-error block"
                 *ngIf="registrationForm.get('country')?.touched && registrationForm?.get('country')?.hasError('required')"
                 i18n="@@CountryRequired">
            Country is required
          </small>
        </div>

        <div class="check-boxes">
          <div>
            <input formControlName="acceptTerms" type="checkbox">
            <label i18n="@@AcceptTermsLabel">I have read and I agree with the <a href="#">terms and
              conditions.</a></label>
          </div>
          <div>
            <input formControlName="sendLoginCredentials" type="checkbox">
            <label i18n="@@SendLoginCredentialsLabel">Send login credentials</label>
          </div>
        </div>

        <div class="header-item text-btn">
          <button
            i18n="header login button @@loginButton"
            class="inner-btn"
            type="submit"
          >Register
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
