import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';


@Injectable(
  {
    providedIn:'root'
  }
)
export class LoginService {
  private showLoginPage = false;
  private isLoginPageVisibleSubject = new BehaviorSubject<boolean>(false);

  // Expose the observable property
  public isLoginPageVisible$: Observable<boolean> = this.isLoginPageVisibleSubject.asObservable();

  showLogin() {
    this.isLoginPageVisibleSubject.next(true);
  }

  hideLogin() {
    this.isLoginPageVisibleSubject.next(false);
  }
}
