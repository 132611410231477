import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
} from '@angular/core';
import {zip} from 'rxjs';
import {HttpDataService} from '../../services/http/http-data.service';
import {ThemeService} from '../../services/theme.service';
import {AuthService} from '../../services/auth.service';
import {UtilService} from '../../services/util.service';
import {
  LOCAL_STORAGE_THEME,
  LOCAL_STORAGE_TIME_ZONE,
  SETTINGS_ABOUT_PANEL_INDEX_IN,
  SETTINGS_ABOUT_PANEL_INDEX_OUT,
  SETTINGS_AGREEMENT_PANEL_INDEX_IN,
  SETTINGS_AGREEMENT_PANEL_INDEX_OUT
} from '../../constants';
import {LanguageModel} from '../../model/cultures/language.model';
import {SelectItem} from 'primeng/api';
import * as moment from 'moment-timezone';
import {CheckboxChangeEvent} from 'primeng/checkbox/checkbox.interface';
import {DeviceDetectorService} from '../../services/device-detector.service';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent implements OnInit, AfterViewChecked {
  public timeZones: SelectItem[] = [];
  public selectedTimeZone: string;
  public themes: { theme: string, name: string }[];
  public selectedTheme: { name: string, theme: string } | undefined;
  public languages: LanguageModel[];
  public selectedLanguage: LanguageModel;
  public isMobileView: boolean;

  @Input() public activePanelIndex: number;
  @Input() isAuthenticated: boolean;

  public constructor(
    private authService: AuthService,
    private httpDataService: HttpDataService,
    private themeService: ThemeService,
    private utilService: UtilService,
    private cdr: ChangeDetectorRef,
    private detectorService: DeviceDetectorService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.isMobileView = this.detectorService.isMobile();
  }

  public ngOnInit(): void {
    this.themes = this.utilService.getThemes();

    this.timeZones = this.fetchTimezones();
    this.selectedTimeZone = this.getDefaultTimezone()
    this.loadSavedTheme();
    this.loadSavedTimeZone();
    this.subscribeToTokenChanges();
    zip(this.httpDataService.getCultures()).subscribe({
      next: ([languagesResponse]) => {
        if (languagesResponse.succeed) {
          this.languages = languagesResponse.value;
          const selectedLanguageId = this.utilService.getSelectedLanguage();
          this.selectedLanguage = this.languages.find(l => l.id === selectedLanguageId)!;
        }
        this.cdr.detectChanges();
      }
    })
  }

  private fetchTimezones(): SelectItem[] {
    const timezones = moment.tz.names();
    return timezones.map(zone => ({label: zone, value: zone}));
  }

  private getDefaultTimezone(): string {
    return moment.tz.guess();
  }

  public onLanguageChange(language: LanguageModel) {
    if (language.twoLetterISOLanguageName === this.locale) {
      return;
    }
    this.selectedLanguage = language;
    this.utilService.updateSelectedLanguage(language);
  }

  public onThemeChange(event: any) {
    const theme = event.theme;
    this.themeService.switchTheme(theme);
  }

  public onChange(event: CheckboxChangeEvent) {
    console.log('onChange todo', event);
  }

  public onTimeZoneChange(timeZone: string) {
    this.selectedTimeZone = timeZone;
    if (this.selectedTimeZone) {
      this.utilService.changeTimeZone(timeZone);
      this.cdr.detectChanges();
    }
  }

  public ngAfterViewChecked(): void {
    const isAuthenticated = this.authService.isAuthenticated();

    if (isAuthenticated && this.activePanelIndex === SETTINGS_ABOUT_PANEL_INDEX_IN ||
      !isAuthenticated && this.activePanelIndex === SETTINGS_ABOUT_PANEL_INDEX_OUT) {
      if (document.getElementById('settings-about-tab')) {
        document.getElementById('settings-about-tab')!.scrollIntoView();
      }
    } else if (isAuthenticated && this.activePanelIndex === SETTINGS_AGREEMENT_PANEL_INDEX_IN ||
      !isAuthenticated && this.activePanelIndex === SETTINGS_AGREEMENT_PANEL_INDEX_OUT) {
      if (document.getElementById('settings-privacy')) {
        document.getElementById('settings-privacy')!.scrollIntoView();
      }
    }
  }

  private loadSavedTheme(): void {
    const savedTheme = localStorage.getItem(LOCAL_STORAGE_THEME);
    if (savedTheme) {
      this.selectedTheme = this.themes.find(theme => savedTheme.includes(theme.theme));
    }
  }

  private loadSavedTimeZone(): void {
    const savedTimeZone = localStorage.getItem(LOCAL_STORAGE_TIME_ZONE);
    if (savedTimeZone) {
      this.selectedTimeZone = this.timeZones.find(timeZone => timeZone.value === savedTimeZone)?.value;
    }
  }

  private subscribeToTokenChanges(): void {
    this.authService.userIsAuthorized$.subscribe(() => {
      this.cdr.detectChanges();
    });
  }

}
