import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TransactionRecordModel} from '../../../model/transaction/transaction-record.model';
import {FormControl, FormGroup} from '@angular/forms';
import {HttpDataService} from '../../../services/http/http-data.service';
import {Observable} from 'rxjs';
import {Currency} from '../../../model/account/currency.model';
import {TransactionType} from '../../../model/transaction/transaction.type';
import {UtilService} from '../../../services/util.service';
import {NewResponse} from '../../../model/response';
import {zip} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService} from '../../../services/auth.service';
import {PermissionsEnum} from '../../../permissions.enum';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit {
  public transactionWalletRecords: TransactionRecordModel[] = [];
  public transactionCryptoRecords: TransactionRecordModel[] = [];
  public transactionTransferRecords: TransactionRecordModel[] = [];
  public transactionCasinoRecords: TransactionRecordModel[] = [];
  public transactionReferralSystemRecords: TransactionRecordModel[] = [];
  public transactionTypes: { id: number, name: string }[];
  public transactionWalletHeaders = ['#', 'Transaction Type', 'Balance', 'Amount', 'Created'];
  public transactionCryptoHeaders = ['#', 'Transaction Type', 'Balance', 'Amount', 'Created', 'Transaction'];
  public transactionTransferHeaders = ['#', 'From User Name', 'To User Name', 'Amount', 'Created'];
  public transactionCasinoHeaders = ['#', 'Game Name', 'Balance', 'Amount', 'Created'];
  public transactionReferralSystemHeaders = ['#', 'Transaction Type', 'User Name', 'Balance', 'Amount', 'Created'];
  public transactionForm: FormGroup;

  @Input() public currentCurrency: Currency;
  @Input() public currencies: Currency[];
  @Input() public transactionsSettingsBarVisible: boolean;
  @Output() public visibleChange = new EventEmitter<boolean>();

  public constructor(
    private cdr: ChangeDetectorRef,
    private httpDataService: HttpDataService,
    private utilService: UtilService,
    private authService: AuthService,
    ) {
  }

  public ngOnInit() {
    this.transactionTypes = this.utilService.getTransactionTypes();
    const currenciesGroupObject: any = {};
    this.currencies.forEach(c => {
      currenciesGroupObject[c.id] = new FormControl<boolean>(c.id === this.currentCurrency.id);
    });
    this.transactionForm = new FormGroup({
      transactionType1: new FormControl<boolean>(true),
      transactionType2: new FormControl<boolean>(false),
      transactionType3: new FormControl<boolean>(false),
      transactionType4: new FormControl<boolean>(false),
      transactionType5: new FormControl<boolean>(false),
      from: new FormControl<Date | null>(null),
      to: new FormControl<Date | null>(null),
      currencies: new FormGroup(currenciesGroupObject),
    });
    this.fetchTransactions();
  }

  public fetchTransactions() {
    const to: string = (this.transactionForm.value.to || new Date()).toISOString();
    const from: string = (this.transactionForm.value.from || new Date(1700, 1, 1)).toISOString();
    const transactionTypeIds: number[] = [];
    for (let i = 1; i <= 5; i++) {
      if (this.transactionForm.controls['transactionType' + i as TransactionTypeKey].value) {
        transactionTypeIds.push(i);
      }
    }
    this.transactionWalletRecords = [];
    this.transactionCryptoRecords = [];
    this.transactionTransferRecords = [];
    this.transactionCasinoRecords = [];
    this.transactionReferralSystemRecords = [];
    if (transactionTypeIds?.length) {
      zip(transactionTypeIds.map(typeId => this.getFetchMethodByTransactionType(typeId, from, to)
        .pipe(
          map((response: NewResponse<TransactionType>) => {
            return ({
              response,
              typeId,
            });
          }),
        )))
        .subscribe({
          next: results => {
            for (let i = 0; i < transactionTypeIds.length; i++) {
              const result = results[i];
              const records = this.utilService.getRecords(result.typeId, result.response);
              switch (result.typeId) {
                case 1:
                  this.transactionWalletRecords = records;
                  break;
                case 2:
                  this.transactionCryptoRecords = records;
                  break;
                case 3:
                  this.transactionTransferRecords = records;
                  break;
                case 4:
                  this.transactionCasinoRecords = records;
                  break;
                case 5:
                  this.transactionReferralSystemRecords = records;
                  break;
                default:
                  throw new Error();
              }
            }
            this.cdr.detectChanges();
          },
        });
    }
  }

  private getFetchMethodByTransactionType(transactionTypeId: number, from: string, to: string)
    : Observable<NewResponse<TransactionType>> {
    const selectedCurrencies = this.transactionForm.controls['currencies'].value;
    const selectedCurrencyIds = [];
    for (const c of this.currencies) {
      if (selectedCurrencies[c.id]) {
        selectedCurrencyIds.push(c.id);
      }
    }
    if (transactionTypeId === 1 && this.authService.hasPermission(PermissionsEnum.wallet_transactions)) {
      return zip(...selectedCurrencyIds.map(currencyId => {
        return this.httpDataService.getUserTransactionsBetween(currencyId, from, to, 1, 50)
      })).pipe(
        map(responses => {
          const firstRes = responses[0];
          if (firstRes) {
            for (let i = 1; i < responses.length; i++) {
              const res = responses[i];
              if (firstRes.totalItems && res.totalItems) {
                firstRes.totalItems += res.totalItems;
              }
              for (const item of responses[i].value.items) {
                firstRes.value.items.push(item);
              }
            }
          }
          return firstRes;
        })
      );
    }
    if (transactionTypeId === 2 && this.authService.hasPermission(PermissionsEnum.crypto_transactions)) {
      return zip(...selectedCurrencyIds.map(currencyId => {
        return this.httpDataService.getTransactionsCrypto(currencyId, from, to, 1, 50)
      })).pipe(
        map(responses => {
          const firstRes = responses[0];
          if (firstRes) {
            for (let i = 1; i < responses.length; i++) {
              const res = responses[i];
              if (firstRes.totalItems && res.totalItems) {
                firstRes.totalItems += res.totalItems;
              }
              for (const item of responses[i].value.items) {
                firstRes.value.items.push(item);
              }
            }
          }
          return firstRes;
        })
      );
    }
    if (transactionTypeId === 3 && this.authService.hasPermission(PermissionsEnum.transfer_transactions)) {
      return zip(...selectedCurrencyIds.map(currencyId => {
        return this.httpDataService.getTransactionsTransfer(currencyId, from, to, 1, 50)
      })).pipe(
        map(responses => {
          const firstRes = responses[0];
          if (firstRes) {
            for (let i = 1; i < responses.length; i++) {
              const res = responses[i];
              if (firstRes.totalItems && res.totalItems) {
                firstRes.totalItems += res.totalItems;
              }
              for (const item of responses[i].value.items) {
                firstRes.value.items.push(item);
              }
            }
          }
          return firstRes;
        })
      );
    }
    if (transactionTypeId === 4 && this.authService.hasPermission(PermissionsEnum.casino_transactions)) {
      return zip(...selectedCurrencyIds.map(currencyId => {
        return this.httpDataService.getTransactionsCasinoBetween(currencyId, from, to, 1, 50)
      })).pipe(
        map(responses => {
          const firstRes = responses[0];
          if (firstRes) {
            for (let i = 1; i < responses.length; i++) {
              const res = responses[i];
              if (firstRes.totalItems && res.totalItems) {
                firstRes.totalItems += res.totalItems;
              }
              for (const item of responses[i].value.items) {
                firstRes.value.items.push(item);
              }
            }
          }
          return firstRes;
        })
      );
    }
    if (transactionTypeId === 5 && this.authService.hasPermission(PermissionsEnum.referral_transactions)) {
      return zip(...selectedCurrencyIds.map(currencyId => {
        return this.httpDataService.getTransactionsReferralSystem(currencyId, from, to, 1, 50)
      })).pipe(
        map(responses => {
          const firstRes = responses[0];
          if (firstRes) {
            for (let i = 1; i < responses.length; i++) {
              const res = responses[i];
              if (firstRes.totalItems && res.totalItems) {
                firstRes.totalItems += res.totalItems;
              }
              for (const item of responses[i].value.items) {
                firstRes.value.items.push(item);
              }
            }
          }
          return firstRes;
        })
      );
    }
    throw (new Error('Transaction type is required.'));
  }

  public trackByHeader(index: number, item: string) {
    return item;
  }

  public onSettingsVisibleChange(value: boolean) {
    this.transactionsSettingsBarVisible = value
    this.visibleChange.emit(this.transactionsSettingsBarVisible);
  }

  public filterTransactions() {
    this.onSettingsVisibleChange(false);
    this.fetchTransactions();
  }
}

export type TransactionTypeKey =
  'transactionType1'
  | 'transactionType2'
  | 'transactionType3'
  | 'transactionType4'
  | 'transactionType5';
