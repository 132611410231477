<p-accordion>
  <p-accordionTab *ngIf="hasPermissionToReadWagerBonuses">
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <span class="vertical-align-middle">Wager Bonuses</span>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div *ngIf="!bonuses.length">No wager bonuses yet.</div>
      <div class="currency-options-bar">
        <div *ngFor="let bonus of bonuses" class="currency-option">
          <div class="flex flex-row align-items-center gap-4 currency-option-info">
            <img class="moving-item" width="50px"
                 [src]="'assets/bonuses/' + 'no-wagering-casino-bonuses' + '.svg'">
            <div style="width: 100%" class="flex flex-column align-items-flex-start gap-2 selected-item">
              <div class="flex justify-content-between">
                <div class="cur-name moving-item">Bonus {{ bonus.bonus }}</div>
                <div class="cur-name">Wagering requirement {{ bonus.wageringRequirement }}</div>
              </div>
              <div class="flex justify-content-between">
                <div class="cur-code moving-item">Created at {{ bonus.createdAt | date:'short' }}</div>
                <div
                  class="cur-code">Active until {{ bonus.activeUntil | date:'short'}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-accordionTab>

  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <span class="vertical-align-middle" i18n="@@history">History</span>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <form [formGroup]="formGroup">
        <div [ngClass]="{ 'account-content' : !isMobileView, 'account-content__mobile': isMobileView }">
          <casino-date-picker
            [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
            [width]="isMobileView ? '100%' : '250px'"
            [placeholder]="'From'"
            [label]="'From'"
            [value]="formGroup['from']"
            (onValueChange)="formGroup.controls['from'].setValue($event)">
          </casino-date-picker>

          <casino-date-picker
            [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
            [width]="isMobileView ? '100%' : '250px'"
            [placeholder]="'To'"
            [label]="'To'"
            [value]="formGroup['to']"
            (onValueChange)="formGroup.controls['to'].setValue($event)">
          </casino-date-picker>
          <div (click)="fetchCasinoHistory()" class="text-btn">
            <button class="inner-btn inner-btn-wide">Fetch</button>
          </div>
        </div>
      </form>
      <div>
        <p-table [value]="casinoHistoryRecords" [tableStyle]="{ 'min-width': '30rem' }">
          <ng-template pTemplate="header">
            <tr>
              <th>#</th>
              <th i18n="@@freespinCountsHeader">Freespin counts</th>
              <th i18n="@@gameNameHeader">Game name</th>
              <th i18n="@@createdHeader">Created</th>
              <th i18n="@@statusHeader">Status</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-record>
            <tr>
              <td>{{ record.code }}</td>
              <td>{{ record.freeSpinCount }}</td>
              <td>{{ record.gameName }}</td>
              <td>{{ record.dateCreated }}</td>
              <td>{{ record.status }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-template>
  </p-accordionTab>

  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <span class="vertical-align-middle" i18n="@@gameHistory">Game History</span>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <form [formGroup]="casinoGameHistoryForm">
        <div [ngClass]="{ 'account-content' : !isMobileView, 'account-content__mobile': isMobileView }">
          <casino-date-picker
            [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
            [width]="isMobileView ? '100%' : '250px'"
            [placeholder]="'From'"
            [label]="'From'"
            [value]="formGroup['from']"
            (onValueChange)="casinoGameHistoryForm.controls['from'].setValue($event)">
          </casino-date-picker>

          <casino-date-picker
            [ngClass]="{ 'account-content__item' : !isMobileView, 'account-content__item_mobile': isMobileView }"
            [width]="isMobileView ? '100%' : '250px'"
            [placeholder]="'To'"
            [label]="'To'"
            [value]="formGroup['to']"
            (onValueChange)="casinoGameHistoryForm.controls['to'].setValue($event)">
          </casino-date-picker>

          <div (click)="fetchCasinoGameHistory()" class="text-btn">
            <button class="inner-btn inner-btn-wide">Fetch</button>
          </div>
        </div>
      </form>
      <div>
        <p-table [value]="casinoGameHistoryRecords" [tableStyle]="{ 'min-width': '30rem' }">
          <ng-template pTemplate="header">
            <tr>
              <th>#</th>
              <th i18n="@@transactionTypeHeader">Transaction type</th>
              <th i18n="@@balanceBeforeHeader">Balance before</th>
              <th i18n="@@transferAmountHeader">Transfer amount</th>
              <th i18n="@@balanceAfterHeader">Balance after</th>
              <th i18n="@@createdHeader">Created</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-record>
            <tr>
              <td>{{ record.id }}</td>
              <td>{{ record.baseTransaction.transactionType }}</td>
              <td>{{ 'todo' }}</td>
              <td>{{ record.baseTransaction.amount.value }}</td>
              <td>{{ 'todo' }}</td>
              <td>{{ record.created }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-template>
  </p-accordionTab>
</p-accordion>
