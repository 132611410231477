<div [ngClass]="isMobileView ? 'image-slider-mobile' : ''">
  <div class="images" *ngIf="!isFiltered || isLoginPageOpen">
    <div class="images-inner">
      <div class="image-slide" *ngFor="let image of images; trackBy:trackByImage">
        <img class="image no-user-selection" [src]="image" alt="Slide image">
      </div>
    </div>
  </div>
</div>

<div id="casino-providers-slider-wrapper" *ngIf="!isMobileView">
  <providers-slider></providers-slider>
</div>

<div *ngIf="isLoading" class="loading-spinner">
  <div class="ring">Loading<span></span></div>
</div>

<ng-container *ngIf="!isFiltered || isLoginPageOpen" class="carousel">
  <ng-container *ngFor="let gameProvider of gameProviders; trackBy:trackByProviderId">
    <app-game-carousel
      [title]="gameProvider?.displayName"
      [games]="games.get(gameProvider.id)"
    ></app-game-carousel>
  </ng-container>
</ng-container>

<ng-container *ngIf="!!isFiltered">
  <div class="filtered-games-container">
    <div class="game-header-wrapper">
      <h3 class="provider-name no-user-selection">
        {{ selectedCategory === 'liked' ? (favouriteGames | translateIfMultilingual) : selectedCategory }}
      </h3>
    </div>
    <div class="game-list-wrapper">
      <div class="game-list" [ngClass]="filteredGames.length > 2 ? 'game-list-space-evenly' : 'game-list-start'">
        <div *ngFor="let game of filteredGames; trackBy:trackByFilteredGameId">
          <app-game-card
            (removeLiked)="selectedCategory === 'liked' ? updateLikedGames() : ''"
            [game]="game"
          ></app-game-card>
        </div>
      </div>
    </div>
  </div>
</ng-container>



