import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {REQUEST_ERROR} from '../../../locale/multilingual-strings-constants';
import {MultilingualStringModel} from '../model/multilingual-string.model';
import {ToastService} from './toast.service';

@Injectable()
export class NotifierService {
  constructor(
    private toastService: ToastService,
    @Inject(LOCALE_ID) public locale: string,
  ) {
  }

  public handleRequestError(error: HttpErrorResponse): void {
    console.warn(error);
    if (error?.error?.errors?.length) {
      error.error.errors.forEach((err: any) => {
        this.toastService.message$.next({
          severity: 'error',
          summary: 'Failure',
          detail: err.description,
        });
      });
      return;
    }

    this.toastService.message$.next({
      severity: 'error',
      summary: 'Failure',
      detail: REQUEST_ERROR[this.locale],
    });
  }

  public handleErrors(errors: string[]): void {
    this.toastService.message$.next({
      severity: 'error',
      summary: 'Failure',
      detail: errors.join('\n'),
    });
  }

  public handleSuccessRequest(detail: MultilingualStringModel): void {
    this.toastService.message$.next({
      severity: 'success',
      summary: 'Success',
      detail: detail[this.locale],
    });
  }
}
