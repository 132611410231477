<app-canvas-background></app-canvas-background>

<div *ngIf="!isMobileView; else mobileView">
  <div class="container desktop-view">
    <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  </div>
</div>

<ng-template #mobileView>
  <ng-container *ngIf="!isGameOpenedInMobileMode; else gameOpenedInMobileMode">
    <div class="container mobile-view">
      <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
    </div>
    <mobile-access-panel></mobile-access-panel>
  </ng-container>
  <ng-template #gameOpenedInMobileMode>
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </ng-template>
</ng-template>

<ng-template #mainTemplate>
  <p-toast position="top-center"></p-toast>
  <ng-container *ngTemplateOutlet="header"></ng-container>
  <ng-container *ngIf="!isMobileView">
    <ng-container *ngTemplateOutlet="leftBarDesktop"></ng-container>
  </ng-container>
  <ng-container *ngIf="isMobileView">
    <ng-container *ngTemplateOutlet="leftBarMobile"></ng-container>
  </ng-container>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <ng-container *ngTemplateOutlet="rightBar"></ng-container>
  <ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-template>

<ng-template #header>
  <div class="header">
    <app-header [timeZone]="timeZone"></app-header>
  </div>
</ng-template>

<ng-template #leftBarDesktop>
  <div class="sidebar"
       [ngClass]="{ 'sidebar-open': sidebarOpen, 'sidebar-closed': !sidebarOpen, }">
    <left-bar
      [isAdminBar]="showAdminSideBar"
      [isMobileView]="false"
      [isAuthenticated] = "isAuthenticated"
      (toggle)="sidebarOpen=!sidebarOpen"
      style="width: inherit"
    ></left-bar>
  </div>
</ng-template>

<ng-template #leftBarMobile>
  <div class="sidebar mobile-left-bar">
    <p-sidebar
      styleClass="sidebar-custom"
      [(visible)]="visible"
      [style]="{width:'220px'}"
      position="left">
      <left-bar
        [isAdminBar]="showAdminSideBar"
        [isMobileView]="true"
        [isAuthenticated]="isAuthenticated"
        (isLeftBarClosed)="closeLeftBar($event)"
        style="width: 50%"
      ></left-bar>
    </p-sidebar>
  </div>
</ng-template>

<ng-template #content>
  <div class="content">
    <div class="content__blur-overlay">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div class="content__sidebars">
    <app-register *ngIf="navigationService.showRegisterPage"></app-register>
    <app-login-page *ngIf="navigationService.showLoginPage"></app-login-page>
    <app-forgot-password *ngIf="navigationService.showFPPage"></app-forgot-password>
  </div>
</ng-template>

<ng-template #rightBar>
  <div class="user-settings-bar">
    <right-bar style="width: inherit"></right-bar>
  </div>
</ng-template>

<ng-template #footer>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</ng-template>
