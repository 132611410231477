import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {countries} from '../../util/countries';
import {AuthService} from '../../services/auth.service';
import {Subscription} from 'rxjs';
import {Response} from '../../model/response.model';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {LoginService} from '../../services/login.service';
import {REGISTRATION_SUCCESS} from '../../../../locale/multilingual-strings-constants';
import {LOCAL_STORAGE_REFERRAL_ID} from '../../constants';
import {NavigationService} from '../../services/navigation.service';
import {NotifierService} from '../../services/notifier.service';
import {DeviceDetectorService} from '../../services/device-detector.service';
import {RegisterModel} from '../../../../../libs/submodules/identity/src/lib/models/register.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['../../../../auth-component-styles.scss'],
  animations: [
    trigger('registrationSlideInAnimation', [
      state('in', style({transform: 'translateX(0)'})),
      transition(':enter', [style({transform: 'translateX(100%)'}), animate('500ms ease')]),
      transition(':leave', animate('500ms ease', style({transform: 'translateX(100%)'}))),
    ]),
  ],
})
export class RegisterComponent implements OnInit, OnDestroy {
  private mouseDownOutside = false;
  private aSub: Subscription;
  public showPassword = false;
  public isMobileView = false;
  public countries: { name: string; code: string; }[] = [];

  public registrationForm = this.fb.group({
    email: [null, Validators.compose([Validators.required, Validators.email])],
    password: [null, [Validators.required, Validators.pattern(/^(?=.*[!@#$%^&*(),.?:{}|<>-])(?=.*[0-9])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*(),.?:{}|<>-]{8,}$/)]],
    acceptTerms: [false, Validators.required],
    sendLoginCredentials: [false],
    country: [null, Validators.required],
    referralId: [localStorage.getItem(LOCAL_STORAGE_REFERRAL_ID)]
  });

  constructor(private authService: AuthService,
              private loginService: LoginService,
              private elementRef: ElementRef,
              private fb: FormBuilder,
              private navigationService: NavigationService,
              private notifierService: NotifierService,
              private detectorService: DeviceDetectorService,
  ) {
    this.countries = countries;
    this.isMobileView = detectorService.isMobile();
  }

  public ngOnInit(): void {
    this.loginService.showLogin();
    setTimeout(() => document.addEventListener('mousedown', this.onMousedown));
    setTimeout(() => document.addEventListener('click', this.clickOutsideComponent));
  }

  public register(): void {
    console.log(this.registrationForm.value)

    this.loginService.hideLogin();
    this.registrationForm.disable();
    this.aSub = this.authService.register(this.registrationForm).subscribe(
      (response: Response<string>) => {
        if (response.succeed) {
          this. handleSuccessfulRegistration();
        } else {
          this.handleFailedRegistration(response.errors);
        }
      },
      err => {
        this.notifierService.handleRequestError(err);
        this.registrationForm.enable();
      }
    );
  }

  private onRegisterSuccess(data: FormGroup) {
    const loginData: RegisterModel = {...data.value};
    const newLoginForm = this.fb.group({
      identifier: loginData.email.split('@')[0],
      password: loginData.password,
      rememberMe: false
    })

    this.authService.login(newLoginForm).subscribe(
      () => {
        this.navigationService.navigateToHome().then();
      },
      (error) => {
        console.error('Login failed:', error);
      }
    );
  }

  public closeRegisterPage() {
    this.navigationService.closeAllPages();
  }

  public togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  private onMousedown = (event: MouseEvent) => {
    this.mouseDownOutside = !this.elementRef.nativeElement.contains(event.target);
  };

  private clickOutsideComponent = (event: MouseEvent) => {
    if (!this.elementRef.nativeElement.contains(event.target) && this.mouseDownOutside) {
      this.closeRegisterPage();
    }
    this.mouseDownOutside = false;
  };

  private handleSuccessfulRegistration(): void {
    this.notifierService.handleSuccessRequest(REGISTRATION_SUCCESS);
    this.closeRegisterPage();
    this.onRegisterSuccess(this.registrationForm);
  }

  private handleFailedRegistration(errors: string[]): void {
    this.notifierService.handleErrors(errors);
    this.loginService.hideLogin();
    this.registrationForm.enable();
  }

  public ngOnDestroy(): void {
    if (this.aSub) {
      this.aSub.unsubscribe();
    }
    document.removeEventListener('click', this.clickOutsideComponent);
  }

  public trackByCountryCode(index: number, item: { name: string; code: string; }) {
    return item.code;
  }
}
