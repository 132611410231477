import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ReferralRedirectComponent} from './shared/classes/referral-redirect.component';
import {redirectIfLoggedInGuard} from './shared/guards/redirect-if-logged-in.guard';
import {hasPermissionGuard} from './shared/guards/has-permission.guard';
import {PermissionsEnum} from './shared/permissions.enum';

const routes: Routes = [
  {
    path: 'r/:referralId',
    component: ReferralRedirectComponent,
  },
  {
    path: 'administration',
    loadChildren: () => import('./modules/administration/administration.module').then(m => m.AdministrationModule),
    // canActivate: [hasPermissionGuard],
    // data: {
    //   permissionName: PermissionsEnum.,
    //   restPermissions: [],
    //   routing: true
    // }
  },
  {
    path: 'game',
    loadChildren: () => import('./modules/game/game.module').then(m => m.GameModule),
    canActivate: [hasPermissionGuard],
    data: {
      permissionName: PermissionsEnum.gameSession_view,
      restPermissions: [PermissionsEnum.gameSession_create],
      routing: true
    }
  },
  {
    path: 'Account/ForgotPassword',
    loadChildren: () => import('./shared/components/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    canActivate: [redirectIfLoggedInGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: '**',
    redirectTo: ''
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
