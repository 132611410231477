<ng-container *ngIf="fg && fcn; else wit">
  <form [formGroup]="fg">
    <div class="app-input">
      <span class="p-float-label">
        <input
          pInputText
          type="number"
          class="app-input__field app-input__field--color"
          style="width: {{width}}"
          [ngClass]="{'ng-dirty': touched}"
          placeholder="{{placeholder}}"
          id="{{id}}"
          [disabled]="disabled"
          formControlName="{{fcn}}"
          [min]="min || undefined"
          [minFractionDigits]="minFractionDigits || undefined"
          [maxFractionDigits]="maxFractionDigits || undefined"
        />
        <label class="app-input__label">{{ label }}</label>
      </span>
      <small
        id="{{id}}-help"
        class="p-error"
        *ngIf="hasError">
        {{ validationMessage }}
      </small>
    </div>
  </form>
</ng-container>

<ng-template #wit>
  <div class="app-input">
    <span class="p-float-label">
      <input
        pInputText
        type="number"
        class="app-input__field app-input__field--color"
        style="width: {{width}}"
        [ngClass]="{'ng-dirty': touched}"
        placeholder="{{placeholder}}"
        id="{{id}}"
        [disabled]="disabled"
        [value]="value"
        (input)="onValueChange.emit($event.target.value)"
      />
      <label class="app-input__label">{{ label }}</label>
    </span>
    <ng-container *ngIf="validationMessage">
      <small
        id="{{id}}-help"
        class="p-error"
        *ngIf="hasError">
        {{ validationMessage }}
      </small>
    </ng-container>
  </div>
</ng-template>
