import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {TableFieldModel} from '../../model/table-field.model';
import {DEFAULT_TABLE_LIMIT, DEFAULT_TABLE_ROWS_OPTIONS} from '../../constants';
import {FilterService, MenuItem} from 'primeng/api';
import {TableLazyLoadEvent, TablePageEvent, TableRowSelectEvent} from 'primeng/table/table.interface';
import {FieldTypesEnum} from '../../field-types.enum';
import {UtilService} from '../../services/util.service';
import {CheckboxChangeEvent} from 'primeng/checkbox/checkbox.interface';
import {Table} from 'primeng/table';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  public globalFilterField: string[] = [];
  public filterValue = '';
  public globalFilterPlaceholder = '';
  public columnFilterVisible: any = {};
  public fieldTypesEnum = FieldTypesEnum;
  public loadingRows: number[] = [];
  public virtualScrollOptions: any;
  private allRowsCount: number;
  private _fields: TableFieldModel[] = [];
  private _value: any[];

  @Input() public menuItems: MenuItem[] = [];
  @Input() public hasMenuPermission = false;
  @Input() public lazy = false;
  @Input() public paginator = false;
  @Input() public loading = false;
  @Input() public showCurrentPageReport = false;
  @Input() public filterDelay = 0;
  @Input() public globalFilterMatchMode = 'contains';
  @Input() public columnFilterMatchMode = 'contains';
  @Input() public rowsLimit = DEFAULT_TABLE_LIMIT;
  @Input() public rowsPerPageOptions = DEFAULT_TABLE_ROWS_OPTIONS;
  @Input() public totalRecords: number;
  @Input() public content: string;
  @Input() public secondaryContent: string;
  @Input() public additionalFields: TableFieldModel[];
  @Input() public selectionMode: 'multiple' | 'single';
  @Output() public onRowSelect$: EventEmitter<TableRowSelectEvent> = new EventEmitter<TableRowSelectEvent>();
  @Output() public onRowDataChange$: EventEmitter<{ row: TableRowSelectEvent; event: CheckboxChangeEvent, data: any[] }> = new EventEmitter<{ row: TableRowSelectEvent; event: CheckboxChangeEvent, data: any[] }>();
  @Output() public onLoad$: EventEmitter<{ first: number, last: number }> = new EventEmitter<{ first: number, last: number }>();
  @Output() public onLazyLoad$: EventEmitter<TableLazyLoadEvent> = new EventEmitter<TableLazyLoadEvent>();
  @Output() public onPage$: EventEmitter<TablePageEvent> = new EventEmitter<TablePageEvent>();
  @Output() public onToggleMenu$: EventEmitter<TableRowSelectEvent> = new EventEmitter<TableRowSelectEvent>();
  @Output() public onAction$: EventEmitter<TableRowSelectEvent> = new EventEmitter<TableRowSelectEvent>();
  @Input() public first = 0;
  @Input() public virtualScroll = false;
  @ViewChild('table') public primeTableComponent: Table;

  public get value(): any[] {
    return this._value;
  }

  @Input()
  public set value(value: any[]) {
    if (!this.value || !this.value.length) {
      this.virtualScrollOptions = {
        items: value,
        step: 40,
        orientation: 'vertical',
        autoSize: true,
        numToleratedItems: 20, // intervals of firsts/lasts is numToleratedItems+1
        delay: 0,
        lazy: false,
        onScrollIndexChange: this.onLoad.bind(this),
      };
    }
    this._value = value;
    this.calculateLoadingRowsCount();
  }

  @Input()
  set fields(value: TableFieldModel[]) {
    this._fields = value;
    this.globalFilterField = value.filter(field => field.globalFilter).map(field => field.name);
    this.globalFilterPlaceholder = value.filter(field => field.globalFilter).map(field => field.displayName).join(', ');
  }

  get fields(): TableFieldModel[] {
    return this._fields;
  }

  @Input()
  set rowsCount(allRowsCount: number) {
    this.allRowsCount = allRowsCount;
    if (allRowsCount) {
      this.calculateLoadingRowsCount();
    }
  }

  private calculateLoadingRowsCount() {
    this.loadingRows = [];
    if (this.allRowsCount) {
      for (let i = 0; i < this.allRowsCount - this.value.length; i++) {
        this.loadingRows.push(i);
      }
    }
    this.cdr.detectChanges();
  }

  public constructor(
    private filterService: FilterService,
    private cdr: ChangeDetectorRef,
    public utilService: UtilService,
  ) {
  }

  public onLoad(e: any) {
    this.onLoad$.emit(e);
  }

  public onLazyLoadEvent(event: TableLazyLoadEvent) {
    this.onLazyLoad$.emit(event);
  }

  public toggleMenu(menu: any, event: MouseEvent, row: TableRowSelectEvent) {
    menu.toggle(event);
    this.onToggleMenu$.emit(row);
  }

  public onAction(row: TableRowSelectEvent) {
    this.onAction$.emit(row);
  }

  public resetFilter(fieldName: string) {
    this.columnFilterVisible[fieldName] = false;
  }

  public onPageEvent(event: TablePageEvent) {
    this.onPage$.emit(event);
  }

  public onRowSelectEvent(event: TableRowSelectEvent) {
    this.onRowSelect$.emit(event);
  }

  public changeRowData(row: TableRowSelectEvent, event: CheckboxChangeEvent) {
    this.onRowDataChange$.emit({row, event, data: this.value});
  }

  public resetScrollTop() {
    this.primeTableComponent.resetScrollTop();
  }
}
