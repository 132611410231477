import {environment} from '../../environments/environment';

export const ROOT_DOMAIN = `${environment.url}`;
export const FULL_DOMAIN = `https://${ROOT_DOMAIN}`;
export const API_URL = `${environment.apiUrl}`;

export const SUPPORTED_LANGUAGES = ['en-US', 'ru-RU', 'pt-PT', 'fr-FR', 'tr-TR', 'az-Latn-AZ', 'es-ES'];
export const DEFAULT_LANGUAGE = 'e52a873d-9ed2-4613-8a68-396a67dfab52'; // en

export const FETCH_INTERVAL = 5000;

export const LOCAL_STORAGE_AUTH_TOKEN = 'auth-token';
export const LOCAL_STORAGE_THEME = 'theme';
export const LOCAL_STORAGE_TIME_ZONE = 'time-zone';
export const LOCAL_STORAGE_LANGUAGE = 'language';
export const LOCAL_STORAGE_MAIN_CURRENCY = 'main-currency';
export const LOCAL_STORAGE_GAME_CURRENCY = 'game-currency';
export const LOCAL_STORAGE_ACCOUNT_DETAILS = 'account-details';
export const LOCAL_STORAGE_REFERRAL_ID = 'referral-id';

export const SETTINGS_AGREEMENT_PANEL_INDEX_IN = 3;
export const SETTINGS_AGREEMENT_PANEL_INDEX_OUT = 1;
export const SETTINGS_ABOUT_PANEL_INDEX_IN = 4;
export const SETTINGS_ABOUT_PANEL_INDEX_OUT = 2;
export const SETTINGS_TAB_INDEX_IN = 5;
export const SETTINGS_TAB_INDEX_OUT = 1;
export const SETTINGS_GENERAL_PANEL_INDEX_IN = 1;
export const WALLETS_TAB_INDEX_IN = 1;

export const GAME_TYPES_ORDER: { [key: string]: number } = {
  'topgames': 1,
  'slot': 2,
  'tablegames': 3,
  'arcade': 4,
  'newgames': 5,
  'blackjack': 6,
  'keno': 7,
  'poker': 8,
  'roulette': 9,
};

export const MAX_PAGING_LIMIT = 999999;
export const DEFAULT_TABLE_LIMIT = 200;
export const DEFAULT_TABLE_ROWS_OPTIONS = [50, 100, 200, 500, 1000];
export const ROLES_LIMIT = 200;
export const LOCAL_STORAGE_ROLES_LIMIT = 'roles-limit';
export const LOCAL_STORAGE_USER_TRANSACTIONS_LIMIT = 'user-transactions-limit';
export const LOCAL_STORAGE_USERS_LIMIT = 'users-limit';
export const LOCAL_STORAGE_USERS_BY_CREATOR_LIMIT = 'users-by-creator-limit';

export const SETTINGS_DROPDOWN_ITEMS: { name: string, settingsId: number, isSavedSettings: boolean }[] = [
  {
    name: 'Inherit from parent',
    settingsId: 1,
    isSavedSettings: false,
  },
];

export const GAME_CURRENCY_ICONS_BG = {
  cny: '#b90808',
  brl: '#ffea00',
  azn: '#a20068',
  usd: '#005098',
  try: '#9a0000',
  irr: '#ff8700',
  rub: '#00d9d9',
  eur: '#24bd06',
  mxn: '#92da00',
};
