import {Injectable} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {BehaviorSubject, EMPTY, finalize, Observable, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthService,
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((res: any) => {
        return this.authService.handleHttpResponse(res as HttpResponse<any>);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handleTokenRefresh(req, next);
        }
        return throwError(() => error);
      })
    );
  }

  private handleTokenRefresh(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshInProgress$.value) {
      this.refreshInProgress$.next(true);

      return this.authService.refreshToken().pipe(
        switchMap(() => {
          return next.handle(req).pipe(map((res: any) => {
            return this.authService.handleHttpResponse(res as HttpResponse<any>);
          }));
        }),
        catchError((err: HttpErrorResponse) => {
          if (this.authService.userIsAuthorized$.value) {
            this.authService.resetAccountAuthData(false);
          }
          this.authService.logout();
          return throwError(() => err);
        }),
        finalize(() => {
          this.refreshInProgress$.next(false);
        })
      );
    } else {
      return this.refreshInProgress$.pipe(switchMap(res => {
        return !res ? next.handle(req).pipe(map((res: any) => {
          return this.authService.handleHttpResponse(res as HttpResponse<any>);
        })) : EMPTY;
      }));
    }
  }

}
