import {ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, RouterStateSnapshot} from '@angular/router';
import {inject} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {NavigationService} from '../services/navigation.service';
import {PermissionsEnum} from '../permissions.enum';

export const hasPermissionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
) => {
  const permissionName = route.data['permissionName'];
  const restPermissions = route.data['restPermissions'];
  const routing = route.data['routing'];
  const authService = inject(AuthService);
  const hasAccess = authService.hasPermission(permissionName, restPermissions);
  if (!hasAccess) {
    const navigationService = inject(NavigationService);

    if (routing && !hasAccess) {
      switch (permissionName) {
        case PermissionsEnum.organizationUser_read:
          navigationService.navigateToHome().then();
          break;
        case PermissionsEnum.hostedServices_manage:
          navigationService.navigateToHome().then();
          break;
        case PermissionsEnum.game_read:
          navigationService.navigateToHome().then();
          break;
        case PermissionsEnum.gameProvider_read:
          navigationService.navigateToHome().then();
          break;
        case PermissionsEnum.roles_Read:
          navigationService.navigateToHome().then();
          break;
      }
    }
  }
  return hasAccess;
};

export const hasPermissionGuardChild: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => hasPermissionGuard(route, state);
