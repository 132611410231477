import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {MultilingualStringModel} from '../model/multilingual-string.model';

@Pipe({
  name: 'translateIfMultilingual',
})
export class TranslateIfMultilingualPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {
  }

  public transform(value: MultilingualStringModel | string): string {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      return value;
    }
    return value[this.locale];
  }

}
