<p-panel [toggleable]="true" [toggler]="'header'" [collapsed]="true">
  <ng-template pTemplate="header">
    <div class="flex align-items-center gap-2">
      <span class="font-bold">{{header}}</span>
    </div>
  </ng-template>
  <ng-template pTemplate="headericons"></ng-template>
  <p class="m-0">
    {{text}}
  </p>
</p-panel>
