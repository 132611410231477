import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'casino-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent {
  private _value: Date | null;
  @Input() public options: any[];
  @Input() public placeholder: string;
  @Input() public optionValue: string;
  @Input() public optionLabel: string;
  @Input() public label: string;
  @Input() public showTime: boolean;
  @Input() public showClear = false;
  @Input() public showIcon = true;
  @Input() public readonlyInput = true;
  @Input() public id: string;
  @Input() public width = '250px';
  @Output() public onValueChange = new EventEmitter<string>();

  @Input() set value(value: string) {
    if (value) {
      this._value = new Date(value);
    }
    this._value = null;
  }

  get value(): Date | null {
    return this._value;
  }
}
