<ng-container *ngIf="currentCurrency?.id">
  <div class="carousel-container">
    <div class="cards-container">
      <div class="crypto-card-wrapper" (click)="openCurrenciesBar()">
        <img class="crypto-svg"
             [src]="'assets/cryptocurrencies/' + currentCurrency.code.toLowerCase() + '.svg'"
             i18n-src="@@cryptoCurrencyImage">
        <div>
          <p class="price-text">
            Main currency
          </p>
          <p class="balance-text">
            {{currentWallet ? currentWallet?.balance || 0 : ''}} {{currentCurrency?.code}}
          </p>
        </div>
        <div>
          <i class="pi pi-angle-down wallet-change-icon"></i>
        </div>
      </div>

      <div class="crypto-card-wrapper" (click)="openWalletSettingsBar()">
        <div
          class="game-currency-icon"
          style="height: 40px; width: 40px; font-size: 22px; border-radius: 20px;"
          [ngStyle]="{backgroundColor: gameCurrencyIconsBG[gameCurrency?.code.toLowerCase()]}"
        >{{gameCurrency?.symbol}}</div>
        <div class="game-currency">
          <p class="price-text">Game currency</p>
          <p class="balance-text">{{currentWallet ? ((currentWallet?.balance || 0) * (rate || 0)).toFixed(6) : ''}} {{gameCurrency?.code}}</p>
        </div>
        <div>
          <i class="pi pi-angle-down wallet-change-icon"></i>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<p-sidebar
  [style]="{height: '100%', borderRadius: '10px'}"
  [modal]="false"
  [(visible)]="currenciesBarVisible"
  position="bottom"
>
  <div class="currencies-bar">
    <h3 class="header_text">Active Balance</h3>
    <p class="par-label">Choose currency</p>
<!--    <div class="currency-types-bar">-->
<!--      <div (click)="isCrypto = true;" [ngClass]="{'selected': isCrypto}" class="currency-type-btn">-->
<!--        Crypto-->
<!--      </div>-->
<!--      <div (click)="isCrypto = false;" [ngClass]="{'selected': !isCrypto}" class="currency-type-btn">-->
<!--        FIAT-->
<!--      </div>-->
<!--    </div>-->
    <div class="currency-options-bar">
      <ng-container *ngIf="!wallets?.length">
        <div [ngStyle]="{border: currentCurrency.id === c.id ? '1px solid' : null}" (click)="changeMainCurrency(c)" *ngFor="let c of mainCurrencies" class="currency-option">
          <div class="flex flex-row align-items-center gap-4 currency-option-info">
            <img class="moving-item" width="50px"
                 [src]="'assets/cryptocurrencies/' + (c.code | lowercase) + '.svg'">
            <div style="width: 100%" class="flex flex-column align-items-flex-start gap-2 selected-item">
              <div class="flex justify-content-between">
                <div class="cur-name moving-item">{{ c.name | uppercase }}</div>
                <div class="cur-name">{{c?.code | uppercase}}</div>
              </div>
              <div class="flex justify-content-between">
                <div class="cur-code moving-item">{{ c.code | uppercase }}</div>
                <div
                  class="cur-code">{{gameCurrency?.symbol}}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="wallets?.length">
        <div [ngStyle]="{border: currentCurrency.id === wallet.currencyId ? '1px solid' : null}" (click)="changeMainCurrency(wallet.currency)" *ngFor="let wallet of wallets" class="currency-option">
          <div class="flex flex-row align-items-center gap-4 currency-option-info">
            <img class="moving-item" width="50px"
                 [src]="'assets/cryptocurrencies/' + (wallet.currency.code | lowercase) + '.svg'">
            <div style="width: 100%" class="flex flex-column align-items-flex-start gap-2 selected-item">
              <div class="flex justify-content-between">
                <div class="cur-name moving-item">{{ wallet.currency.name | uppercase }}</div>
                <div class="cur-name">{{wallet?.balance || 0}} {{wallet.currency?.code | uppercase}}</div>
              </div>
              <div class="flex justify-content-between">
                <div class="cur-code moving-item">{{ wallet.currency.code | uppercase }}</div>
                <div
                  class="cur-code">{{((wallet?.balance || 0) * (rates[wallet.currency.code] || 0)).toFixed(6)}} {{gameCurrency?.symbol}}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</p-sidebar>

<div class="tabs" *ngIf="currentCurrency?.cryptoCurrency">
  <input type="radio" id="tab1" name="tab-control" checked>
  <input type="radio" id="tab2" name="tab-control">
  <input type="radio" id="tab3" name="tab-control">
  <input type="radio" id="tab4" name="tab-control">
  <ul>
    <li><label for="tab1" role="button" title="Withdraw"><br><span i18n="@@withdraw">Withdraw</span></label></li>
    <li><label for="tab2" role="button" title="Deposit"><br><span i18n="@@deposit">Deposit</span></label></li>
    <li><label for="tab3" role="button" title="Buy Crypto"><br><span i18n="@@buyCrypto">Buy Crypto</span></label></li>
  </ul>

  <div class="slider">
    <div class="indicator"></div>
  </div>
  <div class="content">
    <section>
      <ng-container *ngIf="currentCurrency && currentWallet">
        <app-withdraw [currentCurrency]="currentCurrency"
                      [currentWallet]="currentWallet"
                      [currentFiatCode]="gameCurrency?.code"
                      [rate]="rate">
        </app-withdraw>
      </ng-container>
    </section>

    <section>
      <ng-container *ngIf="currentCurrency && currentWallet">
        <app-deposit [currentCurrency]="currentCurrency"
                     [currentWallet]="currentWallet"
                     [rate]="rate"
                     [gameCurrency]="gameCurrency"
        ></app-deposit>
      </ng-container>
    </section>

    <section>
      <div class="flex flex-column gap-4">
        <app-panel
          [header]="'Where to buy crypto?'"
          [text]="'You can buy or cash out using crypto exchangers or crypto terminals. On the site bestchange.com you will find a list of exchangers through which you can purchase crypto. You can also use them to exchange crypto for fiat (paper) money.'"
        ></app-panel>

        <div class="flex flex-column gap-4">
          <div class="crypt__item" id="onramper-link"
               (click)="openExternalLink('https://onramper.com/')">
            <div class="crypt__option">
              <div class="item__icon moving-item">
                <img src="assets/images/onramper_icon.svg">
              </div>
              <div class="item__inner moving-item">
                <p class="item__header" i18n="@@onramper">Onramper</p>
                <p class="item__info" i18n="@@buyCryptoWithCard">Buy crypto with Credit/Debit Card</p>
              </div>
            </div>
            <i class="pi pi-angle-right crypt__nav"></i>
          </div>

          <div class="crypt__item" id="binance-link"
               (click)="openExternalLink('https://accounts.binance.com/en/register?ref=207299185&gclid=CjwKCAjwkaSaBhA4EiwALBgQaBp7rCCO7gSZKDxwg2rK2FKOC2c3SfGd74iIvjTY74Rta2rG3Mm-HRoCOE4QAvD_BwE')">
            <div class="crypt__option">
              <div class="item__icon moving-item">
                <img src="assets/images/binance_icon.svg">
              </div>
              <div class="item__inner moving-item">
                <p class="item__header" i18n="@@binance">Binance</p>
                <p class="item__info" i18n="@@buyCryptoFromRecommendedExchange">Buy crypto from our recommended exchange</p>
              </div>
            </div>
            <i class="pi pi-angle-right crypt__nav"></i>
          </div>

          <div class="crypt__item" id="kraken-link" (click)="openExternalLink('https://www.kraken.com/sign-in')">
            <div class="crypt__option">
              <div class="item__icon moving-item">
                <img src="assets/images/kraken_icon.svg">
              </div>
              <div class="item__inner moving-item">
                <p class="item__header">Kraken</p>
                <p class="item__info" i18n="@@buyCryptoFromRecommendedExchange">Buy crypto from our recommended exchange</p>
              </div>
            </div>
            <i class="pi pi-angle-right crypt__nav"></i>
          </div>

          <div class="crypt__item" id="blockfinex-link"
               (click)="openExternalLink('https://www.blockfinex.com/account/signin')">
            <div class="crypt__option">
              <div class="item__icon moving-item"><img src="assets/images/blockfinex_icon.svg" style="width:32px; height: 32px"></div>
              <div class="item__inner moving-item">
                <p class="item__header">Blockfinex</p>
                <p class="item__info" i18n="@@buyCryptoFromRecommendedExchange">Buy crypto from our recommended exchange</p>
              </div>
            </div>
            <i class="pi pi-angle-right crypt__nav"></i>
          </div>

          <div class="crypt__item" id="bestchange-link" (click)="openExternalLink('https://www.bestchange.com/')">
            <div class="crypt__option">
              <div class="item__icon moving-item"><img src="assets/images/bestchange.jpeg" style="width:32px; height: 32px"></div>
              <div class="item__inner moving-item">
                <p class="item__header">Bestchange</p>
                <p class="item__info" i18n="@@buyCryptoFromRecommendedExchange">Buy crypto from our recommended exchange</p>
              </div>
            </div>
            <i class="pi pi-angle-right crypt__nav"></i>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<div class="wallets-section-separator"></div>
<div class="wallets-section flex flex-column gap-2">
  <div class="wallets-section-title">{{'Transactions' | uppercase}}</div>
  <div class="flex flex-column gap-4">
    <div (click)="openTransactionsBar()" class="wallets-section-content flex justify-content-between">
      <div class="flex gap-4 moving-item">
        <i class="pi pi-clock"></i>
        <div>Transaction history</div>
      </div>
      <i class="pi pi-angle-right"></i>
    </div>
  </div>
</div>
<div class="wallets-section-separator"></div>
<div class="wallets-section flex flex-column gap-2">
  <div class="wallets-section-title">{{'Support & settings' | uppercase}}</div>
  <div class="flex flex-column gap-4">
    <div (click)="openFAQBar()" class="wallets-section-content flex justify-content-between">
      <div class="flex gap-4 moving-item">
        <i class="pi pi-question"></i>
        <div>FAQ</div>
      </div>
      <i class="pi pi-angle-right"></i>
    </div>
  </div>
</div>

<p-sidebar
  #transactionHistory
  class="wallet-transactions-bar"
  [style]="{height: '100%', borderRadius: '10px'}"
  [modal]="false"
  [(visible)]="transactionsBarVisible"
  position="bottom"
>
  <ng-template pTemplate="header">
    <i class="fa fa-sliders transactions-settings-icon" (click)="transactionsSettingsBarVisible=true"></i>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex flex-column gap-4 wallet-settings">
      <app-transaction [currentCurrency]="currentCurrency"
                       [transactionsSettingsBarVisible]="transactionsSettingsBarVisible"
                       (visibleChange)="transactionsSettingsBarVisible=$event"
                       [currencies]="mainCurrencies"
      ></app-transaction>
    </div>
  </ng-template>
</p-sidebar>

<p-sidebar
  #walletSettings
  [style]="{height: '100%', borderRadius: '10px'}"
  [modal]="false"
  [(visible)]="walletSettingsBarVisible"
  position="bottom"
>
  <div class="currencies-bar">
    <h3 class="header_text">Active Balance</h3>
    <p class="par-label">Choose currency</p>
    <div class="currency-options-bar">
      <div [ngStyle]="{border: gameCurrency.id === gc.id ? '1px solid' : null}"
           (click)="onGameCurrencyChange(gc.id); this.walletSettingsBarVisible = false;" *ngFor="let gc of gameCurrencies" class="currency-option">
        <div class="flex flex-row align-items-center gap-4 currency-option-info">
          <div
            class="game-currency-icon"
            style="height: 50px; width: 50px; font-size: 26px; border-radius: 25px;"
            [ngStyle]="{backgroundColor: gameCurrencyIconsBG[gc?.code.toLowerCase()]}"
          >{{gc?.symbol}}</div>
          <div class="flex flex-column align-items-flex-start gap-2 selected-item">
            <div class="flex justify-content-between">
              <div class="cur-name moving-item">{{ gc.name | uppercase }}</div>
            </div>
            <div class="flex justify-content-between">
              <div class="cur-code moving-item">{{ gc.code | uppercase }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>

<p-sidebar
  #FAQ
  [style]="{height: '100%', borderRadius: '10px'}"
  [modal]="false"
  [(visible)]="faqBarVisible"
  position="bottom"
>
  <div class="flex flex-column gap-4 wallet-settings">
    <h2 class="header_text">FAQ</h2>
    <div class="flex flex-column gap-3">
      <app-panel
        [header]="'How to invite players?'"
        [text]="'Tell them about the system and send them a link pointed above for registration. Throughout the time when the players play in our resource, you will receive 1 % of the amount they withdraw from the system. If the players you invite bring in new players, you will also profit from their earnings. This chain is endless. With the right approach, you can create a system that provides you with a stable income in a short time. The system is created by the players – for the players. The payout percentage of the system is the highest. The involved players will thank you for it.'"
      ></app-panel>
      <app-panel
        [header]="'How to deposit?'"
        [text]="'Select the currency you want to deposit in the wallet. In the Deposit tab, the Deposit address field contains the address of the wallet to which you want to transfer money. Copy the wallet’s address or scan the QR code and make a transfer. Note, after each replenishment, the wallet address changes.'"
      ></app-panel>
      <app-panel
        [header]="'How to withdraw?'"
        [text]="'Select the currency from which you want to withdraw money in the wallet. Go to the Withdraw tab, in the recipient address field, enter the address to which you want to withdraw money. In the amount field, enter the amount you want to withdraw and click the Withdraw button.'"
      ></app-panel>
      <app-panel
        [header]="'Where to buy crypto?'"
        [text]="'You can buy or cash out using crypto exchangers or crypto terminals. On the site bestchange.com you will find a list of exchangers through which you can purchase crypto. You can also use them to exchange crypto for fiat (paper) money.'"
      ></app-panel>
    </div>
  </div>
</p-sidebar>
