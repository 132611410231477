import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {HttpDataService} from '../../../services/http/http-data.service';
import {Currency} from '../../../model/account/currency.model';
import {CurrencyConvertService} from '../../../services/currency-convert.service';
import {OPERATION_SUCCESSFUL} from '../../../../../locale/multilingual-strings-constants';
import {ToastService} from '../../../services/toast.service';
import {NotifierService} from '../../../services/notifier.service';
import {AuthService} from '../../../services/auth.service';
import {PermissionsEnum} from '../../../permissions.enum';
import {WalletModel} from '../../../model/wallet/user-wallet/wallet.model';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WithdrawComponent implements OnChanges {
  public isQrReaderVisible = false;
  public withdrawForm = new FormGroup({
    withdrawAddress: new FormControl(''),
    cryptoCurrency: new FormControl(0),
    fiat: new FormControl(0),
  });

  @Input() public currentCurrency: Currency;
  @Input() public currentWallet: WalletModel;
  @Input() public rate: number;
  @Input() public currentFiatCode: string;

  public constructor(
    private cdr: ChangeDetectorRef,
    private httpDataService: HttpDataService,
    private currencyConvertService: CurrencyConvertService,
    private toastService: ToastService,
    private notifierService: NotifierService,
    private authService: AuthService,
  ) {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['currentCurrency'] && changes['currentCurrency'].currentValue) {
      this.withdrawForm.reset({
        cryptoCurrency: 0,
        fiat: 0
      });
    }
  }

  public withdraw() {
    if (this.authService.hasPermission(PermissionsEnum.wallet_withdraw)) {
      const body = {
        walletId: this.currentWallet?.id,
        address: this.withdrawForm.value.withdrawAddress,
        amount: {value: this.withdrawForm.value.cryptoCurrency}
      }
      if (!body.address) {
        this.toastService.message$.next({
          severity: 'error',
          summary: 'Withdraw address is required.',
          detail: 'Operation failed. Please enter correct data.',
        });
        return;
      }
      this.httpDataService.postWalletWithdraw(body).subscribe({
        next: data => {
          if (data.succeed) {
            this.notifierService.handleSuccessRequest(OPERATION_SUCCESSFUL);
            this.withdrawForm.reset();
          } else {
            this.notifierService.handleErrors(data.errors);
          }
        },
        error: error => this.notifierService.handleRequestError(error),
      });
    }
  }

  public convertFromFiat(amount: string) {
    this.withdrawForm.controls['cryptoCurrency'].setValue(this.currencyConvertService.convertFromFiat(+amount, this.rate));
    this.cdr.detectChanges();
  }

  public convertFromCrypto(amount: string) {
    this.withdrawForm.controls['fiat'].setValue(this.currencyConvertService.convertFromCrypto(+amount, this.rate));
    this.cdr.detectChanges();
  }

  public getMax(maxCryptoAmount: number) {
    this.withdrawForm.controls['cryptoCurrency'].setValue(maxCryptoAmount);
    this.convertFromCrypto(`${maxCryptoAmount}`);
    this.cdr.detectChanges();
  }

  async paste() {
    this.withdrawForm.value.withdrawAddress = await navigator.clipboard.readText();
    this.cdr.detectChanges();
  }

  public readScannerValue(value: string) {
    this.withdrawForm.value.withdrawAddress = value;
    this.isQrReaderVisible = false;
  }
}
