/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {ROOT_DOMAIN} from './app/shared/constants';
import {environment} from './environments/environment';
import {enableProdMode} from '@angular/core';

document.title = ROOT_DOMAIN + ' games';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
