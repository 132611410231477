<ng-container *ngIf="fg && fcn; else wit">
  <form [formGroup]="fg">
    <div class="app-input">
      <span class="p-float-label">
        <p-password
          class="app-input__field app-input__field--color"
          id="{{id}}"
          [style]="{width: width}"
          [ngClass]="{'ng-dirty': touched}"
          [placeholder]="placeholder"
          formControlName="{{fcn}}"
          [disabled]="disabled"
          [feedback]="false"
          [toggleMask]="true"
        ></p-password>
        <label class="app-input__label">{{ label }}</label>
      </span>
      <ng-container *ngIf="validationMessage">
        <small
          id="{{id}}-help"
          class="p-error"
          *ngIf="hasError">
          {{ validationMessage }}
        </small>
      </ng-container>
    </div>
  </form>
</ng-container>

<ng-template #wit>
  <div class="app-input">
    <span class="p-float-label">
      <p-password
        class="app-input__field app-input__field--color"
        id="{{id}}"
        [style]="{width: width}"
        [ngClass]="{'ng-dirty': touched}"
        [feedback]="false"
        [toggleMask]="true"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [ngModel]="value"
        (ngModelChange)="onValueChange.emit($event.target.value)"
      ></p-password>
      <label class="app-input__label">{{ label }}</label>
    </span>
    <ng-container *ngIf="validationMessage">
      <small
        id="{{id}}-help"
        class="p-error"
        *ngIf="hasError">
        {{ validationMessage }}
      </small>
    </ng-container>
  </div>
</ng-template>
