import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitButtonComponent {
  @Input() public disabled = false;
  @Input() public label: string;

  @Output() public onButtonClick = new EventEmitter<any>();

  public onClick(event: any): void {
    this.onButtonClick.next(event);
  }
}
