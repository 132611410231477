<div class="sidebar" [ngClass]="{'open': isDrawerOpen || isMobileView, 'mobile-sidebar': isMobileView, 'mobile-ios-sidebar': isIosMobile}"
     (mouseenter)="toggleIcons(true)" (mouseleave)="toggleIcons(false)">
  <div class="drawer">

    <ng-container *ngIf="!isAdminBar; else adminBar">
      <div *ngIf="!isMobileView" class="logo" [routerLink]="'home'">
        <img class="no-user-selection" width="50" src='assets/casino/superhero.svg'>
      </div>

      <div *ngIf="isAuthenticated" class="star-item btn favourite-games"
           (click)="filterLikedGames()">
        <i style="font-size: 29px" class="pi pi-star-fill"></i>
        <span *ngIf="isDrawerOpen || isMobileView" class="favourite-text" i18n="@@FavouritesLabel">Favourites</span>
      </div>

      <div *ngIf="isAuthenticated" class="wheel-item btn mini-btn">
        <img class="no-user-selection" (click)="openBonusGame()" width="30"
             src="assets/casino/wheel.svg">
        <span *ngIf="isDrawerOpen || isMobileView" class="favourite-text" i18n="@@WheelLabel">Wheel</span>
      </div>

      <ul class="navigation" [ngClass]="{'small-ios-navigation': isIosMobile}">
        <li
          [ngClass]="{'selected': 'home' === selectedCategory}"
          (click)="filterGamesByCategory('home')"
          (mouseenter)="hoveredCategory='allGames'"
          (mouseleave)="hoveredCategory=''"
        >
          <div class="mini-btn">
            <div class="img_wrapper">
              <img width="30" class="navigation-img no-user-selection"
                   [src]="selectedCategory !== 'home' ? 'assets/casino/categories/all_games.svg' : 'assets/casino/categories/all_games_active.svg'">
            </div>
            <span *ngIf="isDrawerOpen || isMobileView" class="text" i18n="@@AllGamesLabel">All Games</span>
          </div>
        </li>
      </ul>

      <ng-container *ngFor="let categoryType of categoryTypes; trackBy: trackByCategoryTypeId">
        <div class="title"
             *ngIf="isDrawerOpen || isMobileView">{{ getCategoryTypeName(categoryType) | translateIfMultilingual}}</div>
<!--        <div class="separator" *ngIf="!isDrawerOpen || isMobileView"></div>-->
        <ul class="navigation" [ngClass]="{'small-ios-navigation': isIosMobile}">

          <li *ngFor="let category of categorizedCategories[categoryType]; trackBy: trackByCategoryId"
              (click)="filterGamesByCategory(category.name)"
              (mouseenter)="hoveredCategory=category"
              (mouseleave)="hoveredCategory=''"
              [ngClass]="{'selected': category.name === selectedCategory}">
            <div class="mini-btn">
              <div class="img_wrapper">
                <img width="30" class="navigation-img no-user-selection"
                     [src]="selectedCategory !==  category.name ? category.url : category.urlActive">
              </div>
              <span *ngIf="isDrawerOpen || isMobileView" class="text">{{category.displayName}}</span>
            </div>
          </li>
        </ul>
      </ng-container>
    </ng-container>

    <ng-template #adminBar>
      <div *ngIf="!isMobileView" class="logo" [routerLink]="''">
        <img class="no-user-selection" width="50" src='assets/casino/superhero.svg'>
      </div>

      <ul class="navigation adminBar" [ngClass]="{'small-ios-navigation': isIosMobile}">
        <li *ngIf="userPermission" [ngClass]="{ 'selected': selectedNavItem === navEnum.users }">
          <div class="mini-btn" (click)="navigate(navEnum.users)">
            <i class="pi pi-users"></i>
            <span *ngIf="isDrawerOpen || isMobileView">Users</span>
          </div>
        </li>
        <li *ngIf="userPermission" [ngClass]="{ 'selected': selectedNavItem === navEnum.usersByCreator }">
          <div class="mini-btn" (click)="navigate(navEnum.usersByCreator)">
            <i class="pi pi-user-edit"></i>
            <span *ngIf="isDrawerOpen || isMobileView">Users By Creator</span>
          </div>
        </li>
        <li *ngIf="hostedServicesPermission" [ngClass]="{ 'selected': selectedNavItem === navEnum.hostedServices }">
          <div class="mini-btn" (click)="navigate(navEnum.hostedServices)">
            <img class="no-user-selection" width="40" [src]="'assets/images/hosted-services.png'">
            <span *ngIf="isDrawerOpen || isMobileView">Hosted Services</span>
          </div>
        </li>
        <li *ngIf="gamesPermission" [ngClass]="{ 'selected': selectedNavItem === navEnum.games }">
          <div class="mini-btn" (click)="navigate(navEnum.games)">
            <img class="no-user-selection" width="40" src='assets/casino/categories/all_games.svg'>
            <span *ngIf="isDrawerOpen || isMobileView">Games</span>
          </div>
        </li>
        <li *ngIf="gameProvidersPermission" [ngClass]="{ 'selected': selectedNavItem === navEnum.providers }">
          <div class="mini-btn" (click)="navigate(navEnum.providers)">
            <img class="no-user-selection" width="40" src='assets/images/providers.png'>
            <span *ngIf="isDrawerOpen || isMobileView">Game Providers</span>
          </div>
        </li>
        <li *ngIf="rolesPermission" [ngClass]="{ 'selected': selectedNavItem === navEnum.roles }">
          <div class="mini-btn" (click)="navigate(navEnum.roles)">
            <img class="no-user-selection" width="40" src='assets/images/role.png'>
            <span *ngIf="isDrawerOpen || isMobileView">Roles</span>
          </div>
        </li>
        <li *ngIf="messagesPermission" [ngClass]="{ 'selected': selectedNavItem === navEnum.messages }">
          <div class="mini-btn" (click)="navigate(navEnum.messages)">
            <i class="fa fa-comments-o"></i>
            <span *ngIf="isDrawerOpen || isMobileView">Messages</span>
          </div>
        </li>
      </ul>
    </ng-template>

  </div>
  <div *ngIf="!isMobileView" class="drawerToggle" >
    <img src="assets/design/Vector.png" class="toggle-vector no-user-selection" >
    <img *ngIf="!isDrawerOpen" src="assets/design/left_bar_arrow_right.svg" class="arrow-image no-user-selection" (click)="toggleDrawer()">
    <img *ngIf="isDrawerOpen" src="assets/design/left_bar_arrow_left.svg" class="arrow-image no-user-selection" (click)="toggleDrawer()">
  </div>
</div>
