import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {HttpDataService} from '../../services/http/http-data.service';
import {NotifierService} from '../../services/notifier.service';
import {UserMailboxesModel} from '../../model/mailbox/user-mailboxes.model';
import {MailBoxTypeEnum} from '../../model/mailbox/mail-box-type.enum';
import {switchMap} from 'rxjs/operators';
import {Observable, of, zip} from 'rxjs';
import {MessageModel} from '../../model/mailbox/message.model';
import {AuthService} from '../../services/auth.service';
import {PermissionsEnum} from '../../permissions.enum';
import {Response} from '../../model/response.model';

@Component({
  selector: 'mailbox',
  templateUrl: './mailbox.component.html',
  styleUrls: ['./mailbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailboxComponent implements OnInit {
  public activeIndex = 0;
  public mailBoxes: UserMailboxesModel[] = [];
  public messages: MessageModel[] = [];
  public loading = true;
  public readonly mailBoxTypeEnum = MailBoxTypeEnum;

  public constructor(
    private httpDataService: HttpDataService,
    private notifierService: NotifierService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
  ) {
  }

  public ngOnInit(): void {
    if (this.authService.hasPermission(PermissionsEnum.mailbox_read)) {
      this.httpDataService.getMailboxes().subscribe({
        next: res => {
          if (res.succeed) {
            this.mailBoxes = res.value;
            if (this.mailBoxes.length) {
              this.initMailBoxesAndMessages(this.activeIndex);
              this.cdr.detectChanges();
            }
          } else this.notifierService.handleErrors(res.errors);
        },
        error: err => this.notifierService.handleRequestError(err),
      });
    }
  }

  public onIndexChange(index: number) {
    this.initMailBoxesAndMessages(index)
  }

  private initMailBoxesAndMessages(index: number) {
    if (this.authService.hasPermission(PermissionsEnum.messageRecipient_read)
    && this.authService.hasPermission(PermissionsEnum.message_read)) {
      this.loading = true;
      this.messages = [];
      this.cdr.detectChanges();
      this.httpDataService.getMailboxByMailboxId(this.mailBoxes[index].mailboxId, 1, 100).pipe(
        switchMap(mailbox => {
          const observers: Observable<Response<MessageModel>>[] = [];
          mailbox.value.items.forEach(item => {
            observers.push(this.httpDataService.getMessageByMessageId(item.messageId));
          });
          if (!observers.length) {
            this.loading = false;
            this.cdr.detectChanges();
          }
          return observers.length ? zip(...observers) : of(null);
        })
      ).subscribe(messages => {
        messages?.forEach(mail => {
          this.messages.push(mail.value);
        });
        this.loading = false;
        this.cdr.detectChanges();
      });
    }
  }
}
