<div class="search-container">
  <span class="parent">
    <span class="parent-color">
      <span class="child" (click)="isSearchInputVisible? onSearch(search.value, $event): toggleSearchInput()">
        <span class="child-color">
          <span class="child">
            <span class="child-color">
              <img class="no-user-selection" [src]="'assets/images/Vector.svg'">
            </span>
          </span>
        </span>
      </span>

      <span
        class="search-content"
        [hidden]="!isSearchInputVisible">
          <input
            #search
            id="search-bar"
            type="{{type}}"
            placeholder="{{placeholder}}"
            style="width: {{width}}"
            (input)="onInput(search.value)"
            (keyup.enter)="onSearch(search.value)"
            [@expandInput]="isSearchInputVisible ? 'expanded' : 'collapsed'"
          >
      </span>
    </span>
  </span>
</div>
