<p-sidebar *ngIf="isAuthenticated"
           styleClass="sidebar-custom"
           [(visible)]="visible"
           (visibleChange)="onVisibleChange($event)"
           [style]="isMobileView ? { width:'100%' } : { width:'50%' }"
           position="right">

  <div
    *ngIf="isAuthenticated"
    class="user-info"
  >
    <div class="time">
      <p class="no-user-selection" style="margin: 0">{{ userMail ? userMail : userRole }}</p>
    </div>
    <div (click)="logout()" class="text-btn">
      <button class="inner-btn inner-btn-wide" i18n="header logout button @@logoutButton">Logout</button>
    </div>
  </div>

  <p-tabView
    *ngIf="visible"
    styleClass="tabview-custom"
    [(activeIndex)]="activeIndex"
    [scrollable]="true">

    <p-tabPanel>
      <ng-template pTemplate="header">
        <img width="30px"
             class="no-user-selection"
             [src]="activeIndex === 0 ? 'assets/identity/person_active.svg' : 'assets/identity/person.svg'"
             alt="Person icon">
      </ng-template>
      <ng-template pTemplate="content">
        <h1 class="header_text no-user-selection" i18n="@@accountHeading">Account</h1>
        <account-settings></account-settings>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <img width="30px"
             class="no-user-selection"
             [src]="activeIndex === 1 ? 'assets/identity/wallet_active.svg' : 'assets/identity/wallet.svg' "
             alt="wallet icon">
      </ng-template>
      <ng-template pTemplate="content">
        <h1 class="header_text no-user-selection" i18n="@@walletsHeading">Wallets</h1>
        <wallets *ngIf="visible"></wallets>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <img width="30px"
             class="no-user-selection"
             [src]="activeIndex === 2 ? 'assets/identity/referral_active.svg' : 'assets/identity/referral.svg' "
             alt="referral icon">
      </ng-template>
      <ng-template pTemplate="content">
        <h1 class="header_text no-user-selection" i18n="@@referralHeading">Referral System</h1>
        <referral-system [isAuthenticated]="isAuthenticated"></referral-system>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <img width="30px"
             class="no-user-selection"
             [src]="activeIndex === 3 ? 'assets//casino/bonus_active.svg' : 'assets//casino/bonus.svg' "
             alt="slot icon">
      </ng-template>
      <ng-template pTemplate="content">
        <h1 class="header_text no-user-selection" i18n="@@casinoHeading">Casino</h1>
        <casino></casino>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <img width="30px"
             class="no-user-selection"
             [src]="activeIndex === 4 ? 'assets/identity/message_active.svg' : 'assets/identity/message.svg' "
             alt="message icon">
      </ng-template>
      <ng-template pTemplate="content">
        <h1 class="header_text no-user-selection" i18n="@@mailboxHeading">Mailbox</h1>
        <mailbox></mailbox>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <img width="30px"
             class="no-user-selection"
             [src]="activeIndex === 5 ? 'assets/identity/settings_active.svg' : 'assets/identity/settings.svg' "
             alt="settings icon">
      </ng-template>
      <ng-template pTemplate="content">
        <h1 class="header_text no-user-selection" i18n="@@settingsHeading">Settings</h1>
        <settings [activePanelIndex]="settingsActivePanelIndex" [isAuthenticated]="isAuthenticated"></settings>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</p-sidebar>

<p-sidebar *ngIf="!isAuthenticated"
           styleClass="sidebar-custom"
           [(visible)]="visible"
           (visibleChange)="onVisibleChange($event)"
           [style]="isMobileView ? { width:'100%' } : { width:'50%' }"
           position="right">

  <p-tabView
    *ngIf="visible"
    styleClass="tabview-custom"
    [(activeIndex)]="activeIndex"
    [scrollable]="true">

    <p-tabPanel>
      <ng-template pTemplate="header">
        <img width="30px"
             class="no-user-selection"
             [src]="activeIndex === 0 ? 'assets/identity/referral_active.svg' : 'assets/identity/referral.svg' "
             alt="referral icon">
      </ng-template>
      <ng-template pTemplate="content">
        <h1 class="header_text no-user-selection" i18n="@@referralHeading">Referral System</h1>
        <referral-system [isAuthenticated]="isAuthenticated"></referral-system>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <img width="30px"
             class="no-user-selection"
             [src]="activeIndex === 1 ? 'assets/identity/settings_active.svg' : 'assets/identity/settings.svg' "
             alt="settings icon">
      </ng-template>
      <ng-template pTemplate="content">
        <h1 class="header_text no-user-selection" i18n="@@settingsHeading">Settings</h1>
        <settings [activePanelIndex]="settingsActivePanelIndex" [isAuthenticated]="isAuthenticated"></settings>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</p-sidebar>
