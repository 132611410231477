<div class="app-input">
  <span class="p-float-label">
    <p-dropdown
      *ngIf="!optionValueIsObject"
      class="app-input__field app-input__field--color"
      [style]="{width: width}"
      [options]="options"
      [selectId]="value"
      id="{{id}}"
      placeholder="{{placeholder}}"
      optionLabel="{{optionLabel}}"
      optionValue="{{optionValue}}"
      (onChange)="onValueChange.emit($event.value)">
      <ng-container *ngIf="itemTemplate">
        <ng-template let-item pTemplate="item">
          <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
        </ng-template>
      </ng-container>
    </p-dropdown>
    <p-dropdown
      *ngIf="optionValueIsObject"
      class="app-input__field app-input__field--color"
      [style]="{width: width}"
      [options]="options"
      [selectId]="value"
      id="{{id}}"
      placeholder="{{placeholder}}"
      optionLabel="{{optionLabel}}"
      [ngModel]="objectValue"
      (onChange)="onValueChange.emit($event.value)">
      <ng-container *ngIf="itemTemplate">
        <ng-template let-item pTemplate="item">
          <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
        </ng-template>
      </ng-container>
    </p-dropdown>
    <label class="app-input__label">{{ label }}</label>
  </span>
</div>
