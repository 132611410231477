import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'casino-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordComponent {
  @Input() public id: string;
  @Input() public placeholder: string;
  @Input() public label: string;
  @Input() public validationMessage: string;
  @Input() public disabled = false;
  @Input() public touched = false;
  @Input() public hasError = false;
  @Input() public width = '100%';
  @Input() public fcn: string;
  @Input() public fg: FormGroup;
  @Input() public value = '';

  @Output() public onValueChange = new EventEmitter<string>();
}
