import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {LOCAL_STORAGE_THEME} from '../constants';

@Injectable()
export class ThemeService {
  private renderer: Renderer2;

  public constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public switchTheme(theme: string) {
    const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = theme + '.css';
      localStorage.setItem(LOCAL_STORAGE_THEME, theme);
    }
  }

  public loadThemeFromLocalStorage() {
    const savedTheme = localStorage.getItem(LOCAL_STORAGE_THEME);
    const themeLink = this.renderer.selectRootElement('#app-theme');

    if (savedTheme && ['theme1', 'theme2', 'theme3', 'theme4', 'theme5', 'theme6'].includes(savedTheme)) {
      this.renderer.setAttribute(themeLink, 'href', savedTheme + '.css');
    } else {
      const href = themeLink.href as string;
      const themeName = href.slice(href.indexOf('theme'), href.indexOf('.css'));
      localStorage.setItem(LOCAL_STORAGE_THEME, themeName);
    }
  }
}
