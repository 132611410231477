import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'casino-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent {
  @Input() public id: string;
  @Input() public placeholder: string;
  @Input() public label: string;
  @Input() public validationMessage: string;
  @Input() public type = 'text';
  @Input() public rows = 5;
  @Input() public cols = 30;
  @Input() public disabled = false;
  @Input() public touched = false;
  @Input() public hasError = false;
  @Input() public width = '250px';
  @Input() public fcn: string;
  @Input() public fg: FormGroup;
  @Input() public value = '';

  @Output() public onValueChange = new EventEmitter<string>();

}
