import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {GameCategoryModel} from '../../model/game/game-category.model';
import {UtilService} from '../../services/util.service';
import {CategoryTypeEnum} from '../../model/category-type.enum';
import {AuthService} from '../../services/auth.service';
import {ALL_GAMES, GAME_PROVIDER, GAME_TYPE, UNCATEGORIZED} from '../../../../locale/multilingual-strings-constants';
import {MultilingualStringModel} from '../../model/multilingual-string.model';
import {GAME_TYPES_ORDER} from '../../constants';
import {NavigationService} from '../../services/navigation.service';
import {NavigationEnum} from '../../../modules/administration/enums/navigation.enum';
import {PermissionsEnum} from '../../permissions.enum';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs';
import {Platform} from '@angular/cdk/platform';

@Component({
  selector: 'left-bar',
  templateUrl: './left-bar.component.html',
  styleUrls: ['./left-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftBarComponent implements OnInit {
  public readonly navEnum = NavigationEnum;
  private allCategories: GameCategoryModel[] = [];
  public selectedCategory = 'home';
  public hoveredCategory = '';
  public isDrawerOpen = false;
  public showIcons = false;
  public categorizedCategories: { [key: number]: GameCategoryModel[] } = {};
  public userPermission = false;
  public hostedServicesPermission = false;
  public gamesPermission = false;
  public gameProvidersPermission = false;
  public rolesPermission = false;
  public messagesPermission = false;
  public selectedNavItem: NavigationEnum | null = null;
  public isIosMobile: boolean;

  public categoryTypes: number[] = [
    CategoryTypeEnum.GameType,
    CategoryTypeEnum.Uncategorized,
  ];

  @Input() public isMobileView: boolean;
  @Input() private _isAdminBar: boolean;
  @Input() public isAuthenticated: boolean;
  @Output() public isLeftBarClosed = new EventEmitter<boolean>();
  @Output() public toggle = new EventEmitter<boolean>();

  @Input()
  set isAdminBar(value: boolean) {
    this._isAdminBar = value;
    if (!this._isAdminBar) {
      this.selectedNavItem = null;
    }
  }

  get isAdminBar(): boolean {
    return this._isAdminBar;
  }

  public constructor(
    private utilService: UtilService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
    private platform: Platform,
  ) {
  }

  public ngOnInit(): void {
    this.isIosMobile = this.platform.IOS && this.isMobileView;
    this.initializeRouteListener();

    this.authService.userIsAuthorized$.subscribe({
      next: () => {
        this.initializePermissions();
      }
    });

    if (this.utilService.allCategories) {
      this.loadCategoriesWithUrls(this.utilService.allCategories);
    } else {
      this.utilService.allCategories$.subscribe(allCategories => {
        this.loadCategoriesWithUrls(allCategories);
      });
      this.utilService.initCategories();
    }
  }

  private initializeRouteListener() {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(() => {
      let currentRoute = this.route.root;
      while (currentRoute.children[0] !== undefined) {
        currentRoute = currentRoute.children[0];
      }
      const snapshot = currentRoute.snapshot;
      const path = snapshot.routeConfig?.path;
      if (snapshot) {
        this.selectedCategory = snapshot.params['category'] || 'home';
        if ((!this.selectedNavItem || (path && this.navEnum[path as keyof typeof NavigationEnum] === 0)) && path) {
          this.selectedNavItem = this.navEnum[path as keyof typeof NavigationEnum];
          this.cdr.detectChanges();
        }
      }
    });
  }

  private initializePermissions() {
    this.userPermission = this.authService.hasPermission(PermissionsEnum.profile_edit);
    this.hostedServicesPermission = this.authService.hasPermission(PermissionsEnum.hostedServices_manage);
    this.gamesPermission = this.authService.hasPermission(PermissionsEnum.game_read,
      [PermissionsEnum.game_create, PermissionsEnum.game_edit, PermissionsEnum.game_delete]);
    this.gameProvidersPermission = this.authService.hasPermission(PermissionsEnum.gameProvider_read,
      [
        PermissionsEnum.gameProvider_create,
        PermissionsEnum.gameProvider_edit,
        PermissionsEnum.gameProvider_delete,
      ]);
    this.rolesPermission = this.authService.hasPermission(PermissionsEnum.roles_Read,
      [PermissionsEnum.roles_create, PermissionsEnum.roles_edit, PermissionsEnum.roles_delete]);
    this.messagesPermission = this.authService.hasPermission(PermissionsEnum.organizationUser_read,
      [PermissionsEnum.organizationUser_edit, PermissionsEnum.organizationUser_delete]);
  }

  public filterLikedGames() {
    this.navigationService.navigateToHome('liked').then();
    if (this.isMobileView) {
      this.isLeftBarClosed.emit(true);
    }
  }

  public loadCategoriesWithUrls(allCategories: GameCategoryModel[]) {
    this.allCategories = allCategories.map(category => {
      category.url = `assets/casino/categories/${category.name.toLowerCase()}.svg`;
      category.urlActive = `assets/casino/categories/${category.name.toLowerCase()}_active.svg`;
      return category;
    })
    this.categorizeCategories();
    this.cdr.detectChanges();
  }

  private categorizeCategories() {
    this.categorizedCategories = {};

    this.allCategories
      .filter((category: GameCategoryModel) => category.categoryType != 1)
      .forEach((category) => {
        if (!this.categorizedCategories[category.categoryType]) {
          this.categorizedCategories[category.categoryType] = [];
        }
        this.categorizedCategories[category.categoryType].push(category);
      });

    Object.keys(this.categorizedCategories).forEach((key) => {
      if (+key === CategoryTypeEnum.GameType) {
        this.sortGameTypes(this.categorizedCategories[+key]);
      } else {
        this.categorizedCategories[+key].sort((a: GameCategoryModel, b: GameCategoryModel) =>
          a.name.localeCompare(b.name));
      }
    });
  }

  public getCategoryTypeName(categoryType: number): MultilingualStringModel {
    switch (categoryType) {
      case CategoryTypeEnum.GameProvider:
        return GAME_PROVIDER;
      case CategoryTypeEnum.GameType:
        return GAME_TYPE;
      case CategoryTypeEnum.AllGames:
        return ALL_GAMES;
      default:
        return UNCATEGORIZED;
    }
  }

  private sortGameTypes(gameTypes: GameCategoryModel[]): GameCategoryModel[] {
    return gameTypes.sort((a, b) => {
      if (a && b) {
        return GAME_TYPES_ORDER[a.name.toLowerCase()] - GAME_TYPES_ORDER[b.name.toLowerCase()];
      }
      return 0;
    })
  }

  public filterGamesByCategory(categoryName: string) {
    this.selectedCategory = categoryName;
    this.navigationService.navigateToHome(categoryName).then();
    if (this.isMobileView) {
      this.isLeftBarClosed.emit(true);
    }
  }

  public toggleDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen;
    this.toggle.emit(this.isDrawerOpen);
  }

  public toggleIcons(show: boolean) {
    this.showIcons = show;
  }

  public openBonusGame() {
    const bonusGames = this.utilService.getBonusGames();
    if (this.authService.isAuthenticated() && bonusGames.length) {
      this.utilService.openGame(
        bonusGames[0],
        false
      );
    }
    if (this.isMobileView) {
      this.isLeftBarClosed.emit(true);
    }
  }

  public trackByCategoryTypeId(index: number, item: number) {
    return item;
  }

  public trackByCategoryId(index: number, item: GameCategoryModel) {
    return item.id;
  }

  public navigate(name: NavigationEnum): void {
    this.selectedNavItem = name;
    switch (name) {
      case NavigationEnum.users:
        this.navigationService.navigateToUsers().then();
        break;
      case NavigationEnum.usersByCreator:
        this.navigationService.navigateToUsersByCreator().then();
        break;
      case NavigationEnum.hostedServices:
        this.navigationService.navigateToHostedServices().then();
        break;
      case NavigationEnum.games:
        this.navigationService.navigateToGames().then();
        break;
      case NavigationEnum.providers:
        this.navigationService.navigateToGameProviders().then();
        break;
      case NavigationEnum.roles:
        this.navigationService.navigateToAdministrationRoles().then();
        break;
      case NavigationEnum.messages:
        this.navigationService.navigateToAdministrationMessages().then();
        break;
      default:
        throw new Error();
    }
  }
}
