import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {Response} from '../../model/response.model';
import {AuthService} from '../../services/auth.service';
import {Subscription} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {LoginService} from '../../services/login.service';
import {LOGIN_SUCCESS} from '../../../../locale/multilingual-strings-constants';
import {NavigationService} from '../../services/navigation.service';
import {NotifierService} from '../../services/notifier.service';
import {DeviceDetectorService} from '../../services/device-detector.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['../../../../auth-component-styles.scss'],
  animations: [
    trigger('loginSlideInAnimation', [
      state('in', style({transform: 'translateX(0)'})),
      transition(':enter', [style({transform: 'translateX(100%)'}), animate('500ms ease')]),
      transition(':leave', [animate('500ms ease', style({transform: 'translateX(100%)'}))]),
    ]),
  ],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  private mouseDownOutside = false;
  public aSub: Subscription;
  public showPassword = false;
  public isMobileView = false;
  public loginForm = this.fb.group({
    identifier: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(8)]],
    rememberMe: [false]
  });

  constructor(private loginService: LoginService,
              private elementRef: ElementRef,
              private activatedRoute: ActivatedRoute,
              private fb: FormBuilder,
              private notifierService: NotifierService,
              private navigationService: NavigationService,
              private authService: AuthService,
              private detectorService: DeviceDetectorService,
  ) {
    this.isMobileView = detectorService.isMobile();
  }

  public ngOnInit(): void {   // todo ??????
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params['registered']) {
        console.log(1)
      } else if (params['accessDenied']) {
        console.log(2)
      }
    })

    this.loginService.showLogin();
    setTimeout(() => document.addEventListener('mousedown', this.onMousedown));
    setTimeout(() => document.addEventListener('click', this.clickOutsideComponent));
  }

  public ngOnDestroy(): void {
    if (this.aSub) {
      this.aSub.unsubscribe();
    }
    document.removeEventListener('click', this.clickOutsideComponent);
  }

  public login() {
    this.loginService.hideLogin();
    this.loginForm.disable();
    this.aSub = this.authService.login(this.loginForm).subscribe({
      next: (response: Response<string>) => {
        if (response.succeed) {
          this.handleSuccessfulLogin();
        } else {
          this.handleFailedLogin(response);
        }
      },
      error: err => {
        this.notifierService.handleRequestError(err);
        this.loginForm.enable();
      },
    });
  }

  public closeLoginPage() {
    this.navigationService.closeAllPages();
  }

  public togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  public openForgotPasswordPage() {
    this.navigationService.showForgotPassPage();
  }

  public openRegisterPage() {
    this.navigationService.showRegister();
  }

  private onMousedown = (event: MouseEvent) => {
    this.mouseDownOutside = !this.elementRef.nativeElement.contains(event.target);
  };

  private clickOutsideComponent = (event: MouseEvent) => {
    if (!this.elementRef.nativeElement.contains(event.target) && this.mouseDownOutside) {
      this.closeLoginPage();
    }
    this.mouseDownOutside = false;
  };

  private handleSuccessfulLogin() {
    this.notifierService.handleSuccessRequest(LOGIN_SUCCESS)
    this.loginService.hideLogin();
    this.loginForm.reset();
    this.loginForm.enable();
    this.closeLoginPage();
  }

  private handleFailedLogin(response: Response<string>) {
    this.notifierService.handleErrors(response.errors);
    this.loginForm.enable();
  }
}
