export const countries = [
  { name: 'Afghanistan', code: 'AF', alpha3Code: 'AFG', phoneCode: '+93' },
  { name: 'Åland Islands', code: 'AX', alpha3Code: 'ALA', phoneCode: '+358' },
  { name: 'Albania', code: 'AL', alpha3Code: 'ALB', phoneCode: '+355' },
  { name: 'Algeria', code: 'DZ', alpha3Code: 'DZA', phoneCode: '+213' },
  { name: 'American Samoa', code: 'AS', alpha3Code: 'ASM', phoneCode: '+1' },
  { name: 'Andorra', code: 'AD', alpha3Code: 'AND', phoneCode: '+376' },
  { name: 'Angola', code: 'AO', alpha3Code: 'AGO', phoneCode: '+244' },
  { name: 'Anguilla', code: 'AI', alpha3Code: 'AIA', phoneCode: '+1' },
  { name: 'Antarctica', code: 'AQ', alpha3Code: 'ATA', phoneCode: '' },
  { name: 'Antigua and Barbuda', code: 'AG', alpha3Code: 'ATG', phoneCode: '+1' },
  { name: 'Argentina', code: 'AR', alpha3Code: 'ARG', phoneCode: '+54' },
  { name: 'Armenia', code: 'AM', alpha3Code: 'ARM', phoneCode: '+374' },
  { name: 'Aruba', code: 'AW', alpha3Code: 'ABW', phoneCode: '+297' },
  { name: 'Australia', code: 'AU', alpha3Code: 'AUS', phoneCode: '+61' },
  { name: 'Austria', code: 'AT', alpha3Code: 'AUT', phoneCode: '+43' },
  { name: 'Azerbaijan', code: 'AZ', alpha3Code: 'AZE', phoneCode: '+994' },
  { name: 'Bahamas', code: 'BS', alpha3Code: 'BHS', phoneCode: '+1' },
  { name: 'Bahrain', code: 'BH', alpha3Code: 'BHR', phoneCode: '+973' },
  { name: 'Bangladesh', code: 'BD', alpha3Code: 'BGD', phoneCode: '+880' },
  { name: 'Barbados', code: 'BB', alpha3Code: 'BRB', phoneCode: '+1' },
  { name: 'Belarus', code: 'BY', alpha3Code: 'BLR', phoneCode: '+375' },
  { name: 'Belgium', code: 'BE', alpha3Code: 'BEL', phoneCode: '+32' },
  { name: 'Belize', code: 'BZ', alpha3Code: 'BLZ', phoneCode: '+501' },
  { name: 'Benin', code: 'BJ', alpha3Code: 'BEN', phoneCode: '+229'  },
  { name: 'Bermuda', code: 'BM', alpha3Code: 'BMU', phoneCode: '+1' },
  { name: 'Bhutan', code: 'BT', alpha3Code: 'BTN', phoneCode: '+975' },
  { name: 'Bolivia', code: 'BO', alpha3Code: 'BOL', phoneCode: '+591' },
  { name: 'Bosnia and Herzegovina', code: 'BA', alpha3Code: 'BIH', phoneCode: '+387' },
  { name: 'Botswana', code: 'BW', alpha3Code: 'BWA', phoneCode: '+267' },
  { name: 'Bouvet Island', code: 'BV', alpha3Code: 'BVT', phoneCode: ''  },
  { name: 'Brazil', code: 'BR', alpha3Code: 'BRA', phoneCode: '+55' },
  { name: 'British Indian Ocean Territory', code: 'IO', alpha3Code: 'IOT', phoneCode: '+246' },
  { name: 'Brunei Darussalam', code: 'BN', alpha3Code: 'BRN', phoneCode: '+673' },
  { name: 'Bulgaria', code: 'BG', alpha3Code: 'BGR', phoneCode: '+359' },
  { name: 'Burkina Faso', code: 'BF', alpha3Code: 'BFA', phoneCode: '+226' },
  { name: 'Burundi', code: 'BI', alpha3Code: 'BDI', phoneCode: '+257' },
  { name: 'Cambodia', code: 'KH', alpha3Code: 'KHM', phoneCode: '+855' },
  { name: 'Cameroon', code: 'CM', alpha3Code: 'CMR', phoneCode: '+237' },
  { name: 'Canada', code: 'CA', alpha3Code: 'CAN', phoneCode: '+1' },
  { name: 'Cape Verde', code: 'CV', alpha3Code: 'CPV', phoneCode: '+238' },
  { name: 'Cayman Islands', code: 'KY', alpha3Code: 'CYM', phoneCode: '+345' },
  { name: 'Central African Republic', code: 'CF', alpha3Code: 'CAF', phoneCode: '+236' },
  { name: 'Chad', code: 'TD', alpha3Code: 'TCD', phoneCode: '+235' },
  { name: 'Chile', code: 'CL', alpha3Code: 'CHL', phoneCode: '+56' },
  { name: 'China', code: 'CN', alpha3Code: 'CHN', phoneCode: '+86' },
  { name: 'Christmas Island', code: 'CX', alpha3Code: 'CXR', phoneCode: '+61' },
  { name: 'Cocos (Keeling) Islands', code: 'CC', alpha3Code: 'CCK', phoneCode: '+61' },
  { name: 'Colombia', code: 'CO', alpha3Code: 'COL', phoneCode: '+57' },
  { name: 'Comoros', code: 'KM', alpha3Code: 'COM', phoneCode: '+269' },
  { name: 'Congo', code: 'CG', alpha3Code: 'COG', phoneCode: '+242' },
  { name: 'Congo, DR', code: 'CD', alpha3Code: 'COD', phoneCode: '+243' },
  { name: 'Cook Islands', code: 'CK', alpha3Code: 'COK', phoneCode: '+682' },
  { name: 'Costa Rica', code: 'CR', alpha3Code: 'CRI', phoneCode: '+506' },
  { name: 'Cote D\'Ivoire', code: 'CI', alpha3Code: 'CIV', phoneCode: '+225' },
  { name: 'Croatia', code: 'HR', alpha3Code: 'HRV', phoneCode: '+385' },
  { name: 'Cuba', code: 'CU', alpha3Code: 'CUB', phoneCode: '+53' },
  { name: 'Cyprus', code: 'CY', alpha3Code: 'CYP', phoneCode: '+357' },
  { name: 'Czech Republic', code: 'CZ', alpha3Code: 'CZE', phoneCode: '+420' },
  { name: 'Denmark', code: 'DK', alpha3Code: 'DNK', phoneCode: '+45' },
  { name: 'Djibouti', code: 'DJ', alpha3Code: 'DJI', phoneCode: '+253' },
  { name: 'Dominica', code: 'DM', alpha3Code: 'DMA', phoneCode: '+1' },
  { name: 'Dominican Republic', code: 'DO', alpha3Code: 'DOM', phoneCode: '+1' },
  { name: 'Ecuador', code: 'EC', alpha3Code: 'ECU', phoneCode: '+593' },
  { name: 'Egypt', code: 'EG', alpha3Code: 'EGY', phoneCode: '+20' },
  { name: 'El Salvador', code: 'SV', alpha3Code: 'SLV', phoneCode: '+503' },
  { name: 'Equatorial Guinea', code: 'GQ', alpha3Code: 'GNQ', phoneCode: '+240' },
  { name: 'Eritrea', code: 'ER', alpha3Code: 'ERI', phoneCode: '+291' },
  { name: 'Estonia', code: 'EE', alpha3Code: 'EST', phoneCode: '+372' },
  { name: 'Ethiopia', code: 'ET', alpha3Code: 'ETH', phoneCode: '+251' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK', alpha3Code: 'FLK', phoneCode: '+500' },
  { name: 'Faroe Islands', code: 'FO', alpha3Code: 'FRO', phoneCode: '+298' },
  { name: 'Fiji', code: 'FJ', alpha3Code: 'FJI', phoneCode: '+679' },
  { name: 'Finland', code: 'FI', alpha3Code: 'FIN', phoneCode: '+358' },
  { name: 'France', code: 'FR', alpha3Code: 'FRA', phoneCode: '+33' },
  { name: 'French Guiana', code: 'GF', alpha3Code: 'GUF', phoneCode: '+594' },
  { name: 'French Polynesia', code: 'PF', alpha3Code: 'PYF', phoneCode: '+689' },
  { name: 'French Southern Territories', code: 'TF', alpha3Code: 'ATF', phoneCode: '+262' },
  { name: 'Gabon', code: 'GA', alpha3Code: 'GAB', phoneCode: '+241' },
  { name: 'Gambia', code: 'GM', alpha3Code: 'GMB', phoneCode: '+220' },
  { name: 'Georgia', code: 'GE', alpha3Code: 'GEO', phoneCode: '+995' },
  { name: 'Germany', code: 'DE', alpha3Code: 'DEU', phoneCode: '+49' },
  { name: 'Ghana', code: 'GH', alpha3Code: 'GHA', phoneCode: '+233' },
  { name: 'Gibraltar', code: 'GI', alpha3Code: 'GIB', phoneCode: '+350' },
  { name: 'Greece', code: 'GR', alpha3Code: 'GRC', phoneCode: '+30' },
  { name: 'Greenland', code: 'GL', alpha3Code: 'GRL', phoneCode: '+299' },
  { name: 'Grenada', code: 'GD', alpha3Code: 'GRD', phoneCode: '+1' },
  { name: 'Guadeloupe', code: 'GP', alpha3Code: 'GLP', phoneCode: '+590' },
  { name: 'Guam', code: 'GU', alpha3Code: 'GUM', phoneCode: '+1' },
  { name: 'Guatemala', code: 'GT', alpha3Code: 'GTM', phoneCode: '+502' },
  { name: 'Guernsey', code: 'GG', alpha3Code: 'GGY', phoneCode: '+44' },
  { name: 'Guinea', code: 'GN', alpha3Code: 'GIN', phoneCode: '+224' },
  { name: 'Guinea-Bissau', code: 'GW', alpha3Code: 'GNB', phoneCode: '+245' },
  { name: 'Guyana', code: 'GY', alpha3Code: 'GUY', phoneCode: '+592' },
  { name: 'Haiti', code: 'HT', alpha3Code: 'HTI', phoneCode: '+509' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM', alpha3Code: 'HMD', phoneCode: '' },
  { name: 'Holy See (Vatican City State)', code: 'VA', alpha3Code: 'VAT', phoneCode: '+379' },
  { name: 'Honduras', code: 'HN', alpha3Code: 'HND', phoneCode: '+504' },
  { name: 'Hong Kong', code: 'HK', alpha3Code: 'HKG', phoneCode: '+852' },
  { name: 'Hungary', code: 'HU', alpha3Code: 'HUN', phoneCode: '+36' },
  { name: 'Iceland', code: 'IS', alpha3Code: 'ISL', phoneCode: '+354' },
  { name: 'India', code: 'IN', alpha3Code: 'IND', phoneCode: '+91' },
  { name: 'Indonesia', code: 'ID', alpha3Code: 'IDN', phoneCode: '+62' },
  { name: 'Iran', code: 'IR', alpha3Code: 'IRN', phoneCode: '+98' },
  { name: 'Iraq', code: 'IQ', alpha3Code: 'IRQ', phoneCode: '+964' },
  { name: 'Ireland', code: 'IE', alpha3Code: 'IRL', phoneCode: '+353' },
  { name: 'Isle of Man', code: 'IM', alpha3Code: 'IMN', phoneCode: '+44' },
  { name: 'Israel', code: 'IL', alpha3Code: 'ISR', phoneCode: '+972' },
  { name: 'Italy', code: 'IT', alpha3Code: 'ITA', phoneCode: '+39' },
  { name: 'Jamaica', code: 'JM', alpha3Code: 'JAM', phoneCode: '+1' },
  { name: 'Japan', code: 'JP', alpha3Code: 'JPN', phoneCode: '+81' },
  { name: 'Jersey', code: 'JE', alpha3Code: 'JEY', phoneCode: '+44' },
  { name: 'Jordan', code: 'JO', alpha3Code: 'JOR', phoneCode: '+962' },
  { name: 'Kazakhstan', code: 'KZ', alpha3Code: 'KAZ', phoneCode: '+7' },
  { name: 'Kenya', code: 'KE', alpha3Code: 'KEN', phoneCode: '+254' },
  { name: 'Kiribati', code: 'KI', alpha3Code: 'KIR', phoneCode: '+686' },
  { name: 'North Korea', code: 'KP', alpha3Code: 'PRK', phoneCode: '+850' },
  { name: 'South Korea', code: 'KR', alpha3Code: 'KOR', phoneCode: '+82' },
  { name: 'Kuwait', code: 'KW', alpha3Code: 'KWT', phoneCode: '+965' },
  { name: 'Kyrgyzstan', code: 'KG', alpha3Code: 'KGZ', phoneCode: '+996' },
  { name: 'Lao People\'s Democratic Republic', code: 'LA', alpha3Code: 'LAO', phoneCode: '+856' },
  { name: 'Latvia', code: 'LV', alpha3Code: 'LVA', phoneCode: '+371' },
  { name: 'Lebanon', code: 'LB', alpha3Code: 'LBN', phoneCode: '+961' },
  { name: 'Lesotho', code: 'LS', alpha3Code: 'LSO', phoneCode: '+266' },
  { name: 'Liberia', code: 'LR', alpha3Code: 'LBR', phoneCode: '+231' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY', alpha3Code: 'LBY', phoneCode: '+218' },
  { name: 'Liechtenstein', code: 'LI', alpha3Code: 'LIE', phoneCode: '+423' },
  { name: 'Lithuania', code: 'LT', alpha3Code: 'LTU', phoneCode: '+370' },
  { name: 'Luxembourg', code: 'LU', alpha3Code: 'LUX', phoneCode: '+352' },
  { name: 'Macao', code: 'MO', alpha3Code: 'MAC', phoneCode: '+853' },
  { name: 'North Macedonia', code: 'MK', alpha3Code: 'MKD', phoneCode: '+389' },
  { name: 'Madagascar', code: 'MG', alpha3Code: 'MDG', phoneCode: '+261' },
  { name: 'Malawi', code: 'MW', alpha3Code: 'MWI', phoneCode: '+265' },
  { name: 'Malaysia', code: 'MY', alpha3Code: 'MYS', phoneCode: '+60' },
  { name: 'Maldives', code: 'MV', alpha3Code: 'MDV', phoneCode: '+960' },
  { name: 'Mali', code: 'ML', alpha3Code: 'MLI', phoneCode: '+223' },
  { name: 'Malta', code: 'MT', alpha3Code: 'MLT', phoneCode: '+356' },
  { name: 'Marshall Islands', code: 'MH', alpha3Code: 'MHL', phoneCode: '+692' },
  { name: 'Martinique', code: 'MQ', alpha3Code: 'MTQ', phoneCode: '+596' },
  { name: 'Mauritania', code: 'MR', alpha3Code: 'MRT', phoneCode: '+222' },
  { name: 'Mauritius', code: 'MU', alpha3Code: 'MUS', phoneCode: '+230' },
  { name: 'Mayotte', code: 'YT', alpha3Code: 'MYT', phoneCode: '+262' },
  { name: 'Mexico', code: 'MX', alpha3Code: 'MEX', phoneCode: '+52' },
  { name: 'Micronesia, Federated States of', code: 'FM', alpha3Code: 'FSM', phoneCode: '+691' },
  { name: 'Moldova, Republic of', code: 'MD', alpha3Code: 'MDA', phoneCode: '+373' },
  { name: 'Monaco', code: 'MC', alpha3Code: 'MCO', phoneCode: '+377' },
  { name: 'Mongolia', code: 'MN', alpha3Code: 'MNG', phoneCode: '+976' },
  { name: 'Montenegro', code: 'ME', alpha3Code: 'MNE', phoneCode: '+382' },
  { name: 'Montserrat', code: 'MS', alpha3Code: 'MSR', phoneCode: '+1' },
  { name: 'Morocco', code: 'MA', alpha3Code: 'MAR', phoneCode: '+212' },
  { name: 'Mozambique', code: 'MZ', alpha3Code: 'MOZ', phoneCode: '+258' },
  { name: 'Myanmar', code: 'MM', alpha3Code: 'MMR', phoneCode: '+95' },
  { name: 'Namibia', code: 'NA', alpha3Code: 'NAM', phoneCode: '+264' },
  { name: 'Nauru', code: 'NR', alpha3Code: 'NRU', phoneCode: '+674' },
  { name: 'Nepal', code: 'NP', alpha3Code: 'NPL', phoneCode: '+977' },
  { name: 'Netherlands', code: 'NL', alpha3Code: 'NLD', phoneCode: '+31' },
  { name: 'Netherlands Antilles', code: 'AN', alpha3Code: 'ANT', phoneCode: '+599' },
  { name: 'New Caledonia', code: 'NC', alpha3Code: 'NCL', phoneCode: '+687' },
  { name: 'New Zealand', code: 'NZ', alpha3Code: 'NZL', phoneCode: '+64' },
  { name: 'Nicaragua', code: 'NI', alpha3Code: 'NIC', phoneCode: '+505' },
  { name: 'Niger', code: 'NE', alpha3Code: 'NER', phoneCode: '+227' },
  { name: 'Nigeria', code: 'NG', alpha3Code: 'NGA', phoneCode: '+234' },
  { name: 'Niue', code: 'NU', alpha3Code: 'NIU', phoneCode: '+683' },
  { name: 'Norfolk Island', code: 'NF', alpha3Code: 'NFK', phoneCode: '+672' },
  { name: 'Northern Mariana Islands', code: 'MP', alpha3Code: 'MNP', phoneCode: '+1' },
  { name: 'Norway', code: 'NO', alpha3Code: 'NOR', phoneCode: '+47' },
  { name: 'Oman', code: 'OM', alpha3Code: 'OMN', phoneCode: '+968' },
  { name: 'Pakistan', code: 'PK', alpha3Code: 'PAK', phoneCode: '+92' },
  { name: 'Palau', code: 'PW', alpha3Code: 'PLW', phoneCode: '+680' },
  { name: 'Palestinian Territory, Occupied', code: 'PS', alpha3Code: 'PSE', phoneCode: '+970' },
  { name: 'Panama', code: 'PA', alpha3Code: 'PAN', phoneCode: '+507' },
  { name: 'Papua New Guinea', code: 'PG', alpha3Code: 'PNG', phoneCode: '+675' },
  { name: 'Paraguay', code: 'PY', alpha3Code: 'PRY', phoneCode: '+595' },
  { name: 'Peru', code: 'PE', alpha3Code: 'PER', phoneCode: '+51' },
  { name: 'Philippines', code: 'PH', alpha3Code: 'PHL', phoneCode: '+63' },
  { name: 'Pitcairn', code: 'PN', alpha3Code: 'PCN', phoneCode: '+64' }, // Not an official ITU code, but used by some satellite systems
  { name: 'Poland', code: 'PL', alpha3Code: 'POL', phoneCode: '+48' },
  { name: 'Portugal', code: 'PT', alpha3Code: 'PRT', phoneCode: '+351' },
  { name: 'Puerto Rico', code: 'PR', alpha3Code: 'PRI', phoneCode: '+1' }, // Assuming North American numbering plan
  { name: 'Qatar', code: 'QA', alpha3Code: 'QAT', phoneCode: '+974' },
  { name: 'Reunion', code: 'RE', alpha3Code: 'REU', phoneCode: '+262' }, // May also include +262 269 (Mayotte)
  { name: 'Romania', code: 'RO', alpha3Code: 'ROU', phoneCode: '+40' },
  { name: 'Russian Federation', code: 'RU', alpha3Code: 'RUS', phoneCode: '+7' }, // Russia has multiple area codes within the +7 country code
  { name: 'Rwanda', code: 'RW', alpha3Code: 'RWA', phoneCode: '+250' },
  { name: 'Saint Helena', code: 'SH', alpha3Code: 'SHN', phoneCode: '+290' },
  { name: 'Saint Kitts and Nevis', code: 'KN', alpha3Code: 'KNA', phoneCode: '+1' }, // Assuming North American numbering plan
  { name: 'Saint Lucia', code: 'LC', alpha3Code: 'LCA', phoneCode: '+1' }, // Assuming North American numbering plan
  { name: 'Saint Pierre and Miquelon', code: 'PM', alpha3Code: 'SPM', phoneCode: '+508' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC', alpha3Code: 'VCT', phoneCode: '+1' }, // Assuming North American numbering plan
  { name: 'Samoa', code: 'WS', alpha3Code: 'WSM', phoneCode: '+685' },
  { name: 'San Marino', code: 'SM', alpha3Code: 'SMR', phoneCode: '+378' },
  { name: 'Sao Tome and Principe', code: 'ST', alpha3Code: 'STP', phoneCode: '+239' },
  { name: 'Saudi Arabia', code: 'SA', alpha3Code: 'SAU', phoneCode: '+966' },
  { name: 'Senegal', code: 'SN', alpha3Code: 'SEN', phoneCode: '+221' },
  { name: 'Serbia', code: 'RS', alpha3Code: 'SRB', phoneCode: '+381' },
  { name: 'Seychelles', code: 'SC', alpha3Code: 'SYC', phoneCode: '+248' },
  { name: 'Sierra Leone', code: 'SL', alpha3Code: 'SLE', phoneCode: '+232' },
  { name: 'Singapore', code: 'SG', alpha3Code: 'SGP', phoneCode: '+65' },
  { name: 'Slovakia', code: 'SK', alpha3Code: 'SVK', phoneCode: '+421' },
  { name: 'Slovenia', code: 'SI', alpha3Code: 'SVN', phoneCode: '+386' },
  { name: 'Solomon Islands', code: 'SB', alpha3Code: 'SLB', phoneCode: '+677' },
  { name: 'Somalia', code: 'SO', alpha3Code: 'SOM', phoneCode: '+252' },
  { name: 'South Africa', code: 'ZA', alpha3Code: 'ZAF', phoneCode: '+27' },
  { name: 'SGSSI', code: 'GS', alpha3Code: 'SGS', phoneCode: '+500' },
  { name: 'Spain', code: 'ES', alpha3Code: 'ESP', phoneCode: '+34' },
  { name: 'Sri Lanka', code: 'LK', alpha3Code: 'LKA', phoneCode: '+94' },
  { name: 'Sudan', code: 'SD', alpha3Code: 'SDN', phoneCode: '+249' },
  { name: 'Suriname', code: 'SR', alpha3Code: 'SUR', phoneCode: '+597' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ', alpha3Code: 'SJM', phoneCode: '+47' },
  { name: 'Swaziland', code: 'SZ', alpha3Code: 'SWZ', phoneCode: '+268' },
  { name: 'Sweden', code: 'SE', alpha3Code: 'SWE', phoneCode: '+46' },
  { name: 'Switzerland', code: 'CH', alpha3Code: 'CHE', phoneCode: '+41' },
  { name: 'Syrian Arab Republic', code: 'SY', alpha3Code: 'SYR', phoneCode: '+963' },
  { name: 'Taiwan, Province of China', code: 'TW', alpha3Code: 'TWN', phoneCode: '+886' },
  { name: 'Tajikistan', code: 'TJ', alpha3Code: 'TJK', phoneCode: '+992' },
  { name: 'Tanzania, United Republic of', code: 'TZ', alpha3Code: 'TZA', phoneCode: '+255' },
  { name: 'Thailand', code: 'TH', alpha3Code: 'THA', phoneCode: '+66' },
  { name: 'Timor-Leste', code: 'TL', alpha3Code: 'TLS', phoneCode: '+670' },
  { name: 'Togo', code: 'TG', alpha3Code: 'TGO', phoneCode: '+228' },
  { name: 'Tokelau', code: 'TK', alpha3Code: 'TKL', phoneCode: '+690' },
  { name: 'Tonga', code: 'TO', alpha3Code: 'TON', phoneCode: '+676' },
  { name: 'Trinidad and Tobago', code: 'TT', alpha3Code: 'TTO', phoneCode: '+1' },
  { name: 'Tunisia', code: 'TN', alpha3Code: 'TUN', phoneCode: '+216' },
  { name: 'Turkey', code: 'TR', alpha3Code: 'TUR', phoneCode: '+90' },
  { name: 'Turkmenistan', code: 'TM', alpha3Code: 'TKM', phoneCode: '+993' },
  { name: 'Turks and Caicos Islands', code: 'TC', alpha3Code: 'TCA', phoneCode: '+1' }, // Assuming North American numbering plan
  { name: 'Tuvalu', code: 'TV', alpha3Code: 'TUV', phoneCode: '+688' },
  { name: 'Uganda', code: 'UG', alpha3Code: 'UGA', phoneCode: '+256' },
  { name: 'Ukraine', code: 'UA', alpha3Code: 'UKR', phoneCode: '+380' },
  { name: 'United Arab Emirates', code: 'AE', alpha3Code: 'ARE', phoneCode: '+971' },
  { name: 'United Kingdom', code: 'GB', alpha3Code: 'GBR', phoneCode: '+44' },
  { name: 'United States', code: 'US', alpha3Code: 'USA', phoneCode: '+1' }, // North American numbering plan
  { name: 'United States Minor Outlying Islands', code: 'UM', alpha3Code: 'UMI', phoneCode: '+1' }, // North American numbering plan
  { name: 'Uruguay', code: 'UY', alpha3Code: 'URY', phoneCode: '+598' },
  { name: 'Uzbekistan', code: 'UZ', alpha3Code: 'UZB', phoneCode: '+998' },
  { name: 'Vanuatu', code: 'VU', alpha3Code: 'VUT', phoneCode: '+678' },
  { name: 'Venezuela', code: 'VE', alpha3Code: 'VEN', phoneCode: '+58' },
  { name: 'Viet Nam', code: 'VN', alpha3Code: 'VNM', phoneCode: '+84' },
  { name: 'Virgin Islands, British', code: 'VG', alpha3Code: 'VGB', phoneCode: '+1' }, // North American numbering plan
  { name: 'Virgin Islands, U.S.', code: 'VI', alpha3Code: 'VIR', phoneCode: '+1' }, // North American numbering plan
  { name: 'Wallis and Futuna', code: 'WF', alpha3Code: 'WLF', phoneCode: '+681' },
  { name: 'Western Sahara', code: 'EH', alpha3Code: 'ESH', phoneCode: '+212' },
  { name: 'Yemen', code: 'YE', alpha3Code: 'YEM', phoneCode: '+967' },
  { name: 'Zambia', code: 'ZM', alpha3Code: 'ZMB', phoneCode: '+260' },
  { name: 'Zimbabwe', code: 'ZW', alpha3Code: 'ZWE', phoneCode: '+263' }
];
