<p-tabView styleClass="tabview-custom">
  <p-tabView *ngIf="mailBoxes.length" (activeIndexChange)="onIndexChange($event)" [(activeIndex)]="activeIndex" [scrollable]="true">
    <p-tabPanel *ngFor="let mailbox of mailBoxes; let i = index" [header]="mailbox.mailboxType">
      <ng-container>
        <ng-template pTemplate="header">
          <ng-container *ngIf="mailbox.mailboxType === mailBoxTypeEnum.BONUS">
            <i *ngIf="!mailbox.isRead" class="pi pi-gift" pBadge value=" " severity="danger"></i>
            <i *ngIf="mailbox.isRead" class="pi pi-gift"></i>
          </ng-container>
          <ng-container *ngIf="mailbox.mailboxType === mailBoxTypeEnum.CHBONUS">
            <i *ngIf="!mailbox.isRead" class="pi pi-home" pBadge value=" " severity="danger"></i>
            <i *ngIf="mailbox.isRead" class="pi pi-home"></i>
          </ng-container>
          <ng-container *ngIf="mailbox.mailboxType === mailBoxTypeEnum.CHCHBONUS">
            <i *ngIf="!mailbox.isRead" class="pi pi-inbox" pBadge value=" " severity="danger"></i>
            <i *ngIf="mailbox.isRead" class="pi pi-inbox"></i>
          </ng-container>
          <ng-container *ngIf="mailbox.mailboxType === mailBoxTypeEnum.CHCHCHBONUS">
            <i *ngIf="!mailbox.isRead" class="pi pi-gift" pBadge value=" " severity="danger"></i>
            <i *ngIf="mailbox.isRead" class="pi pi-gift"></i>
          </ng-container>
        </ng-template>
        <div class="notification-container">
          <div class="notification" *ngFor="let message of messages">
            <h3 class="notification-subject">{{ message.subject }}</h3>
            <p class="notification-body">{{ message.body }}</p>
          </div>
          <div class="notification" *ngIf="!messages.length">
            <p class="notification-body">No messages in this mailbox.</p>
          </div>
        </div>

        <div class="loading-container" *ngIf="loading">
          <div class="loading-spinner"></div>
          <div class="loading-text">Loading...</div>
        </div>
      </ng-container>
    </p-tabPanel>
  </p-tabView>
  <div *ngIf="!mailBoxes.length">
    No mailboxes.
  </div>
</p-tabView>
