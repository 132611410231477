<ng-container *ngIf="title && games?.length">
  <div class="cards-container no-user-selection" [ngClass]="{'mobile-view': isMobileView}">
    <h3 class="provider-name">{{ title }}</h3>
    <div class="carousel-container">
      <p-carousel #pCarousel [value]="games" [numVisible]="numVisible"
                  [numScroll]="numScroll" [circular]="false" [responsiveOptions]="isMobileView?
                  responsiveOptionsForMobile : responsiveOptions">
        <ng-template ngFor let-game let-i="index" pTemplate="item">
          <div [style.width.px]="!isMobileView ? cardContainerWidth : 'auto'">
            <div [style.width.px]="!isMobileView ? cardContainerWidth : 'auto'">
              <ng-container *ngIf="game?.id">
                <app-game-card [game]="game"></app-game-card>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>
</ng-container>
