import {Injectable} from '@angular/core';
import {LOCAL_STORAGE_TIME_ZONE} from '../constants';
import * as moment from 'moment';
import {DatePipe} from '@angular/common';
import {UtilService} from './util.service';

@Injectable()
export class DateService {
  private userTimeZone: string;

  constructor(
    private datePipe: DatePipe,
    private utilService: UtilService,
  ) {
    this.userTimeZone = localStorage.getItem(LOCAL_STORAGE_TIME_ZONE)!;

    utilService.timeZoneChange$.subscribe(timeZone => this.userTimeZone = timeZone)
  }

  /**
   * Converts a UTC date string to the local date and time based on user's timezone.
   * @param utcDateTimeString The UTC date string to convert.
   * @returns The local date and time string formatted as 'YYYY-MM-DD HH:mm:ss'.
   */
  public convertUTCToLocalDateTime(utcDateTimeString: string): string {
    const UTCTransformedString = this.datePipe.transform(utcDateTimeString, 'medium') + ' GMT+0000';
    return moment.utc(UTCTransformedString).tz(this.userTimeZone).format('YYYY-MM-DD HH:mm:ss');
  }
}
