<header class="header" [ngClass]="{ 'mobile-view' : isMobileView }">
  <div *ngIf="!isMobileView" class="header-nav-main">
    <div>
      <casino-search
        [alwaysExpanded]="false"
        [placeholder]="'Search your game'"
        (onKeyup$)="searchGame($event)">
      </casino-search>
    </div>
  </div>

  <div class="header__nav" [ngClass]="{'header__nav-mobile': isMobileView}">
    <div *ngIf="isMobileView" routerLink="home" class="show-on-mobile">
      <img class="logo__img no-user-selection" src='assets/casino/superhero.svg'>
    </div>
    <div class="header-content">
      <div *ngIf="isAuthenticated && hasPermissionToReadAdministration" class="header-item icon-btn">
        <div (click)="goToAdministration()" class="inner-btn">
          <i class="pi pi-user-edit no-user-selection" style="font-size: 20px;"></i>
        </div>
      </div>

      <div class="header-item icon-btn">
        <div (click)="navigationService.openRightBar()" class="inner-btn">
          <img class="no-user-selection" width="20" src="assets/identity/person.svg">
        </div>
      </div>

      <ng-container *ngIf="!isAuthenticated">
        <div class="header-item text-btn">
          <button
            i18n="header login button @@loginButton"
            class="inner-btn"
            (click)="navigationService.showLogin()"
          >Login
          </button>
        </div>
        <div class="header-item text-btn">
          <button
            i18n="@@RegisterTitle"
            class="inner-btn"
            (click)="navigationService.showRegister()"
          >Register
          </button>
        </div>
      </ng-container>

      <div class="header-item text-btn crypto-currency-text"
           *ngIf="isAuthenticated && convertedBalance !== null && convertedBalance !== undefined && fiatCurrency">
        <div (click)="openWalletsBar()" class="inner-btn">
          <div
            class="game-currency-icon"
            style="height: 20px; width: 20px; font-size: 12px; border-radius: 10px;"
            [ngStyle]="{backgroundColor: gameCurrencyIconsBG[fiatCurrency?.code.toLowerCase()]}"
          >{{ fiatCurrency?.symbol }}</div>
            <span>{{ currentWallet ? convertedBalance : (fiatCurrency.code | uppercase) }}</span>
        </div>
      </div>

      <div *ngIf="isAuthenticated && mainCurrencies && mainCurrency" class="header-item drop-down drop-down-crypto">
        <div class="inner-btn">
          <p-dropdown optionLabel="code"
                      id="mainCurrencyDropdown"
                      [style]="{borderRadius: '25px', width: '100%', height: '38px', paddingLeft: '15px', display: 'flex', alignItems: 'center',}"
                      [appendTo]="'body'"
                      [(ngModel)]="mainCurrency"
                      (onChange)="updateSelectedMainCurrency($event.value.id)"
                      [options]="mainCurrencies">
            <ng-template pTemplate="selectedItem">
              <div class="flex flex-row align-items-center gap-2">
                <div class="flex flag-container">
                  <img class="no-user-selection" width="20px" [src]="'assets/cryptocurrencies/' + (mainCurrency.code | lowercase) + '.svg'">
                </div>
                <div *ngIf="!isMobileView" class="flex align-items-center gap-2 selected-item">
                  <div>{{ mainCurrency.code | uppercase }}</div>
                </div>
                <div class="dropdown-separator"></div>
              </div>
            </ng-template>

            <ng-template let-currency pTemplate="item">
              <div class="flex flex-row align-items-center gap-2 header-dropdown-overlay-crypto">
                <div class="flag-container">
                  <img class="no-user-selection" width="20px" [src]="'assets/cryptocurrencies/' + (currency.code | lowercase) + '.svg'">
                </div>
                <div class="flex align-items-center gap-2">
                  <div>{{ currency.code | uppercase }}</div>
                </div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>

      <div *ngIf="languages && selectedLanguage" class="header-item drop-down drop-down-lang">
        <div class="inner-btn">
          <p-dropdown [appendTo]="'body'"
                      [style]="{borderRadius: '25px', width: '100%', height: '38px', paddingLeft: '15px', display: 'flex', alignItems: 'center',}"
                      (onChange)="onLanguageChange($event.value)"
                      [(ngModel)]="selectedLanguage"
                      [options]="languages"
                      id="languageDropdown"
                      optionLabel="threeLetterISOLanguageName">
            <ng-template pTemplate="selectedItem">
              <div class="flex flex-row align-items-center gap-2">
                <div class="flag-container">
                  <img class="no-user-selection" width="20px" [src]="'assets/languages/' + selectedLanguage.twoLetterISOLanguageName + '.png'">
                </div>
                <div *ngIf="!isMobileView" class="flex align-items-center gap-2 selected-item">
                  <div>{{ selectedLanguage.threeLetterISOLanguageName | uppercase }}</div>
                </div>
                <div class="dropdown-separator"></div>
              </div>
            </ng-template>

            <ng-template let-language pTemplate="item">
              <div class="flex flex-row align-items-center gap-2 header-dropdown-overlay-lang">
                <div class="flag-container">
                  <img class="no-user-selection" width="20px" [src]="'assets/languages/' + language.twoLetterISOLanguageName + '.png'">
                </div>
                <div class="flex align-items-center gap-2">
                  <div>{{ language.threeLetterISOLanguageName | uppercase }}</div>
                </div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>

      <div class="header-item time" *ngIf="!isMobileView">
        <div>{{ getCurrentTime() }}</div>
      </div>

    </div>

  </div>
</header>
<div class="header-btm-border"></div>
